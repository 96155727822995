import { useDispatch } from "react-redux";

/*
 * Data card editor action types
 */

export const INITIALIZE_IDS = "INITIALIZE_IDS";
export const IDS_SET_CONTENT_FILTERS = "IDS_SET_CONTENT_FILTERS";
export const IDS_CLEAN_CONTENT_FILTERS = "IDS_CLEAN_CONTENT_FILTERS";
export const IDS_RECALCULATE_CONTENT_FILTERS = 'IDS_RECALCULATE_CONTENT_FILTERS';
export const IDS_DELETE_CONTENT_FILTER = 'IDS_DELETE_CONTENT_FILTER';
export const IDS_SET_CONTENT_SORT = "IDS_SET_CONTENT_SORT";
export const IDS_SET_INTERACTIVE_FILTER = "IDS_SET_INTERACTIVE_FILTER"
export const IDS_REMOVE_INTERACTIVE_FILTER = "IDS_REMOVE_INTERACTIVE_FILTER"
export const IDS_REMOVE_INTERACTIVE_FILTERS = "IDS_REMOVE_INTERACTIVE_FILTERS"

// General data card editor actions
const initializeIDS = () => dispatch => dispatch({ type: INITIALIZE_IDS });
const setContentFilters = ({ cardId, pageId, filters }) => dispatch => dispatch({ type: IDS_SET_CONTENT_FILTERS, cardId, pageId, filters });
const recalculateContentFilters = ({ cardId, pageId, filterKeys }) => dispatch => dispatch({ type: IDS_RECALCULATE_CONTENT_FILTERS, cardId, pageId, filters: filterKeys });
const cleanContentFilters = ({ contentId }) => dispatch => dispatch({ type: IDS_CLEAN_CONTENT_FILTERS, contentId });
const setContentSort = (cardId, sort) => dispatch => dispatch({ type: IDS_SET_CONTENT_SORT, cardId, sort });
const setInteractiveFilter = ({ cardId, pageId, filter }) => dispatch => dispatch({ type: IDS_SET_INTERACTIVE_FILTER, cardId, pageId, filter })
const removeInteractiveFilter = ({ cardId, pageId, filterId }) => dispatch => dispatch({ type: IDS_REMOVE_INTERACTIVE_FILTER, cardId, pageId, filterId })
const removeInteractiveFilters = ({ cardId, pageId }) => dispatch => dispatch({ type: IDS_REMOVE_INTERACTIVE_FILTERS, cardId, pageId })

export function useIDSActions() {

    const dispatch = useDispatch();

    return {
        initializeIDS: () => dispatch(initializeIDS()),
        setContentFilters: ({ cardId, pageId, filters }) => dispatch(setContentFilters({ cardId, pageId, filters })),
        recalculateContentFilters: ({ cardId, pageId, filterKeys }) => dispatch(recalculateContentFilters({ cardId, pageId, filterKeys })),
        cleanContentFilters: ({ contentId }) => dispatch(cleanContentFilters({ contentId })),
        setContentSort: (cardId, sort) => dispatch(setContentSort(cardId, sort)),
        setInteractiveFilter: ({ cardId, pageId, filter }) => dispatch(setInteractiveFilter({ cardId, pageId, filter })),
        removeInteractiveFilter: ({ cardId, pageId, filterId }) => dispatch(removeInteractiveFilter({ cardId, pageId, filterId })),
        removeInteractiveFilters: ({ cardId, pageId }) => dispatch(removeInteractiveFilters({ cardId, pageId }))
    };
}
