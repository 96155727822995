import { createBrowserHistory } from "history";
import { BIUWER_SHARE_APP_NAME } from "./constants";

const PREV_PATH_LABEL = "prevPath";
const PREV_STATE_LABEL = "prevState";

/**
 * Singleton class to store and manage navigation history instance linked with React Router HOC
 */
class History {

    // Private properties
    #history;
    #inMemory;

    // Public properties
    previousPath = null;
    previousState = null;

    init(app) {
        this.#inMemory = app === BIUWER_SHARE_APP_NAME;
        this.#history = createBrowserHistory();
        this.previousPath = this.#history.location.state?.[PREV_PATH_LABEL] ? [this.#history.location.state?.[PREV_PATH_LABEL]] : [];
        this.previousState = this.#history.location.state?.[PREV_STATE_LABEL] ? [this.#history.location.state?.[PREV_STATE_LABEL]] : [];

        return this.#history;
    }

    // Private methods
    #updatePreviousPath() {
        this.previousPath.push(`${this.#history.location.pathname}${this.#history.location?.search || ''}`);
        return this.previousPath[this.previousPath.length-1];
    }

    #updatePreviousState() {
        this.previousState.push(this.#history.location.state || {});
        return this.previousState[this.previousState.length - 1];
    }

    #ensureQuery(path) {
        const currentSearch = this.#history.location.search;
        if (typeof path === "string") {
            if (!path.includes("?") && currentSearch) {
                return path + currentSearch;
            }
            return path;
        } else if (typeof path === "object" && path !== null) {
            if (!path.search && currentSearch) {
                return { ...path, search: currentSearch };
            }
            return path;
        }
        return path;
    }

    #navigateTo(path, state = {}, replace = false, backNavigation = false) {
        if(!backNavigation) {
            state[PREV_PATH_LABEL] = this.#updatePreviousPath();
            state[PREV_STATE_LABEL] = this.#updatePreviousState();
        }
        if (this.#inMemory || replace) {
            this.#history.replace(path, state);
        } else {
            this.#history.push(path, state);
        }
    }

    // Public methods
    getHistory() {
        return this.#history;
    }

    getLocation() {
        return this.#history.location;
    }

    push(path, state = {}) {
        const pathWithQuery = this.#ensureQuery(path);
        this.#navigateTo(pathWithQuery, state);
    }

    replace(path, state = {}) {
        const pathWithQuery = this.#ensureQuery(path);
        this.#navigateTo(pathWithQuery, state, true);
    }

    goBack() {
        if (this.#inMemory) {
            this.previousPath.length > 0 && this.#navigateTo(this.previousPath.pop(), this.previousState.pop() || {}, true, true);
        } else {
            this.#updatePreviousPath();
            this.#updatePreviousState();
            this.#history.goBack();
        }
    }
}

export default new History();