import { Map as ImmutableMap, fromJS, List } from "immutable";

// Actions
import {
    // Interactive Data System
    INITIALIZE_IDS,
    IDS_SET_CONTENT_FILTERS,
    IDS_RECALCULATE_CONTENT_FILTERS,
    IDS_CLEAN_CONTENT_FILTERS,
    IDS_SET_CONTENT_SORT,
    IDS_SET_INTERACTIVE_FILTER,
    IDS_REMOVE_INTERACTIVE_FILTER,
    IDS_REMOVE_INTERACTIVE_FILTERS
} from './interactive-data-system-actions';


// Initial state
const initialState = ImmutableMap(fromJS({}));


/**
 * Data card editor Reducers
 */
export default function interactiveDataSystem(state = initialState, action) {

    let newState = state;
    const contentRoute = action.contentId ?? (action?.pageId ? `${action.pageId}__${action.cardId}` : action.cardId);
    const interactiveRoute = action.pageId

    switch (action.type) {
        case INITIALIZE_IDS:
            return initialState;
        case IDS_SET_CONTENT_FILTERS:
            const prevFilters = state?.getIn(["content", contentRoute, "filters"]) || ImmutableMap({});
            return newState?.setIn(["content", contentRoute, "filters"], prevFilters?.merge(action.filters));
        case IDS_RECALCULATE_CONTENT_FILTERS:
            let newFilters = ImmutableMap({});
            action.filters?.forEach((filter) => {
                if (newState?.getIn(["content", contentRoute, "filters", filter])) {
                    newFilters = newFilters.setIn([filter], newState?.getIn(["content", contentRoute, "filters", filter]));
                }
            });
            return newState?.setIn(["content", contentRoute, "filters"], newFilters);
        case IDS_CLEAN_CONTENT_FILTERS:
            return newState?.setIn(["content", contentRoute, "filters"], ImmutableMap(fromJS({})));
        case IDS_SET_CONTENT_SORT:
            const prevSort = state?.getIn(["content", contentRoute, "sort"]) || ImmutableMap({});
            return newState?.setIn(["content", contentRoute, "sort"], prevSort?.merge(action.sort));
        case IDS_SET_INTERACTIVE_FILTER:
            const interactiveFilters = state?.getIn(["interactive_filters", interactiveRoute]) ?? List([])
            const filterIndex = interactiveFilters?.findIndex(interactiveFilter => interactiveFilter.get("_id") === action.filter._id)
            if (filterIndex >= 0) {
                return newState?.setIn(["interactive_filters", interactiveRoute], interactiveFilters.setIn([filterIndex, "values"], fromJS(action.filter.values)))
            } else {
                return newState?.setIn(["interactive_filters", interactiveRoute], interactiveFilters.push(fromJS(action.filter)))
            }
        case IDS_REMOVE_INTERACTIVE_FILTER:
            const prevInteractiveFilters = state?.getIn(["interactive_filters", interactiveRoute]) ?? List([])
            const filterToRemoveIndex = prevInteractiveFilters?.findIndex(interactiveFilter => interactiveFilter.get("_id") === action.filterId)
            if (filterToRemoveIndex >= 0) {
                return newState?.removeIn(["interactive_filters", interactiveRoute, filterToRemoveIndex])
            }
            return newState
        case IDS_REMOVE_INTERACTIVE_FILTERS:
            return newState?.setIn(["interactive_filters", interactiveRoute], List([]))
        default:
            return newState;
    }
};
