// import { List } from "immutable"
// import request from "@biuwer/common/src/libs/superagent"
// import utilsLib from "@biuwer/common/src/libs/utils-lib";
import gqlRequest from "@biuwer/core/src/graphql-request";

import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import { defaultContext } from "@biuwer/redux/src/config/constants";
import { addNotification } from "../../system/notifications/notifications-actions";
import i18n from "@biuwer/core/src/i18n";
import {DATAFILTERS, FILTERS_DETAIL} from "./filters-gql";

// Page imports
import {PAGES_DETAIL} from "@biuwer/redux/src/system/pages/pages-gql";
import {
    PAGE_UPDATE_ERROR, PAGE_UPDATE_REQUEST, PAGE_UPDATE_SUCCESS,
    PAGE_FILTERS_UPSERT_SUCCESS, PAGE_FILTERS_CLEAN_SUCCESS
} from "@biuwer/redux/src/system/pages/pages-actions";

// Card imports
import { CARDS_DETAIL } from "@biuwer/redux/src/system/cards/cards-gql";
import {
    CARD_UPDATE_ERROR, CARD_UPDATE_REQUEST, CARD_UPDATE_SUCCESS,
    CARD_FILTERS_UPSERT_SUCCESS, CARD_FILTERS_CLEAN_SUCCESS
} from "@biuwer/redux/src/system/cards/cards-actions";

export const FILTERS_INITIALIZE ='FILTERS_INITIALIZE';
export const FILTERS_LIST_INITIALIZE ='FILTERS_LIST_INITIALIZE';
export const FILTERS_DETAIL_INITIALIZE ='FILTERS_DETAIL_INITIALIZE';

export const FILTER_FETCH_REQUEST = 'FILTER_FETCH_REQUEST';
export const FILTER_FETCH_SUCCESS  = 'FILTER_FETCH_SUCCESS';
export const FILTER_FETCH_ERROR = 'FILTER_FETCH_ERROR';

export const FILTERS_FETCH_REQUEST = 'FILTERS_FETCH_REQUEST';
export const FILTERS_FETCH_SUCCESS  = 'FILTERS_FETCH_SUCCESS';
export const FILTERS_FETCH_ERROR = 'FILTERS_FETCH_ERROR';

export const FILTERS_UPSERT_REQUEST = 'FILTERS_UPSERT_REQUEST';
export const FILTERS_UPSERT_SUCCESS = 'FILTERS_UPSERT_SUCCESS';
export const FILTERS_UPSERT_ERROR = 'FILTERS_UPSERT_ERROR';

export const FILTERS_CLEAN_REQUEST = 'FILTERS_CLEAN_REQUEST';
export const FILTERS_CLEAN_SUCCESS = 'FILTERS_CLEAN_SUCCESS';
export const FILTERS_CLEAN_ERROR = 'FILTERS_CLEAN_ERROR';

export const DATAFILTERS_UPSERT_REQUEST = "DATAFILTERS_UPSERT_REQUEST"
export const DATAFILTERS_UPSERT_SUCCESS = "DATAFILTERS_UPSERT_SUCCESS"
export const DATAFILTERS_UPSERT_ERROR = "DATAFILTERS_UPSERT_ERROR"

const generateSkeleton = (type, body, context = defaultContext, extraArgs, showNotification = true) => {
    let skeleton = { type, context }, notification;

    switch(type.substring(type.lastIndexOf('_') + 1, type.length)) {
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false
            };
            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body.payload
            };

            if (body.status === 2){
                skeleton.issuePayload = {
                    status: body.status,
                    code: body.issuePayload.code,
                    message: body.message
                }
            }
            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                issuePayload: {
                    status: body.status,
                    code: body.issuePayload ? body.issuePayload.code : body.payload, // Backward compatibility with old error handling
                    message: body.message
                }
            };
            break;
        default:
            break;
    }

    switch (type) {
        case FILTERS_UPSERT_REQUEST:
            break;
        case FILTERS_UPSERT_SUCCESS:
        case FILTERS_CLEAN_SUCCESS:
            skeleton.payload = body;
            break;
        case FILTERS_UPSERT_ERROR:
            break;
        case FILTER_FETCH_SUCCESS:
        case FILTERS_FETCH_SUCCESS:
            skeleton.payload = body;
            break;

            // Page actions
        case PAGE_UPDATE_REQUEST:
            skeleton.pageId = extraArgs.pageId;
            break;
        case PAGE_UPDATE_SUCCESS:
            skeleton.payload = body;
            skeleton.updated = true;
            skeleton.pageId = extraArgs.pageId;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.updateSuccess', { name: i18n.t('pages.nameLabel'), context: 'female', count: 1 })
            };
            break;
        case PAGE_UPDATE_ERROR:
            notification = {
                styleType: 'error',
                message: i18n.t('notifications.error')
            };
            break;
        case PAGE_FILTERS_UPSERT_SUCCESS:
        case PAGE_FILTERS_CLEAN_SUCCESS:
            skeleton.payload = body;
            skeleton.pageId = extraArgs.pageId;
            break;

            // Card actions
        case CARD_UPDATE_REQUEST:
            skeleton.pageId = extraArgs.pageId;
            skeleton.cardId = extraArgs.cardId;
            break;
        case CARD_UPDATE_SUCCESS:
            skeleton.payload = body;
            skeleton.pageId = extraArgs.pageId;
            skeleton.cardId = extraArgs.cardId;
            skeleton.updated = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.updateSuccess', { name: i18n.t('cards.cardsLabel'), context: 'female', count: 1 })
            };
            break;
        case CARD_UPDATE_ERROR:
            skeleton.pageId = extraArgs.pageId;
            skeleton.cardId = extraArgs.cardId;
            notification = {
                styleType: 'error',
                message: i18n.t('notifications.error')
            };
            break;
        case CARD_FILTERS_UPSERT_SUCCESS:
        case CARD_FILTERS_CLEAN_SUCCESS:
            skeleton.payload = body;
            skeleton.cardId = extraArgs.cardId;
            break;
        case DATAFILTERS_UPSERT_REQUEST:
            skeleton.cardId = extraArgs.cardId
            skeleton.pageId = extraArgs.pageId
            break
        case DATAFILTERS_UPSERT_SUCCESS:
            skeleton.payload = body
            skeleton.cardId = extraArgs.cardId
            skeleton.pageId = extraArgs.pageId
            skeleton.updated = true
            break
        case DATAFILTERS_UPSERT_ERROR:
            skeleton.cardId = extraArgs.cardId
            skeleton.pageId = extraArgs.pageId
            notification = {
                styleType: 'error',
                message: i18n.t('notifications.error')
            };
            break
        default:
            break;
    }

    return (dispatch) => {
        dispatch(skeleton);

        if (notification && showNotification) {
            dispatch(addNotification(notification));
        }
    };
};

export const initializeFilters = (context = defaultContext) => {
    return {
        type: FILTERS_INITIALIZE,
        context
    };
};

export const initializeListFilters = (context = defaultContext) => {
    return {
        type: FILTERS_LIST_INITIALIZE,
        context
    };
};

export const initializeDetailFilters = (context = defaultContext) => {
    return {
        type: FILTERS_DETAIL_INITIALIZE,
        context
    };
};

/**
 * Read filter action
 * @param filterId
 * @param context
 * @param gql
 */
export const readFilter = (filterId, context = defaultContext, gql = FILTERS_DETAIL) => {
    return async (dispatch) => {

        try {
            dispatch(generateSkeleton(FILTER_FETCH_REQUEST, null, context));

            const filter = await gqlRequest({
                queryName: "readFilter",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "String!",
                    name: "_id",
                    data: filterId
                }]
            });

            dispatch(generateSkeleton(FILTER_FETCH_SUCCESS, filter, context));
        } catch (err) {
            dispatch(generateSkeleton(FILTER_FETCH_ERROR, err, context));
        }
    };
}

/**
 * Read filter action
 * @param query
 * @param context
 * @param gql
 */
export const readFilters = (query, context = defaultContext, gql = FILTERS_DETAIL) => {
    return async (dispatch) => {

        try {
            dispatch(generateSkeleton(FILTERS_FETCH_REQUEST, null, context));

            const filters = await gqlRequest({
                queryName: "readFilters",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "QueryFilterInput!",
                    name: "query",
                    data: query
                }]
            });

            dispatch(generateSkeleton(FILTERS_FETCH_SUCCESS, filters, context, {}));
        } catch (err) {
            dispatch(generateSkeleton(FILTERS_FETCH_ERROR, err, context));
        }
    };
}

export const upsertPageFilters = (filters, filtersConfig, pageId, context = defaultContext, gql = PAGES_DETAIL) => {

    return async dispatch => {

        try {
            dispatch(generateSkeleton(PAGE_UPDATE_REQUEST, null, context, { pageId }));

            const upsertedPageFilters = await gqlRequest({
                queryType: "mutation",
                queryName: "upsertPageFilters",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "[JSONObject!]!",
                    name: "filters",
                    data: filters
                }, {
                    type: "Float!",
                    name: "pageId",
                    data: pageId
                }, {
                    type: "JSONObject!",
                    name: "filtersConfig",
                    data: filtersConfig
                }]
            });

            dispatch(generateSkeleton(PAGE_UPDATE_SUCCESS, upsertedPageFilters, context, { pageId }));
        } catch (err) {
            dispatch(generateSkeleton(PAGE_UPDATE_ERROR, err, context, { pageId: pageId }));
        }
    };
};

export const upsertCardFilters = (filters, filtersConfig, cardId, context = defaultContext, gql = CARDS_DETAIL) => {

    return async dispatch => {

        try {
            dispatch(generateSkeleton(CARD_UPDATE_REQUEST, null, context, { cardId }));

            const upsertedCardFilters = await gqlRequest({
                queryType: "mutation",
                queryName: "upsertCardFilters",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "[JSONObject!]!",
                    name: "filters",
                    data: filters
                }, {
                    type: "Float!",
                    name: "cardId",
                    data: cardId
                }, {
                    type: "JSONObject!",
                    name: "filtersConfig",
                    data: filtersConfig
                }]
            });

            dispatch(generateSkeleton(CARD_UPDATE_SUCCESS, upsertedCardFilters, context, { cardId }));
        } catch (err) {
            dispatch(generateSkeleton(CARD_UPDATE_ERROR, err, context, { cardId: cardId }));
        }
    };
};

export const upsertPageDataFilters = (dataFilters, pageId, context = defaultContext, gql = DATAFILTERS) => {

    return async dispatch => {

        try {

            dispatch(generateSkeleton(DATAFILTERS_UPSERT_REQUEST, null, context, { pageId }));

            const upsertPageDataFiltersResponse = await gqlRequest({
                queryType: "mutation",
                queryName: "upsertDataFilters",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [
                    {
                        name: "dataFilters",
                        type: "[JSONObject!]!",
                        data: dataFilters,
                    }
                ]
            })

            dispatch(generateSkeleton(DATAFILTERS_UPSERT_SUCCESS, upsertPageDataFiltersResponse, context, { pageId }, false))

        } catch (err) {
            dispatch(generateSkeleton(DATAFILTERS_UPSERT_ERROR, err, context, { pageId: pageId }, false));
        }
    };
};

export const upsertCardDataFilters = (dataFilters, cardId, context = defaultContext, gql = DATAFILTERS) => {

    return async dispatch => {

        try {

            dispatch(generateSkeleton(DATAFILTERS_UPSERT_REQUEST, null, context, { cardId }));

            const upsertCardDataFiltersResponse = await gqlRequest({
                queryType: "mutation",
                queryName: "upsertDataFilters",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [
                    {
                        name: "dataFilters",
                        type: "[JSONObject!]!",
                        data: dataFilters
                    }
                ]
            })

            dispatch(generateSkeleton(DATAFILTERS_UPSERT_SUCCESS, upsertCardDataFiltersResponse, context, { cardId }, false))

        } catch (err) {
            dispatch(generateSkeleton(DATAFILTERS_UPSERT_ERROR, err, context, { cardId: cardId }, false));
        }
    };
};

// const internalCleanDataFilters = (filters = List([])) => filters.toJS().map(filter => ({ filter_id: filter._id, expression: filter.default_expression }))

export const cleanPageDataFilters = (pageId, context = defaultContext, gql = DATAFILTERS) => {

    return async dispatch => {

        try {

            dispatch(generateSkeleton(DATAFILTERS_UPSERT_REQUEST, null, context, { pageId }));

            // // If biuwer share don't update dataFilters in server
            // // Simulate fetch request with 200 ms response time to force state changes
            // if (!utilsLib.isBiuwerApp()) {
            //     const dataFilters = internalCleanDataFilters(filters);
            //     return setTimeout(() => dispatch(generateSkeleton(PAGE_FILTERS_CLEAN_SUCCESS, dataFilters, context, { pageId })), 200);
            // }

            const cleanPageDataFiltersResponse = await gqlRequest({
                queryType: "mutation",
                queryName: "cleanDataFilters",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [
                    {
                        name: "objectType",
                        type: "String!",
                        data: "Page"
                    },
                    {
                        name: "objectId",
                        type: "Float!",
                        data: pageId
                    }
                ]
            })

            dispatch(generateSkeleton(DATAFILTERS_UPSERT_SUCCESS, cleanPageDataFiltersResponse, context, { pageId }, false))

        } catch (err) {
            dispatch(generateSkeleton(DATAFILTERS_UPSERT_ERROR, err, context, { pageId: pageId }, false));
        }
    };
};

export const cleanCardDataFilters = (cardId, context = defaultContext, gql = DATAFILTERS) => {

    return async dispatch => {

        try {

            dispatch(generateSkeleton(DATAFILTERS_UPSERT_REQUEST, null, context, { cardId }));

            // // If biuwer share don't update dataFilters in server
            // // Simulate fetch request with 200 ms response time to force state changes
            // if (!utilsLib.isBiuwerApp()) {
            //     const dataFilters = internalCleanDataFilters(filters);
            //     return setTimeout(() => dispatch(generateSkeleton(CARD_FILTERS_CLEAN_SUCCESS, dataFilters, context, { cardId })), 200);
            // }

            const cleanCardDataFiltersResponse = await gqlRequest({
                queryType: "mutation",
                queryName: "cleanDataFilters",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [
                    {
                        name: "objectType",
                        type: "String!",
                        data: "Card"
                    },
                    {
                        name: "objectId",
                        type: "Float!",
                        data: cardId
                    }
                ]
            })

            dispatch(generateSkeleton(DATAFILTERS_UPSERT_SUCCESS, cleanCardDataFiltersResponse, context, { cardId }, false))

        } catch (err) {
            dispatch(generateSkeleton(DATAFILTERS_UPSERT_ERROR, err, context, { cardId: cardId }, false));
        }
    };
};

export const upsertDataFilters = (filtersData, context = defaultContext, gql = DATAFILTERS) => {

    return async dispatch => {

        try {
            dispatch(generateSkeleton(FILTERS_UPSERT_REQUEST, null, context));

            const upsertedDataFilters = await gqlRequest({
                queryType: "mutation",
                queryName: "upsertDataFilters",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "[JSONObject!]!",
                    name: "datafilters",
                    data: filtersData
                }]
            });

            dispatch(generateSkeleton(FILTERS_UPSERT_SUCCESS, upsertedDataFilters, context, {}));
        } catch (err) {
            dispatch(generateSkeleton(FILTERS_UPSERT_ERROR, err, context));
        }
    };
};

export const upsertDataCardEditorFilters = (filtersData, context = defaultContext) => {
    return {
        type: FILTERS_UPSERT_SUCCESS,
        payload: filtersData,
        context,
        source: 'dataCardEditor'
    };
};

export const cleanDataFilters = (objectType, objectId, context = defaultContext, gql = DATAFILTERS) => {

    return async dispatch => {

        try {
            dispatch(generateSkeleton(FILTERS_CLEAN_REQUEST, null, context));

            const cleanedDataFilters = await gqlRequest({
                queryType: "mutation",
                queryName: "cleanDataFilters",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "String!",
                    name: "objectType",
                    data: objectType
                }, {
                    type: "Float!",
                    name: "objectId",
                    data: objectId
                }]
            });

            dispatch(generateSkeleton(FILTERS_CLEAN_SUCCESS, cleanedDataFilters, context, {}));
        } catch (err) {
            dispatch(generateSkeleton(FILTERS_CLEAN_ERROR, err, context));
        }
    };
}