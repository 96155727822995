import { Link } from "react-router-dom";
import utilsLib from "../libs/utils-lib";
import History from "@biuwer/core/src/history";

export default function LinkWrapper({ to, replace, className, children, ...restProps }) {

    const isBiuwerApp = utilsLib.isBiuwerApp();
    const location = History.getLocation();

    return (
        <Link
            to={{
                pathname: to,
                search: location?.search
            }}
            className={className}
            replace={replace || !isBiuwerApp}
            {...restProps}
        >
            {children}
        </Link>
    );
}