import { useCallback, useEffect, useMemo, useState } from "react"
import Helmet from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { createSelector } from "reselect"
import { useTranslation, Trans } from "react-i18next"
import moment from "moment"

// Components
import Loading from "@biuwer/common/src/components/loading"
import CustomButton from "@biuwer/common/src/components/custom-button"
import InfoMessage from "@biuwer/common/src/components/info-message"
import StripeWrapper from "../payments/stripe-wrapper"
import Link from "@biuwer/common/src/components/link"

// Hooks
import usePayments from "../payments/use-payments"

// Libs
import HelmetLib from "@biuwer/common/src/libs/helmet-lib"
import chatLib from "@biuwer/common/src/libs/chat-lib"

// Actions
import { defaultContext } from "@biuwer/redux/src/config/constants"
import { PAYMENT_LINK_ORDER } from "@biuwer/redux/src/billing/orders/orders-gql"
import { initLanguage } from "@biuwer/redux/src/system/auth/session-actions"
import { getOrderByPaymentLink } from "@biuwer/redux/src/billing/orders/orders-actions"
import { initializePayments, payOrderByPaymentLink } from "@biuwer/redux/src/billing/payments/payments-actions"

// Selectors
import { orderSelector, orderIsFetchingSelector, orderErrorSelector, orderErrorMessageSelector } from "@biuwer/redux/src/billing/orders/orders-selectors"
import { paymentIsFetchingSelector, paymentSelector, paymentCreatedSelector } from "@biuwer/redux/src/billing/payments/payments-selectors";

function useReduxDispatch() {
    const dispatch = useDispatch()
    return {
        dispatch,
        getOrder: (paymenLinkId, context = defaultContext, gql = PAYMENT_LINK_ORDER) => dispatch(getOrderByPaymentLink(paymenLinkId, context, gql)),
        initializePayments: (context = defaultContext) => dispatch(initializePayments(context)),
        payOrderByPaymentLink: (paymentLinkId, context = defaultContext) => dispatch(payOrderByPaymentLink(paymentLinkId, context))
    }
}

// Component selector
const paymentLinkPageSelector = createSelector(
    orderSelector, orderIsFetchingSelector,
    orderErrorSelector, orderErrorMessageSelector,
    paymentSelector, paymentIsFetchingSelector, paymentCreatedSelector,
    (order, orderIsFetching, orderError, orderErrorMessage, payment, paymentIsFetching, paymentCreated) => ({
        order, orderIsFetching, orderError, orderErrorMessage, payment, paymentIsFetching, paymentCreated
    })
);

function PaymentLinkPage({ match }) {
    const { params } = match;
    const { paymentLinkId } = params;

    const { confirmPaymentIntent, handlePaymentIntentError } = usePayments()
    const [paymentErrorMessage, setPaymentErrorMessage] = useState(null)
    const [paymentSuccess, setPaymentSuccess] = useState(false)

    // Redux
    const {
        order, orderIsFetching, orderError, orderErrorMessage,
        payment, paymentIsFetching, paymentCreated
    } = useSelector(state => paymentLinkPageSelector(state, defaultContext))
    const { getOrder, initializePayments, payOrderByPaymentLink } = useReduxDispatch()

    /**
     * Confirm existing payment intent linked to a pending order
     * @param {String} clientSecret payment intent secret
     * @param {String} cardId payment method id
     */
    const confirmPayment = useCallback((clientSecret, cardId) => {
        setPaymentErrorMessage(null)
        confirmPaymentIntent(clientSecret, cardId)
            .then(({ error }) => {
                if (error) {
                    handlePaymentIntentError(error, setPaymentErrorMessage)
                } else {
                    setPaymentSuccess(true)
                }
            })
    }, [confirmPaymentIntent])

    /**
     * Main function for processing credit card payments.
     * Check payment intent info previous stored in order metadata.
     * If order has no payment intent info or payment status is canceled, generates a new payment intent with payOrderByPaymentLink action.
     * Otherwise confirm payment intent with card id given from order metadata.
     */
    const submitPayment = useCallback(() => {

        const orderData = order?.toJS()
        const paymentId = orderData?.stripe_details?.payment_intent_id
        const paymentStatus = orderData?.stripe_details?.status
        const paymentClientSecret = orderData?.stripe_details?.client_secret

        if (paymentId == null || paymentClientSecret == null || paymentStatus === "canceled") {
            payOrderByPaymentLink(paymentLinkId)
        } else {
            const cardId = orderData?.card_id
            confirmPayment(paymentClientSecret, cardId)
        }
    }, [order, paymentLinkId, confirmPayment])

    // Initialization and fetching order with given payment link id
    useEffect(() => {
        initLanguage()
        initializePayments()
    }, [])
    useEffect(() => {
        getOrder(paymentLinkId)
    }, [paymentLinkId])

    // Check payment intent created and confirm it if required
    useEffect(() => {
        if (!paymentIsFetching && paymentCreated && payment.get("id")) {
            const status = payment.get("status")

            if (status === "requires_action") {
                confirmPayment(payment.get("client_secret"))
            }
            if (status === "succeeded") {
                setPaymentSuccess(true)
            }
        }
    }, [payment, paymentIsFetching, paymentCreated, paymentLinkId, confirmPayment])

    return (
        <div className="d-flex flex-column mt10">
            <Loading show={orderIsFetching} applyElapse={false} />
            <OrderInfo
                order={order}
                error={orderError && orderErrorMessage}
                submitPayment={submitPayment}
                paymentSuccess={paymentSuccess}
                paymentErrorMessage={paymentErrorMessage}
                setPaymentErrorMessage={setPaymentErrorMessage}
            />
            <PaymentLinkPageFooter />
        </div>
    )
}

function OrderInfo({ order, error, submitPayment = () => {}, paymentSuccess = false, paymentErrorMessage, setPaymentErrorMessage = () => {} }) {

    const { t, i18n } = useTranslation()

    const orderNumber = useMemo(() => order?.get("order_number") ?? null, [order])
    const orderDate = useMemo(() => order?.get("created") ?? null, [order])
    const orderPaymentMethod = useMemo(() => order?.get("payment_method") ?? null, [order])
    const stripeDetails = useMemo(() => order?.get("stripe_details")?.toJS() ?? null, [order])
    const orderStatus = useMemo(() => {
        if (paymentSuccess) return "paid"
        return order?.get("status")
    }, [order, paymentSuccess])
    const orderAmount = useMemo(() => order?.get("amount") ?? null, [order])
    const orderCurrency = useMemo(() =>  order?.get("currency") ?? null, [order])
    const orderPeriodStart = useMemo(() => order?.getIn(["period", "start"]) ?? null, [order])
    const orderPeriodEnd = useMemo(() => order?.getIn(["period", "end"]) ?? null, [order])

    const errorType = useMemo(() => {
        if (paymentSuccess) return  "ORDER_ALREADY_PAID"
        return error ?  error.message ?? "unknownError" : null
    }, [error, paymentSuccess])

    const message = useMemo(() => {
        if (orderStatus === "paid" || errorType === "ORDER_ALREADY_PAID") return t("payment.paymentLinkPage.orderAlreadyPaid", { orderNumber })
        if (errorType) return t("payment.paymentLinkPage.invalidPaymentLink")
        return `${t("payment.paymentLinkPage.orderDetails")}:`
    }, [orderNumber, orderStatus, errorType, i18n.language])

    const orderActions = useMemo(() => {
        if (errorType != null) return []
        if (orderPaymentMethod === "creditCard") return [{
            id: "pay",
            label: t("billing:orderHistoryPage.orderOptions.payNow"),
            className: "cursor",
            intent: "info",
            onClick: submitPayment
        }]
        return []
    }, [submitPayment, orderPaymentMethod, errorType, i18n.language])

    const errorMessage = useMemo(() => {
        if (paymentErrorMessage == null) return null
        return t(`errors:payments.${paymentErrorMessage}`)
    }, [paymentErrorMessage, i18n.language])

    return (
        <>
            <h4 className="text-center p-t-25 mb20">{t("payment.paymentLinkPage.title")}</h4>
            <p className={orderStatus === "pending" ? "mb10" : "mb20"}>{message}</p>
            {orderStatus === "pending" && (
                <>
                    <ul className="mb20">
                        <OrderNumber orderNumber={orderNumber} />
                        <OrderDate created={orderDate} />
                        <OrderPeriod start={orderPeriodStart} end={orderPeriodEnd} />
                        <OrderAmount amount={orderAmount} currency={orderCurrency} />
                        <OrderPaymentMethod paymentMethod={orderPaymentMethod} stripeDetails={stripeDetails} />
                    </ul>
                    <section className="mb20">
                        <OrderActions actions={orderActions} />
                    </section>
                    <InfoMessage
                        onCloseErrorMessage={() => setPaymentErrorMessage(null)}
                        message={errorMessage}
                        type="danger"
                    />
                </>
            )}
        </>
    )
}

function OrderNumber({ orderNumber }) {
    if (!orderNumber) return null

    return (
        <li className="line-height-20">
            <Trans
                i18nKey={"billing:orderHistoryPage.orderCard.orderNumberLabel"}
                values={{ orderNumber }}
                components={[<b>placeholder</b>]}
            />
        </li>
    )
}

function OrderAmount({ amount, currency }) {
    if (amount == null || currency == null) return null

    return (
        <>
            <li className="line-height-20">
                <Trans
                    i18nKey={"billing:orderHistoryPage.orderCard.amount"}
                    values={{ amount: parseFloat(amount).toFixed(2) }}
                    components={[<b>placeholder</b>]}
                />
            </li>
            <li className="line-height-20">
                <Trans
                    i18nKey={"billing:orderHistoryPage.orderCard.currency"}
                    values={{ currency: currency.toUpperCase() }}
                    components={[<b>placeholder</b>]}
                />
            </li>
        </>
    )
}

function OrderDate({ created }) {
    if (!created) return null

    return (
        <li className="line-height-20">
            <Trans
                i18nKey={"billing:orderHistoryPage.orderCard.orderCreated"}
                values={{ created: moment(created).format("DD/MM/YYYY") }}
                components={[<b>placeholder</b>]}
            />
        </li>
    )
}

function OrderPeriod({ start, end }) {
    if (start == null || end == null) return null

    return (
        <li className="line-height-20">
            <Trans
                i18nKey={"billing:orderHistoryPage.orderCard.orderPeriod"}
                values={{ period: `${start} - ${end}` }}
                components={[<b>placeholder</b>]}
            />
        </li>
    )
}

function OrderPaymentMethod({ paymentMethod, stripeDetails }) {

    const { t } = useTranslation()

    if (!paymentMethod) return null

    const card = stripeDetails?.card
    const paymentMethodLabel = t(`billing:checkout.paymentMethodBlock.${paymentMethod}.title`)
    const creditCard = card?.last4

    return (
        <li className="line-height-20 mr15">
            <span className="mr10">
                <Trans
                    i18nKey={"billing:orderHistoryPage.orderCard.paymentMethod"}
                    values={{ method: paymentMethodLabel }}
                    components={[<b>placeholder</b>]}
                />
            </span>
            {creditCard && (<span className="mr10"><span className="font-weight-bold">{`**** ${card.last4}`}</span></span>)}
        </li>
    )
}

function OrderActions({ actions = [] }) {
    return (
        <>
            {actions.map(({ id, intent, className, onClick, styles, label }) => (
                <CustomButton
                    key={id}
                    intent={intent}
                    className={className}
                    onClick={onClick}
                    styles={styles}
                >
                    {label}
                </CustomButton>
            ))}
        </>
    )
}

function PaymentLinkPageFooter() {
    const { t } = useTranslation()
    return (
        <>
            <Link className="span-link text-info small mb10" to="/">{t("auth.pageNotFound.goHomePageMessage")}</Link>
            <span className="span-link text-info small mb10" onClick={chatLib.toggleChat}>
                {t("auth.signinForm.helpChat")}
            </span>
        </>
    )
}

export default function PaymentLinkPageWrapper(props) {
    const { t } = useTranslation()
    return (
        <>
            <Helmet
                title={HelmetLib.generatePageTitle(t("payment.paymentLinkPage.title"))}
            />
            <StripeWrapper>
                <PaymentLinkPage {...props} />
            </StripeWrapper>
        </>
    )
}