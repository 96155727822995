export default {
    "hello": "HELLO",
    "breadcrumbs": {
        "home": "Home",
        "admin": {
            "instance": "Instance Administration",
            "organization": "Organization Administration"
        },
        "userProfile": "User Profile"
    },
    "auth": {
        "signupForm": {
            "headerTitle": "Register your Biuwer account",
            "signup": "Sign Up",
            "emailNotValidMessage": "Invalid email format",
            "termsAndPrivacyMessage": "I agree to the <0>Terms of Service</0> and <1>Privacy Policy</1>",
            "marketingMessage": "I would like to receive updates, tips, and offers via email",
            "submitButton": "Sign Up",
            "signinLabel": "Already have an account? Sign In",
            "createDemoContent": "Create demo content"
        },
        "signupEmailSent": {
            "almostThereMessage": "You're almost there!",
            "verifyEmailMessage": "Verify your email to complete sign up.",
            "infoSafeMessage": "To keep your information safe and secure, an email has been sent to:"
        },
        "signupConfirmation": {
            "welcome": "Welcome to Biuwer",
            "welcomeInfo": "You are about to create the organization",
            "welcomeInfo2": "in which you will be the main administrator.",
            "headerTitle": "Complete sign up with a secure password",
            "headerFinishTitle": "We are finishing the creation of your Biuwer account",
            "processingSignup": "Processing signup...",
            "submitButton": "Complete"
        },
        "signinForm": {
            "headerTitle": "Access to your Biuwer account",
            "signin": "Sign In",
            "forgotPasswordLabel": "Forgot password?",
            "oneTimeAccessLabel": "Get one-time link access",
            "emailNotValidMessage": "Invalid email format",
            "submitButton": "Submit",
            "helpChat": "Chat with support",
            "signupLabel": "Don't have an account? Sign Up"
        },
        "forgotForm": {
            "headerTitle": "Get new password",
            "submitButton": "Send password",
            "confirmationMessage": "We have sent you an email with the instructions to recover your password"
        },
        "oneTimeForm": {
            "headerTitle": "Get one-time access link",
            "submitButton": "Send link",
            "helpDescription": "",
            "confirmationMessage": "Check your email. We have sent you an email with a link that will allow you to access."
        },
        "resetPassword": {
            "headerTitle": "Password change",
            "otpTitle": "Access with one time password",
            "passwordLabel": "New password",
            "reEnterPasswordLabel": "Enter password again",
            "submitButton": "Change password",
            "accessButton": "Access",
            "confirmationMessage": "Password changed succesfully.",
            "otpConfirmationMessage": "Access link is correct. You can now access the platform."
        },
        "welcomeUser": {
            "headerTitle": "Welcome to Biuwer",
            "adminAddedYou": "An administator has invited you to colaborate in the organization {{name}}",
            "completeUserData": "To continue you have to finish your user account validation setting a secure password"
        },
        "pageNotFound": {
            "pageNotFoundMessage": "Page not found",
            "goHomePageMessage": "Go to home page",
            "goBackMessage": "Go back"
        },
        "forbiddenAccess": {
            "forbiddenAccessMessage": "Access denied"
        },
        "userNameLabel": "Name",
        "userSurnameLabel": "Surname",
        "organizationNameLabel": "Organization",
        "phoneLabel":"Phone",
        "emailLabel": "E-mail",
        "emailTooltipMessage": "Email address",
        "passwordLabel": "Password",
        "validatePasswordLabel": "Repeat Password",
        "passwordTooltipMessage": "Type your password",
        "passwordMinLengthMessage": "Password must be at least 6 characters long",
        "passwordNotMatchMessage": "Password don't match",
        "reviewPasswordRequirements": "Check password requirements",
        "passwordStrength" : {
            "passwordRequirementsMessage": "Minimum requirements for the new password",
            "minusLabel": "A lowercase letter",
            "mayusLabel": "An uppercase letter",
            "numberLabel": "A numerical digit",
            "specialLabel": "A special character",
            "lengthLabel": "At least 7 characters"
        },
        "back": "Back",
        "cancel": "Cancel",
        "accessSettingsLabel": "Access settings"
    },
    "admin": {
        "title": "Administration",
        "instance": {
            "title": "Instance config",
            "idLabel": "Instance Id",
            "descriptionLabel": "Desc",
            "createdLabel": "Install date",
            "extendTrial": {
                "title": "Extend trial",
                "titleTo": "Extend trial to {{name}}",
                "currentStatus": "Current Status",
                "currentEndDate": "Current end date",
                "trialEndedAt": "Trial ended at",
                "newTrialEndDate": "New trial end date"
            }
        },
        "organization": {
            "title": "Organization",
            "title_plural": "Organizations",
            "orgId": "Org. Id",
            "showOrganizations": "Show Organizations",
            "nameLabel": "Name",
            "businessNameLabel": "Business Name",
            "fiscalIdLabel": "Business Number",
            "townNameLabel": "City",
            "provinceNameLabel": "Province/State",
            "postalCodeLabel": "Postal Code",
            "countryLabel": "Country",
            "fiscalAddressLabel": "Tax residence",
            "typeLabel": "Type",
            "defaultLabel": "Default organization",
            "connected": "Connected",
            "connectedTo": "Connected to",
            "connect": "Connect",
            "availableOrgs": "Available organizations",
            "selectOrg": "Select organization",
            "switchTo": "Switch to",
            "default": "Default",
            "setDefault": "Set as default",
            "switchOrgTitle": "Change organization",
            "switchOrgModalCloseButtonLabel": "Close",
            "withoutOrganizationLabel": "Without organization",
            "hideCoreRolesLabel": "Hide core roles",
            "hideCoreRolesDescription": "If activated, users from each organization won't see core roles in their role list",
            "createdLabel": "Creation Date",
            "languageLabel": "Language",
            "mainLanguageLabel": "Main language",
            "organizationName": "Organization name",
            "primaryContact": "Primary contact",
            "selectPlanLabel": "Select a plan to continue using Biuwer",
            "paymentPending": "Payment pending",
            "cancelSubscription": "Cancel subscription",
            "language": {
                "*": "Defined by the organization",
                "es": "Spanish",
                "en": "English"
            },
            "changeStatusTo": "Change status to",
            "changeTrialStatusTo": "Change trial status to",
            "initialStatus": "Initial status",
            "statusLabel": "Status",
            "status": {
                "trial": "Trial",
                "cancelled": "Cancelled",
                "paymentPending": "Payment Pending"
            },
            "showIntroContent": "Show welcome content",
            "showDemoSpace": "Show demo space",
            "multiLanguageContent": "Multi language content",
            "useBrowserLanguage": "Use browser language",
            "translationsFor": "Translations for",
            "aditionalLanguages": "Additional languages",
            "availableLanguages": "Available languages",
            "noLanguagesConfig": "There are no languages configured",
            "changeContentLanguage": "Change language",
            "applyFeatureWall": "Apply feature wall",
            "automaticBilling": "Automatic billing",
            "customStyles": {
                "title": "Custom styles",
                "customStylesWarningMessage": "Custom styles applies only on shared resources",
                "disabledCustomStyles": "Custom styles disabled",
                "enabledCustomStyles": "Custom styles enabled",
                "applyToAll": "Apply to all",
                "generalStyles": "General styles",
                "headerStyles": "Header styles",
                "fixHeaderBoxShadowColor": "Fixed header shadow color",
                "cardStyles": "Card styles",
                "collectionStyles": "Collection styles",
                "font": "Font",
                "filigreeColor": "Filigree color",
                "primaryButton": "Main button",
                "secondaryButton": "Side button",
                "primaryButtonColor": "Main button",
                "desc": "Description",
                "content": "Content",
                "tabs": "Tabs",
                "tabsBackgroundColor": "Tabs background color",
                "activeTabColor": "Active tab color",
                "backgroundColor": "Background color",
                "text": "Text"
            },
            "themes": {
                "title": "Theme",
                "title_plural": "Themes",
                "builtinTheme": "Built-in Theme",
                "builtinTheme_plural": "Built-in Themes",
                "customTheme": "Custom Theme",
                "customTheme_plural": "Custom Themes",
                "addTheme": "Create new Theme",
                "seeUsage": "See usage",
                "generalStylesStatus": "General styles {{status}}",
                "collectionStylesStatus": "Collection styles {{status}}",
                "pageStylesStatus": "Page styles {{status}}",
                "cardStylesStatus": "Card styles {{status}}",
                "filterStylesStatus": "Filter styles {{status}}",
                "themesListInfo": "Use themes to style and color your content. The theme system allow you to apply complex styling in multiple and flexible ways.",
                "themesListBuiltInInfo": "Use any of these predefined themes by Biuwer in your account.",
                "themesListCustomInfo": "Define your ouw custom themes to apply any styles to any content.",
                "themeEditorInfo": "Styles can be configured to be applied to different areas of content, with the following options.",
                "inheritedTheme": "Inherited theme",
                "themeForm": {
                    "general": {
                        "appMenuAndDropdowns": "App's menus and dropdowns",
                        "dataWarnings": "Data warnings",
                        "informativeText": "Informative text",
                        "getDataButton": "Get data button",
                        "configMenuButton": "Configuration button"
                    }
                }
            },
            "colorPalettes": {
                "title": "Color palette",
                "title_plural": "Color palettes",
                "builtinColorPalette": "Built-in Color palette",
                "builtinColorPalette_plural": "Built-in Color palettes",
                "customColorPalette": "Custom Color palette",
                "customColorPalette_plural": "Custom Color palettes",
                "addColorPalette": "Create Color palette",
                "editColorPalette": "Edit Color palette",
                "seeUsage": "See usage",
                "colorPalettesListInfo": "Use color palettes to define recurring color schemes for your charts, maps and other compatible visual elements. Color palettes can be used directly or through the theme system.",
                "colorPalettesListBuiltInInfo": "Use any of these predefined color palettes by Biuwer, ready to use with your content.",
                "colorPalettesListCustomInfo": "Define your ouw custom color palettes to apply any color scheme to any content.",
                "colorsNumber": "# Colors",
                "color": "Color",
                "color_plural": "Colors",
                "paletteTypes": {
                    "qualitative": "Qualitative",
                    "sequential": "Sequential",
                    "diverging": "Diverging"
                },
                "edit": {
                    "createTitle": "Create Color Palette",
                    "editTitle": "Edit Color Palette",
                    "duplicateTitle": "Duplicate Color Palette",
                    "moveColor": "Move color in palette",
                    "removeColor": "Remove color from palette",
                    "repetitions": "Repetitions",
                    "repetitionPctDegrade": "% of color degradation",
                    "repetitionsNumber": "Number of repetitions",
                    "keyColors": "Key colors",
                    "startColor": "Start color",
                    "middleColor": "Middle Color",
                    "endColor": "End Color",
                    "numberOfColors": "Number of colors",
                    "defineYour": "Define your",
                    "ownColors": "Own colors",
                    "paletteName": "Palette name",
                },
                "deleteColorPaletteMessage": "Are you sure you want to delete the color palette <b>\"{{- name}}\"</b>?",
                "inUse": "In use"
            },
            "customMessages": {
                "title": "Custom Message",
                "title_plural": "Custom Messages",
                "customMessagesInfo": "Custom Messages allow to include custom texts and icons, including the translations of content using the Multi Language feature. Check each message description to know its usage.",
                "messages": {
                    "noData": {
                        "title": "No data available",
                        "description": "This message shows up inside each card when there is no data available for the selected filters. This is not an error, just an absence of data to show."
                    },
                    "forbiddenAccess": {
                        "description": "This message shows up when someone is trying to access any content that is not allowed to access. Personalize this to unify messaging with your application."
                    },
                    "queryDataError": {
                        "description": "This message shows up inside each card when an error occurs during the execution of data queries. Errors can be caused for example, after changing physical schema of datasets."
                    },
                    "applicationError": {
                        "title": "Application Error",
                        "description": "This content is showed up to end users when, for whatever reason, a non controlled error happens in Biuwer. You can personalize texts, formats ands links."
                    }
                },
                "previewCustom": "Preview custom",
                "previewDefault": "Preview default",
                "preview": "Preview",
                "resetDefaultValues": "Reset to default values",
                "link1Text": "Link 1 text",
                "link2Text": "Link 2 text",
                "link1Url": "Link 1 URL",
                "link2Url": "Link 2 URL",
                "text2": {
                    "title": "Title text",
                    "message": "Message text",
                    "messageFormat": "Message format",
                    "titleFormat": "Title format"
                },
                "emptyLinkInfo": "If empty, go back."
            },
            "deleteOrganizationMessage": "Are you sure? This action will start the deletion process for the organization {{name}}."
        },
        "trial": {
            "status": {
                "trialing": "Trialing",
                "trialingInfo": "Plan not selected",
                "converted": "Converted",
                "convertedInfo": "Plan selected and charges accepted",
                "expired": "Expired",
                "expiredInfo": "Trial period has expired"
            },
            "trialPeriod": "Trial period",
            "trialEndsIn": "Trial ends in",
            "regularUseStartAt": "Regular use starts at",
            "trialExpired": "Your trial period has expired",
            "contactUsP1": "You can",
            "contactUsP2": "contact us",
            "contactUsP3": "for help or get an extension of your trial",
            "selectPlan": "Please select a plan to activate your organization and continue using Biuwer",
            "trialWall": {
                "trialExpired": "Your Organization's trial period has expired",
                "SubscribeOrContact": "Please configure your subscription now or chat with us and get an extension using upside buttons"
            }
        },
        "billing": {
            "products": "Products and services",
            "subscription": "Subscription",
            "billingInterval": "Billing interval",
            "billingIntervals": {
                "month": "Monthly",
                "year": "Anualy"
            },
            "defaultPaymentMethods": {
                "wireTransfer": "Wire Transfer",
                "creditCard": "Credit Card",
                "directDebit": "Direct Debit"
            },
            "currencies": {
                "usd": "$",
                "usdLabel": "Dollar ($)",
                "usdLabel_plural": "Dollars ($)",
                "eur": "€",
                "eurLabel": "Euro (€)",
                "eurLabel_plural": "Euros (€)",
            },
            "noProductsOrServicesMessage": "There are no products or services included right now in your subscription",
            "companyNameAndAddress": "Company name and address",
            "companyName": "Company name",
            "paymentInformation": "Payment information",
            "paymentInformationMessage": "You can choose your payment method in every purchase, between <b>Credit or Debit Card, Direct Debit or Wire Transfer</b>, depending on your billing interval and your country.",
            "LocationCurrencyMessage": "For your location, you will be billed in {{currency}}",
            "currentPeriod": "Current period",
            "nextPeriodStartsAt": "Next period starts at",
            "nextInvoiceOn": "Next invoice on",
            "productsAndServicesInfo": "Below is the list of purchased products and services that are billed <b>{{- period}}</b>",
            "billingAmountAndDateLabel": "Your organization will be billed {{amount}} on {{date}} (prices don't include any applicable taxes)",
            "additionalNotes": "Additional notes",
            "additionalNotesMessage": "Use this field for any additional information you would like to include on your invoices",
            "businessPurchaseLabel": "Is a business purchase?",
            "streetAddressLabel": "Street address",
            "VATNumberLabel": "VAT number",
            "companyNameMessage": "This information will be included on all billing invoices on your organization",
            "billingContacts": "Billing contacts",
            "billingContactsMessage": "These contacts will be notified via email of everything related with the subscription billing, besides the primary contact",
            "noBillingContactsMessage": "There are currently no billing contacts",
            "addBillingContact": "Add billing contact"
        },
        "users": {
            "title": "User",
            "title_plural": "Users",
            "nameLabel": "Name",
            "surnamesLabel": "Last name",
            "phoneLabel": "Phone",
            "emailLabel": "Email",
            "changeEmail": "Change email",
            "passwordLabel": "Password",
            "statusLabel": "Status",
            "statusActive": "Active",
            "statusInactive": "Inactive",
            "createdLabel": "Creation Date",
            "lastSeenAt": "Last connection",
            "activeUsers": "{{count}} active user",
            "activeUsers_plural": "{{count}} active users",
            "inactiveUsers": "{{count}} inactive user",
            "inactiveUsers_plural": "{{count}} inactive users",
            "deletedUsers": "{{count}} deleted user",
            "deletedUsers_plural": "{{count}} deleted users",
            "listOfLabel": "User list",
            "addUserLabel": "Add User",
            "addUserLabel_plural": "Add Users",
            "addBasicUser": "Add Basic User",
            "addBasicUser_plural": "Add Basic Users",
            "addAdvancedUser": "Add Advanced User",
            "addAdvancedUser_plural": "Add Advanced Users",
            "assignUserLabel": "Assign User",
            "assignUserLabel_plural": "Assign Users",
            "definePassword": "Set password",
            "generateAutomaticPassword": "Generate automatic password",
            "confirmPasswordLabel": "Confirm password",
            "assignTemporalPassword": "A temporary password will be assigned",
            "incorrectLengthPasswordMessage": "Password must be at least {{count}} characters length",
            "checkPasswordRequirementsMessage": "Check password security requirements",
            "notMatchingPasswordsMessage": "Your passwords don't match",
            "sendEmailToUser": "Send email to user",
            "successCreatedMessage": "User created successfully with name",
            "errorCreatedMessage": "Error creating user with name",
            "successUpdatedMessage": "User updated successfully",
            "errorUpdatedMessage": "Error updating user",
            "sendEmailMessage": "An email has been sent with to {{email}} with the access details",
            "confirmEmailLabel": "Confirm email",
            "incorrectEmailFormatMessage": "Invalid email format",
            "notMatchingEmailsMessage": "Your emails don't match",
            "userExistMessage": "A user with this email already exists",
            "deleteConfirmationMessage": "Are you sure you want to delete this user from the organization?",
            "deleteUserMessage": "Are you sure you want to delete the user {{user}}?",
            "deleteUserFromPlatformMessage": "Are you sure you want to delete the user {{user}}? User will be removed from all organizations in which is included and will not be able to access the platform.",
            "deleteUserSelectMessage": "Select a user to transfer ownership of all their objects",
            "deleteUserPersonalSpaceMessage": "Objects contained into the personal space of the user to be deleted will be transferred to the personal space of the selected user.",
            "deleteUserAuthTokensMessage": "Authentication Tokens associated with the user to be deleted will be also transferred to the selected user.",
            "deleteUserObjectProfileMessage": "User will be deleted from object access settings. Check that data and content permissions are correct.",
            "addUserToOrganizationLabel": "Add user to organization",
            "userExistInPlatformMessage": "<0>A user with this email already exists.</0> If you want to add this user to your organization please click in the 'Add' green button",
            "masqueradeUserActAsLabel": "Connect as",
            "masqueradeUserRecoverMessage": "Masquerade mode. Connected as",
            "masqueradeUserCloseSessionLabel": "Close session",
            "ownerLabel": "Owner",
            "addEditUsers": "Add/Edit Users",
            "activationMethod": "Activation mode",
            "activateByEmail": "Activate by email",
            "activateNow": "Activate now",
            "setPassword": "Set password",
            "warningActivateNowMessage": "<0>The new user will be active upon creation,</0> being able to use Biuwer with a regular login or using shared content with a valid authentication token.",
            "warningNewUserMessage": "<0>The new user will be created with activation pending.</0> To active the account it's necessary to send him the welcome email. The user will establish his password.",
            "warningPendingUserMessage": "<0>The user is pending activation.</0> To activate it, it's necessary to send him the welcome email. The user will establish his password.",
            "warningAddUserActivateNowMessage" : "<0>The user will be active right after adding it to your organization</0>, being able to use Biuwer with a regular login or using shared content with a valid authentication token.",
            "warningAddUserActivateByEmailMessage" : "<0>The user will be added to your organization with activation pending.</0> To activate the user it's necessary to send a welcome email and the user will establish the initial password at first login.",
            "selectUserType": "Select user type",
            "basicUserFeatures": "Basic users can only view and analyze data in Pages and Cards of shared spaces. They don't have a Personal Space and cannot share content outside Biuwer.",
            "advancedUserFeatures": "Advanced users can fully use all Biuwer features. Usually they view, edit and manage content in Shared Spaces and their own Personal Space. They can also share content outside Biuwer, access de Data Center and other tools within the platform.",
            "userTypeLabel": "User type",
            "switchToAdvancedLabel": "Switch to Advanced",
            "switchToBasicLabel": "Switch to Basic",
            "showingObjectIncludedInUser": "Showing <b>{{- object}}</b> where the user is included",
            "showingObjectIncludedInUser_plural": "Showing <b>{{- object}}</b> where the user is included",
            "showingObjectAssignedToUser": "Showing <b>{{- object}}</b> assigned to the user",
            "showingObjectAssignedToUser_plural": "Showing <b>{{- object}}</b> assigned to the user",
            "showingObjectLinkedToUser": "Showing <b>{{- object}}</b> linked to the user",
            "showingObjectLinkedToUser_plural": "Showing <b>{{- object}}</b> linked to the user",
            "usersListHeaderDescription": "Manage <strong>Basic and Advanced Users</strong> to give individual access to Biuwer platform.",
            "noGroupsMessage": "User is not included in any Group",
            "noRolesMessage": "User has no Roles assigned",
            "noCustomFieldsMessage": "There are no Custom Fields linked to User",
            "noAuthTokensMessage": "There are no Auth Tokens linked to User",
            "deleteGroupFromUserMessage": "Are you sure you want to unassign the user from the group <b>{{- group}}</b>?",
            "deleteRoleFromUserMessage": "Are you sure you want to unassign the user from the role <b>{{- role}}</b>?",
            "advancedUsersLabel": "Advanced Users",
            "advancedUserLabel": "Advanced User",
            "advancedUserLabel_plural": "Advanced Users",
            "basicUsersLabel": "Basic Users",
            "basicUserLabel": "Basic User",
            "basicUserLabel_plural": "Basic Users",
            "showInstanceUsers": "Show instance users",
            "organizationsOfUser": "Organizations of User: {{userFullName}}",
            "switchUserType": {
                "main": "Are you sure about this operation?",
                "details": {
                    "basic": "<div class=\"mb10\">If you confirm by clicking Accept, the user's personal space will be deleted and all the objects owned by the user will be transferred to the next designated advanced user.</div><div>From now on, the user will only have the View permission where it is set up and will not be able to share content outside of Biuwer.</div>",
                    "advanced": "<div>If you confirm by clicking Accept:</div><ul><li>A personal space will be created for the user where he/she will be the owner of the objects he/she creates.</li><li>The user will be able to have View, Edit and Manage security levels when configuring the security of objects.</li><li>The user will be able to assign the available Roles, to facilitate access and management of content, data and the Organization itself.</li></ul>"
                },
                "newOwnerSelect": "Select a user to transfer ownership of all his/her objects to:"
            }
        },
        "groups": {
            "title": "Group",
            "title_plural": "Groups",
            "nameLabel": "Name",
            "nameOfGroupLabel": "Group name",
            "descriptionLabel": "Description",
            "statusLabel": "Status",
            "statusActive": "Active",
            "statusInactive": "Inactive",
            "createdLabel": "Creation date",
            "activeGroups": "{{count}} active group",
            "activeGroups_plural": "{{count}} active groups",
            "inactiveGroups": "{{count}} inactive group",
            "inactiveGroups_plural": "{{count}} inactive groups",
            "listOfLabel": "User group list",
            "addGroupLabel": "Add Group",
            "addGroupLabel_plural": "Add Groups",
            "noUsersInGroup": "There are no users in this group",
            "noRolesInGroup": "There are no roles in this group",
            "assignGroupLabel": "Assign Group",
            "assignGroupLabel_plural": "Assign Groups",
            "deleteGroupMessage": "Are you sure you want to delete the group <b>\"{{- group}}\"</b>?",
            "coreGroups": {
                "adminGroup": {
                    "name": "Administrators",
                    "desc": "Organization's administration group"
                },
                "allBasicUsers": {
                    "name": "All basic users",
                    "desc": "Dynamic group with all basic users"
                },
                "allAdvancedUsers": {
                    "name": "All advanced users",
                    "desc": "Dynamic group with all advanced users"
                }
            },
            "groupTypes": {
                "builtin": "Built-in",
                "custom": "Custom"
            },
            "addEditGroups": "Add/Edit Groups",
            "selectGroupType": "Select group type",
            "groupFeatures": "A group it's a container of Users, that allows to view, edit and manage Pages and Cards of shared Spaces. You can also assign Roles.",
            "groupTypeLabel": "Group type",
            "groupsListHeaderDescription": "Manage <b>Groups</b> to apply to a collection of Basic and Advanced Users the same access levels and roles.",
            "showingObjectIncludedInGroup": "Showing <b>{{- object}}</b> assigned to the group.",
            "deleteUserFromGroupMessage": "Are you sure you want to unassign the user <b>{{- user}}</b> from the group?",
            "deleteRoleFromGroupMessage": "Are you sure you want to unassign the role <b>{{- role}}</b> from the group?",
            "noRolesInGroupMessage": "There are no roles assigned to this group.",
            "groupRolesInfoMessage": "The roles assigned to this group will only be applied to advanced users.",
            "groupAccessInfoMessage": "The selected access level will be applied to advanced users in the group and access level View will be applied to basic users in the group"
        },
        "roles": {
            "title": "Role",
            "title_plural": "Roles",
            "nameLabel": "Name",
            "descriptionLabel": "Description",
            "statusLabel": "Status",
            "statusActive": "Active",
            "statusInactive": "Inactive",
            "createdLabel": "Creation date",
            "activeRoles": "{{count}} active rol",
            "activeRoles_plural": "{{count}} active roles",
            "inactiveRoles": "{{count}} inactive role",
            "inactiveRoles_plural": "{{count}} inactive roles",
            "deletedRoles": "{{count}} deleted role",
            "deletedRoles_plural": "{{count}} deleted roles",
            "noPermissionsInRole": "There are no permissions in this role",
            "noUsersInRole": "This role is not associated with any user",
            "noGroupsInRole": "This role is not associated with any group",
            "assignRoleLabel": "Assign Role",
            "assignRoleLabel_plural": "Assign Roles",
            "listOfLabel": "Roles list",
            "showingObjectIncludedInRole": "Showing <b>{{- object}}</b> where the role is included",
            "showingObjectIncludedInRole_plural": "Showing <b>{{- object}}</b> where the role is included",
            "showingObjectAssignedToRole": "Showing <b>{{- object}}</b> assigned to the role",
            "showingObjectAssignedToRole_plural": "Showing <b>{{- object}}</b> assigned to the role",
            "showingObjectAssociatedToRole": "Showing <b>{{- object}}</b> associated to this role",
            "showingObjectAssociatedToRole_plural": "Showing <b>{{- object}}</b> associated to this role",
            "showingObjectLinkedToRole": "Showing <b>{{- object}}</b> linked to the role",
            "showingObjectLinkedToRole_plural": "Showing <b>{{- object}}</b> linked to the role",
            "deleteUserFromRoleMessage": "Are you sure you want to unsassign the user <b>\"{{- user}}\"</b> from the role?",
            "deleteGroupFromRoleMessage": "Are you sure you want to unsassign the group <b>\"{{- group}}\"</b> from the role?",
            "rolesListHeaderDescription": "Roles are collections of permissions that allow Advanced Users and Standard Groups to perform certain actions in Biuwer.",
            "coreRoles": {
                "superAdmin": {
                    "name": "Organization 1 Super Admin",
                    "desc": "Organization 1 Super Admin Super administrador de la Organización 1"
                },
                "superViewer": {
                    "name": "Organization 1 Super Viewer",
                    "desc": "Organization 1 Super Viewer"
                },
                "orgAdmin": {
                    "name": "Organization Administrator",
                    "desc": "Administer the Organization"
                },
                "contentAdmin": {
                    "name": "Content Administrator",
                    "desc": "Administer all Spaces, Pages and Cards"
                },
                "contentEditor": {
                    "name": "Content Editor",
                    "desc": "Edit all Spaces, Pages and Cards"
                },
                "contentViewer": {
                    "name": "Content Viewer",
                    "desc": "View all Spaces, Pages and Cards"
                },
                "dataAdmin": {
                    "name": "Data Administrator",
                    "desc": "Administer all Connections, Datasets and Data Models"
                },
                "dataEditor": {
                    "name": "Data Editor",
                    "desc": "Edit all Connections, Datasets and Data Models"
                },
                "basic": {
                    "name": "Basic role",
                    "desc": "Access and have a basic use of Biuwer"
                },
                "jobberAdmin": {
                    "name": "Executions Administrator",
                    "desc": "Administer Jobs, Steps, Executions and Agents"
                },
                "jobberEditor": {
                    "name": "Executions Editor",
                    "desc": "Edit Jobs, Steps, Executions and Agents"
                },
                "jobberViewer": {
                    "name": "Executions Viewer",
                    "desc": "View Jobs, Steps, Executions and Agents"
                }
            }
        },
        "permissions": {
            "title": "Permission",
            "title_plural": "Permissions",
            "actionsTitle": "Action",
            "actionsTitle_plural": "Actions",
            "listOfLabel": "Permission list",
            "accessLabel": "Access",
            "accessSettingsLabel": "Access settings",
            "currentOwner": "Current owner",
            "currentAccess": "Current Access",
            "enterUsersLabel": "Enter user, group or email",
            "viewLabel": "View",
            "editLabel": "Edit",
            "adminLabel": "Admin",
            "customPermissions": "Customize permissions",
            "inheritedPermissions": "Inherited access permissions",
            "definedPermissionsSection": "Access permissions defined here",
            "inheritPermissions": "Inherit permissions",
            "applyPermissionsToChildren": "Set children to have inherited permissions",
            "applyPermissionsToChildrenInfo": "By checking this option, when saving, it is established in all children that their access permissions are inherited from this configuration",
            "noAccessDefined": "No access has been defined",
            "parentWillBeApplied": "Parent permissions will be applied on save"
        },
        "policies": {
            "dataPoliciesLabel": "Data Policies",
            "enabledDataPolicies": "Data Policies enabled",
            "disabledDataPolicies": "Data Policies disabled",
            "addPolicy": "Add Policy",
            "configurePolicyFilter": "Configure policy filter",
            "configurePolicyGroups": "Policy groups",
            "configurePolicyUsers": "Policy users",
            "selectPolicyGroups": "Select groups to apply policy",
            "selectPolicyUsers": "Select users to apply policy",
            "newPolicy": "New Policy",
            "selectGroup": "Select group",
            "allGroupsSelected": "All groups had been selected",
            "noGroupsSelected": "No groups selected",
            "selectUser": "Select user",
            "allUsersSelected": "All users had been selected",
            "noUsersSelected": "No users selected",
            "noDataPoliciesEnabled": "This dataset does not have data policies enabled. Every user with query permissions can see all available data.",
            "noDataPolicies": "This dataset does not have any data policies defined. Click on ADD POLICY button to configure data policies.",
            "deleteMessage": "Are you sure you want to delete the data policy <b>\"{{- dataPolicy}}\"</b>?",
            "applyAlways": "Force",
            "applyAlwaysInfo": "If a Data Policy is forced to apply, this Dataset will be included in any query that the configured Users and Groups make through any Data Model in which this Dataset has been included.",
            "dataPoliciesMode": "Behaviour between policies:",
            "dataPoliciesModeTooltip": {
                "info": "Data Policy filters will be applied to configured users and groups, and may return results that meet:",
                "orInfo": "<b>OR:</b> <b><u>SOME</u></b> of the filters",
                "andInfo": "<b>AND:</b> <b><u>ALL</u></b> filters"
            }
        },
        "customFields": {
            "title": "Custom field",
            "title_plural": "Custom fields",
            "simpleFields": "Simple fields",
            "groupOfFields": "Fields groups",
            "createDialogTittle": "Create custom field",
            "updateDialogTittle": "Update custom field",
            "createFieldDialogTittle": "Create simple field",
            "createGroupDialogTittle": "Create fields group",
            "updateFieldDialogTittle": "Update simple field",
            "updateGroupDialogTittle": "Update fields group",
            "addFieldToGroup": "Add custom field to group",
            "addField": "Add field",
            "subfieldsEmpty": "Empty subfields list",
            "createSubfield": "Create subfield",
            "updateSubfield": "Update subfield",
            "subfields": "Subfields",
            "subfieldsList": "Subfields list",
            "enumPlaceholder": "Separate values with semicolon ';' ...",
            "enumerateMsg": "Enum allow values",
        }
    },
    "user": {
        "profile": {
            "title": "User profile",
            "userTitle": "User profile: {{name}}",
            "nameLabel": "Name",
            "surnamesLabel": "Surname",
            "phoneLabel": "Phone",
            "emailLabel": "Email",
            "passwordLabel": "Password",
            "changePassword": "Change password",
            "currentPasswordLabel": "Current password",
            "currentPasswordTooltipMessage": "Enter your current password",
            "changePasswordTooltipMessage": "Enter your new password",
            "reEnterPasswordLabel": "Confirm your new password",
            "editButton": "Edit profile",
            "cancelButton": "Cancel",
            "saveButton": "Save",
            "nameInvalidMessage": "Name field is required",
            "emailInvalidMessage": "Invalid email format",
            "emailExistsMessage": "Email already exists",
            "infoHeadingLabel": "Profile info",
            "userLabel": "User",
            "userLabel_plural": "Users",
            "userNameLabel": "User name"
        }
    },
    "menus": {
        "dataCenter": {
            "connections": {
                "title": "Connections",
                "myConnections": "My Connections",
                "connectionCatalog": "Connection Catalog",
                "newConnection": "New Connection",
                "editConnection": "Edit Connection",
                "reverseEngineering": "Reverse Engineering",
            },
            "dataAndModels": {
                "title": "Data and Models",
                "datasets": "Datasets",
                "datamodels": "Data Models"
            },
            "dataPreparation": {
                "title": "Data Preparation",
                "jobs": "Jobs",
                "steps": "Steps",
                "executions": "Executions",
                "agents": "Agents",
                "stepCatalog": "Step Catalog"
            },
            "dataWarehouse": {
                "title": "Data Warehouse",
                "configuration": "Configuration",
                "schemas": "Schemas",
                "security": "Security",
                "accessAndAPI": "Access and API"
            }
        },
        "administration": {
            "generalInfo": {
                "title": "General Information",
                "overview": "Overview",
                "orgDetails": "Organization Details"
            },
            "usageAndBilling": {
                "title": "Usage and Billing",
                "usage": "Usage",
                "statistics": "Statistics",
                "plans": "Plans",
                "orderHistory": "Order History",
                "paymentInfo": "Payment Information",
                "orderDetails": "Order Details"
            },
            "settings": {
                "title": "Settings",
                "mapCatalog": "Map catalog",
                "localization": "Localization",
                "customFields": "Custom Fields",
                "regionalSettings": "Regional Settings",
                "generalSettings": "General Settings",
                "contentSettings": "Content Settings",
                "languageSettings": "Language Settings"
            },
            "security": {
                "title": "Security",
                "authSettings": "Authentication Settings",
                "users": "Users",
                "groups": "Groups",
                "roles": "Roles",
                "authTokens": "Authentication Tokens",
                "apiKeys": "API Keys"
            },
            "content": {
                "title": "Content",
                "sharedResources": "Shared Resources"
            }
        },
        "supportChatButton":"Chat with Support",
        "user": {
            "configInstance": "Instance config",
            "configOrganization": "My Organization",
            "switchOrganization": "Change Organization",
            "profile": "My profile",
            "logout": "Log out"
        },
        "instanceAdmin": {
            "title": "Instance",
            "generalInfo": {
                "title": "General Information",
                "organizations": "Organizations",
                "users": "Users"
            }
        }
    },
    "content": {
        "share": {
            "share": "Share",
            "accessType": "Access type",
            "validityPeriod":"Validity period",
            "deleteResourceToken": "Stop Sharing",
            "noResourceTokenFound": "The {{object}} is currently not being shared",
            "noResourceTokenFound_female": "The {{object}} is currently not being shared",
            "resourceTokenFound": "The {{object}} is being shared",
            "resourceTokenFound_female": "The {{object}} is being shared",
            "resourceTokenUrlCode": "URL for sharing:",
            "resourceTokenSecureFrameCode": "IFRAME to insert:",
            "frameSize": "Size in pixels:",
            "resourceId": "Resource Id:",
            "authTokenNeeded": "HERE_MUST_BE_AN_AUTH_TOKEN",
            "deleteResourceTokenMessage": "Are you sure you want to stop sharing the {{object}}? It will be unavailable outside of Biuwer",
            "deleteResourceTokenMessage_female": "Are you sure you want to stop sharing the {{object}}? It will be unavailable outside of Biuwer",
            "sharedResource": "Shared resource"
        },
        "exports": {
            "exportToPdf": "Export to PDF",
            "export": "Export",
            "formatSelectLabel": "Select format",
            "pageStructureLabel": "Structure",
            "pageStructureDescription": "Print page keeping content structure.",
            "pageStructureWarning": "IMPORTANT: Result may vary depending on the selected size and orientaton, causing unwanted cuts in cards (mainly in charts and tables with a large amount of data).",
            "pageSequentialLabel": "Sequential",
            "pageSequentialDescription": "Prints each element in the page sequentially.",
            "numPageLabel": "Page",
            "numTotalPageLabel": "Page/Total",
            "cardAdjustLabel": "Adjust to available size",
            "includeHeader": "Include content header",
            "includeFilters": "Include filter area",
            "unrollCardContent": "Include visible and non-visible content"
        },
        "imports": {
            "file": "File",
            "file_plural": "Files",
            "fileTypeLabel": "File type",
            "uploadFileLabel": "Upload file",
            "deleteFileLabel": "Delete file",
            "validFileTypesLabel": "Valid file types: {{types}}",
            "mapFields": "Map fields",
            "selectSheet": "Select one sheet",
            "sheetSelection": "Sheet selection",
            "from": "From",
            "to": "To",
            "selectRange": "Select range",
            "column": "Column",
            "row": "Row",
            "rowRangeFormat": "Row format: Integer number (1, 2, 3)",
            "columnRangeFormat": " Column format: Capital letter (A, B, C)",
            "steps": {
                "fileSelect": "File to import selection",
                "datasetConfig": "Dataset configuration",
                "mappingFieldsConfig": "Mapping fields configuration",
                "noDatasetSelectedMessage": "Select a Dataset on previous step",
                "addFieldMessage": "Add fields to the new dataset to be created",
                "setDatasetNameMessage": "Add a name for the new dataset on previous step"
            }
        },
        "moveDuplicate": {
            "pageChildrenSelect": "Select what to do with cards contained on the page",
            "newParentSelect": "Select space destination",
            "moveLabel": "Move",
            "duplicateLabel": "Duplicate",
            "moveObject": "Move {{object}}",
            "duplicateObject": "Duplicate {{object}}",
            "childActions": {
                "none": "Delete from page",
                "noneExplanation": "Page will be moved with its content. Cards contained in this page will keep in its actual space.",
                "move": "Move",
                "moveExplanation": "Cards contained only in this page will be moved, those cards contained in more pages will be duplicated on the space destination.",
                "duplicate": "Duplicate",
                "duplicateExplanation": "Cards will be duplicated on space destination."
            },
            "pageMoveWarning": "Move and Duplicate operations only will be done over the cards which you have edit permission or higher, the rest will be deleted from page structure"
        },
        "canDelete": {
            "dependenciesFoundLabel": "Dependencies found",
            "checkingDependenciesLabel": "Checking dependencies",
            "deletionNotAllowed": "You have no permission for delete the {{object}}",
            "deletionNotAllowed_female": "You have no permission for delete the {{object}}",
            "objectNotFound": "{{object}} not found"
        }
    },
    "filters": {
        "dates": {
            "from": "From",
            "to": "To",
            "fromTo": "From/To",
            "ranges": "Ranges",
            "predefined": "Predefined",
            "notPredefined": "Not predefined",
            "level": "Level",
            "year": "Year",
            "year_plural": "Years",
            "allYears": "All years",
            "quarter": "Quarter",
            "quarter_plural": "Quarters",
            "allQuarters": "All quarters",
            "quarterSymbol": "Q",
            "month": "Month",
            "month_plural": "Months",
            "allMonths": "All months",
            "week": "Week",
            "week_plural": "Weeks",
            "allWeeks": "All weeks",
            "day": "Day",
            "day_plural": "Days",
            "hourOfDay": "Hour of the day",
            "hourOfDay_plural": "Hours of the day",
            "allDays": "All days",
            "inRange": "In range",
            "thisYear": "This year",
            "previousYear": "Previous year",
            "previousTwoYears": "Previous 2 years",
            "twoYearsAgo": "2 years ago",
            "lastTwoYears": "Last 2 years",
            "lastThreeYears": "Last 3 years",
            "thisQuarter": "This quarter",
            "previousQuarter": "Previous quarter",
            "previousTwoQuarters": "Previous 2 quarters",
            "twoQuartersAgo": "2 quarters ago",
            "threeQuartersAgo": "3 quarters ago",
            "lastTwoQuarters": "Last 2 quarters",
            "lastThreeQuarters": "Last 3 quarters",
            "lastFourQuarters": "Last 4 quarters",
            "thisMonth": "This month",
            "previousMonth": "Previous month",
            "previousTwoMonths": "Previous 2 months",
            "twoMonthsAgo": "2 months ago",
            "threeMonthsAgo": "3 months ago",
            "lastTwoMonths": "Last 2 months",
            "lastThreeMonths": "Last 3 months",
            "lastSixMonths": "Last 6 months",
            "lastTwelveMonths": "Last 12 months",
            "today": "Today",
            "yesterday": "Yesterday",
            "previousTwoDays": "Previous 2 days",
            "twoDaysAgo": "2 days ago",
            "threeDaysAgo": "3 days ago",
            "currentWeek": "Current week",
            "lastSevenDays": "Last 7 days",
            "lastFourteenDays": "Last 14 days",
            "lastThirtyDays": "Last 30 days",
            "lastSixtyDays": "Last 60 days",
            "lastHour": "Last hour",
            "lastTwoHours": "Last 2 hours",
            "lastThreeHours": "Last 3 hours",
            "lastFourHours": "Last 4 hours",
            "lastSixHours": "Last 6 hours",
            "lastEightHours": "Last 8 hours",
            "lastTwelveHours": "Last 12 hours",
            "lastTwentyFourHours": "Last 24 hours",
            "thisWeek": "This week",
            "lastWeek": "Last week",
            "previousTwoWeeks": "Previous 2 weeks",
            "twoWeeksAgo": "2 weeks ago",
            "threeWeeksAgo": "3 weeks ago",
            "lastTwoWeeks": "Last 2 weeks",
            "lastThreeWeeks": "Last 3 weeks",
            "lastFourWeeks": "Last 4 weeks",
            "lastSixWeeks": "Last 6 weeks",
            "lastEightWeeks": "Last 8 weeks",
            "lastTwelveWeeks": "Last 12 weeks",
            "yearToDate": "Year to date",
            "quarterToDate": "Quarter to date",
            "monthToDate": "Month to date",
            "weekToDate": "Week to date"
        },
        "numbers": {
            "greaterThan": "Greater than",
            "greaterOrEqualThan": "Greater or equal than",
            "smallerThan": "Smaller than",
            "smallerOrEqualThan": "Smaller or equal than",
            "equalThan": "Equal than",
            "equals": "Equal than",
            "notEqualThan": "Not equals to",
            "notEquals": "Not equals to",
            "between": "Between",
            "notBetween": "Not between",
        },
        "strings": {
            "startsWith": "Starts with",
            "notStartsWith": "Not starts with",
            "endsWith": "Ends with",
            "notEndsWith": "Not ends with",
            "contains": "Contains",
            "notContains": "Not contains"
        },
        "boolean": {
            "true": "True",
            "false": "False"
        },
        "filterType": {
            "date": "Date",
            "string": "String",
            "number": "Number",
            "field": "Field",
            "boolean": "Boolean"
        },
        "formatHelp": {
            "year": "Format: YYYY",
            "quarter": "Format: YYYY-Q",
            "month": "Format: YYYY-MM",
            "week": "Format: YYYY-WW",
            "day": "Format: DD/MM/YYYY",
            "hour_of_day": "Format: HH"
        },
        "formatHelpVT": {
            "year": "Format: YYYY",
            "quarter": "Format: Q",
            "month": "Format: MM",
            "week": "Format: WW",
            "year_quarter": "Format: YYYY-QQ",
            "year_month": "Format: YYYY-MM",
            "year_week": "Format: YYYY-WW",
            "full_date": "Format: YYYY-MM-DD",
            "full_date_time": "Format: YYYY-MM-DD HH:mm:ss",
            "hour_of_day": "Format: HH"
        },
        "urlParam": {
            "title": "URL Parameter",
            "description": "The filter value is defined by the URL parameter indicated below, when it is present and its value is of the type of data configured in the filter. The filter value will not be editable by the user.",
            "paramLabel": "Parameter"
        },
        "openFiltersArea": "Open filters area",
        "closeFiltersArea": "Close filters area",
        "cleanFilters": "Clean filters",
        "operationLabel": "Operation",
        "operationLabel_plural": "Operations",
        "excludeLabel": "Exclude",
        "between": "Between",
        "notBetween": "Not between",
        "inList": "In list",
        "notInList": "Not in list",
        "equals": "Equals to",
        "notEquals": "Not equals to",
        "selection": "Selection",
        "expression": "Expression",
        "aggregationLabel": "Aggregation",
        "topLabel": "Top",
        "top": "Top",
        "notTop": "Not top",
        "bottom": "Bottom",
        "notBottom": "Not bottom",
        "interval": "Interval",
        "notInterval": "Not interval",
        "isNull": "Is null",
        "isNotNull": "Is not null",
        "aggregateBy": "Aggregate by",
        "filterLabel": "filter",
        "filterLabel_plural": "filters",
        "filtersAreaLabel": "Filters area",
        "fieldsSelection": "Field selection",
        "selectField": "Select field",
        "noData": "No data available for the selected field",
        "noDataFilters": "No data available for the selected filters",
        "allValues": "All values",
        "multivalued": "Multiple values",
        "typeHere": "Type here",
        "startTyping": "Type for search",
        "filterValuesThat": "Filter values that",
        "preview": "Preview",
        "showingPartialFromTotal": "Showing {{partial}} of {{total}} results",
        "configureFilter": "Config filter",
        "condition": "Condition",
        "useCustomList": "Custom list",
        "editableLabel": "Editable",
        "mandatoryLabel": "Mandatory",
        "multiValueLabel": "Allow multiple values",
        "visibleLabel": "Visible",
        "cardsToUpdateInfo": "Select cards to apply this filter",
        "cardsToUpdateIndividual": "Select cards and apply configuration individually",
        "cardsToUpdateInBlock": "Apply in bulk",
        "applySameConfigToAllCards": "Apply same config to all cards",
        "cardsToUpdateLabel": "Cards to apply",
        "filtersToUpdateLabel": "Dependent filters",
        "filtersToUpdateInfo": "Select filters dependent from this filter",
        "filterUse": "Filter use",
        "noFilterToUpdateInfo": "Filter unavailable. It must be a field type filter",
        "dependentFiltersMustBeField": "Only field type filters can be dependent",
        "dependencyAlreadyExists": "A relationship with this filter already exists",
        "autoApplyUniqueValue": "Auto apply when having only one value",
        "showSelectionTab": "Show SELECTION tab",
        "showExpressionTab": "Show EXPRESSION tab",
        "showPredefinedTab": "Show PREDEFINED tab",
        "showTopBottomTab": "Show TOP tab",
        "dateLevel": "Date level",
        "allowedOperations": "Operations",
        "customizeFilterLabel": "Customize Filter",
        "addEditFilterDialogLabel": "Add/edit {{object}} filters",
        "cardFiltersApplied": "Filters applied to this card:",
        "queryUpdatedLabel": "Last update:",
        "queryUpdatedNowLabel": "Right now",
        "queryUpdatedTimeLabel": "{{qty}} {{unit}} ago",
        "selectValues": "Select values",
        "multipleValuesHelp": "You can add several values writing them separated by ; (semicolon)",
        "noFilterLabel": "No filter",
        "noFilterLabel_plural": "No filters",
        "noFiltersConfigured": "No filters configured",
        "FiltersConfigured": "Configured filters",
        "showingFiltersFromPageLabel": "Page filters",
        "pageFiltersInfoLabel": "Selected page filters area",
        "cardFiltersInfoLabel": "Card filters area",
        "cardWithNoFiltersLabel": "Card has no configured filter",
        "filterWithNoCardsLabel": "There are some filters without AFFECTED CARDS section completed. Are your sure you want to save?",
        "addFilterLabel": "Add Filter",
        "stageLabel": "Apply after group",
        "stageLabelInfo": "It will only be applied on measure fields",
        "noFiltersApplied": "This card doesn't have any filter applied",
        "cardFiltersModified": "Card filters have been modified.",
        "pageFiltersModified": "Page filters have been modified.",
        "clickPreviewToUpdate": "Click Preview button to update data visualization.",
        "selectFilter": "Select filter",
        "order": {
            "customOrderConfig": "Order configuration"
        },
        "sort": {
            "sortValues": "Order values",
            "customOrderConfig": "Order configuration",
            "customOrderInfo": "To apply a custom order, the number of values have to be less than {{qty}}",
            "otherField": "Other field"
        },
        "optionsTabLabel": "Usage and options",
        "filterOptions": "Filter options",
        "filtersEditor": "Filters editor",
        "numRecords": "# Records",
        "orderLabel": "Order",
        "visibleEditableInfo": "If the filter is not visible or editable, the default value will be applied or if a URL parameter is configured, the value that comes to it via URL or guided navigation",
        "dateValueInputInfo": "Depending on the date level chosen, these are the date formats: Year (YYYY), Quarter (YYYY-Q), Month (YYYY-MM) and Day (YYYY-MM-DD).",
        "allowedValuesInfo": "Type TAB or ENTER to confirm. You can use the character \";\" to split values.",
        "allowedValuesDateInfo": "The manual date format must be YYYY-MM-DD in all cases.",
        "groups": {
            "label": "Filters Groups",
            "new": "New Group",
            "create": "New Filters Group",
            "edit": "Filters Group editor",
            "addGroup": "Add Group",
            "addFilter": "Add Filter",
            "showGroup": "Show filters group",
            "showLogicalOperator": "Show logical operator (AND, OR) between Filters of the Group",
            "showNameAndColor": "Show Filter Group name and color",
            "nameInfo": "The name will show on top of all the filters contained in the filter group, using the text format and color specified.",
            "colorInfo": "The color and font will also be used to customize each filter content.",
            "groupsInfo": "Only applies on connections against a SQL-compatible database",
            "deleteMessage": "Are you sure you want to delete the filters group <b>\"{{- name}}\"</b>? This operation will delete filters included in group too.",
        },
        "dont_filter": {
            "label": "\"All values\" filters with effective values",
            "detail": "By default, for performance reasons and because it is valid in most cases, when a filter is configured with the \"All values\" option, it is not applied to data queries which means that it does not filter. However, there are cases where it is necessary to filter effectively by calculating the corresponding values in each case. For example with dependent filters or with limit values. If you check this option it will filter using the values that apply, and this will have an impact on the performance of contents and queries.",
            "detail1": "By default, for performance reasons and because it is valid in most cases, when a filter is configured with the \"All values\" option, it is not applied to data queries which means that it does not filter.",
            "detail2": "However, there are cases where it is necessary to filter effectively by calculating the corresponding values in each case. For example with dependent filters or with limit values.",
            "detail3": "If you check this option it will filter using the values that apply, and this will have an impact on the performance of contents and queries."
        }
    },
    "authTokens": {
        "title": "Auth Token",
        "title_plural": "Auth Tokens",
        "deleteAuthTokenMessage": "Are you sure you want to delete <br> <b>\"{{- authToken}}\"</b> auth token from <b>\"{{user}}\"</b> user?"
    },
    "apiKeys": {
        "title": "API Key",
        "title_plural": "API Keys",
        "deleteApiKeyMessage": "Are you sure you want to delete <br><b>\"{{- apiKey}}\"</b> API Key?"
    },
    "templates": {
        "templateLabel": "Template",
        "createTemplate": "Create template",
        "createTemplateBasedOn": "Create template based on",
        "content": "Content",
        "createDefaultContent": "Create default content"
    },
    "spaces": {
        "title": "Space",
        "title_plural": "Spaces",
        "createSpace": "Create space",
        "editSpace": "Edit space",
        "deleteSpaceMessage": "Are you sure you want to delete <b>\"{{- space}}\"</b> space?",
        "cantDeleteSpaceMessage": "The space <b>\"{{- space}}\"</b> can't be deleted",
        "cantDeleteSpaceMessage_plural": "The selected spaces can't be deleted",
        "personalSpace": "My space",
        "personalSpaces": "Personal spaces",
        "sharedSpaces": "Shared spaces",
        "selectSpace": "Select space",
        "spaceNotValid": "Space not valid",
        "selectValidSpace": "Select a space in the side menu",
    },
    "collections": {
        "collectionsLabel": "Collection",
        "collectionsLabel_plural": "Collections",
        "deleteCollectionMessage": "Are you sure you want to delete <b>\"{{- collection}}\"</b> collection?",
        "itemsLabel": "Items",
        "contentLabel": "Content",
        "withoutTitleLabel": "Untitled collection",
        "createCollection": "Create collection",
        "editCollection": "Edit collection",
        "contentSettings": "Content settings",
        "contentSettings_large": "Configure the number of items and its content in the collection",
        "noItemsInCollection": "Currently there are no items in the collection",
        "generalSettings": "General settings",
        "collectionGeneralSettings": "Collection general settings"
    },
    "pages": {
        "interactiveFlatTable": "Interactive flat table",
        "nameLabel": "Page",
        "nameLabel_plural": "Pages",
        "createPage": "Create page",
        "editPage": "Edit page",
        "structureLabel": "Structure",
        "editModeLabel": "Edit mode",
        "pagesList": "Pages list",
        "deletePageMessage": "Are you sure you want to delete <b>\"{{- page}}\"</b> page?",
        "cantDeletePageMessage": "The page <b>\"{{- page}}\"</b> can't be deleted",
        "cantDeletePageMessage_plural": "The selected pages can't be deleted",
        "withoutTitleLabel": "Untitled page",
        "pageFiltersLabel": "Page filters",
        "structureAndContentLabel": "Structure and content",
        "showFiltersArea": "Show filters area",
        "autoApplyFilters": "Auto apply filters",
        "generalTabTitle": "General",
        "styleTabTitle": "Style and format",
        "pageNoContent": "This page has no content.",
        "pageStructureSelect": "Start by selecting a predefined structure.",
        "pageJustCreatedMessage": "You have just created this page.",
        "nextStepsMessage": "Next steps:",
        "noContent": "Currently this page doesn't contain any card",
        "noContentAccess": "Currently your user has no access to cards contained in this page",
        "addContent": "Add content",
        "editNameAndDesc": "You can also edit name and description"
    },
    "cards": {
        "cardsLabel": "Card",
        "cardsLabel_plural": "Cards",
        "cardDescription": "Card description",
        "openFullScreen": "Show Card in maximized mode",
        "appliedFilters": "Applied filters",
        "cardOptions": "Card options",
        "cardConfigError": "There is an error in the card configuration.",
        "cardsCatalog": "Card catalog",
        "newCard": "New card",
        "dataCardEditor": "Data card editor",
        "assignExistentCard": "Add existing card",
        "createCard": "Create card",
        "editCard": "Edit card",
        "showTitle": "Show Title",
        "noContentAccess": "Currently your user has no access to this card",
        "deleteCardMessage": "Are you sure you want to delete <b>\"{{- card}}\"</b> card?",
        "cantDeleteCardMessage": "The card <b>\"{{- card}}\"</b> can't be deleted",
        "cantDeleteCardMessage_plural": "The selected cards can't be deleted",
        "cardStatusManagedInSpace": "Card status can be changed in its space",
        "dataPreview": "Preview of data",
        "underlyingData": "Underlying Data",
        "queryString": "Query code",
        "queryStringError": "To see the query code, you must select a datamodel and add at least one field",
        "dataCard": "Data card",
        "showFooter": "Show footer",
        "showRowLimitWarning": "Show row limit warning",
        "fixedHeader": "Keep header up",
        "fixedFilters": "Keep filters up",
        "fixedNavigation": "Keep navigation bar up",
        "cardType": "Card type",
        "cardsList": "Card list",
        "cardFilters": "Card filters",
        "interactiveFilters": "Interactive filters",
        "cardTheme": "Card theme",
        "richTextLabel": "Rich text",
        "crossTableLabel": "Cross table",
        "verticalTableLabel": "Vertical table",
        "chartLabel": "Chart",
        "kpiLabel": "KPI",
        "KpiLayoutConfig": "KPI Layout Settings",
        "KpiLayoutConfigHelp": "Select KPI alignment in each location (only available when a location has more than one KPI).",
        "gaugeLabel": "Gauge",
        "indicatorLabel": "Indicator",
        "indicatorsLabel": "Indicators",
        "indicatorConfig": "Indicator config.",
        "indicatorLocation": "Indicator location",
        "styleConfig": "Style config.",
        "cardChanges": "Changes have been made in the card configuration.",
        "cardChangesClick": "Click Preview to show updated results.",
        "cardDataNotSaved": "There are unsaved changes, if you confirm the changes will be lost",
        "changeCardType": {
            "confirm": "If you confirm by clicking Accept, the following will be done",
            "next": "and then you will be able to configure the visualization of the card from the beginning.",
            "subTitle": "Change the type of Card",
            "visualization": "Initialize all visualization settings.",
            "actions": "Clear the actions configured on the card.",
            "translations": "Clear the translations set on the fields."
        },
        "cardQueryMessagePreview": "You must add at least one field to \"COLUMN\" section to preview this query",
        "kpiMessagePreview": "You must add at least one metric to \"INDICATOR LOCATION\" section to preview this card",
        "kpiMessageSave": "You must add at least one metric to \"INDICATOR LOCATION\" section to save this card",
        "crossTableMessagePreview": "You must add at least one metric to \"DATA\" section to preview this card",
        "crossTableMessageSave": "You must add at least one metric to \"DATA\" section to save this card",
        "verticalTableMessagePreview": "You must add at least one field to \"COLUMN\" section in \"VERTICAL TABLE\" tab to preview this card",
        "verticalTableMessageSave": "You must add at least one field to \"COLUMN\" section in \"VERTICAL TABLE\" tab to save this card",
        "gaugeMessagePreview": "You must add at least one metric to \"METRIC\" section to preview this card",
        "gaugeMessageSave": "You must add at least one metric to \"METRIC\" section to save this card",
        "chartMessage": "To {{mode}} this card, add the following information",
        "breakByMessages": {
            "breakByUnavailableMessage": "Break by not available for the selected chart type",
            "breakByDisabledMessage": "Break by not available for more than one metric"
        },
        "chartConditionsMessages": {
            "typeMessage": "Chart type",
            "singleMessage": "One {{fieldType}} in {{section}} section",
            "multipleMessage": "At least one {{fieldType}} in {{section}} section",
            "twoOptionsMessage": "{{firstOption}} or {{secondOption}}"
        },
        "updateSuccess": "Card saved successfully",
        "crossTableStyles": {
            "crossTableCleanStyle": "Clean",
            "crossTableGoldStyle": "Gold",
            "crossTableBlueGreyStyle": "Blue Grey",
            "crossTableRedStyle": "Red"
        },
        "cardEditorLabel": "Card editor",
        "chartTypeLabel": "Chart type",
        "selectChartTypeLabel": "Select chart",
        "chartTypes": {
            "bar": "Vertical bars",
            "horizontalBar": "Horizontal bars",
            "line": "Line",
            "area": "Area",
            "pie": "Pie",
            "doughnut": "Doughnut",
            "stackedBar": "Stacked vertical bars",
            "stackedHorizontalBar": "Stacked horizontal bars",
            "stacked100Bar": "Stacked 100% vertical bars",
            "stacked100HorizontalBar": "Stacked 100% horizontal bars",
            "mixed": "Bars/Line mixed",
            "radar": "Radar",
            "funnel": "Funnel",
            "horizontalFunnel": "Horizontal funnel",
            "gauge": "Gauge",
            "scatter": "Scatter",
            "bubbles": "Bubbles",
            "bullet": "Bullet",
            "treemap": "Treemap",
            "heatmap": "Heatmap"
        },
        "chartFamilies": {
            "bars": "Bars",
            "line": "Line",
            "lines": "Line",
            "areas": "Area",
            "pies": "Pie",
            "mixed": "Mixed",
            "funnels": "Funnels"
        },
        "chartConfig": {
            "generalConfig": "General settings",
            "xAxeConfig": "X axis config",
            "yAxeConfig": "Y axis config",
            "yAxisConfig": "Y axes config",
            "metricConfig": "Metric config",
            "dimensionConfig": "Dimension config",
            "showAxisLabel": "Show label",
            "showAxisLine": "Show axis",
            "showTicksLabel": "Show ticks",
            "showLegendLabel": "Show legend",
            "showBreadcrumbLabel": "Show breadcrumb",
            "showTooltipLabel": "Show tooltip",
            "showDatalabelsLabel": "Show data labels",
            "showPoints": "Show points",
            "directionLabel": "Direction",
            "borderLineWidthLabel": "Line Width",
            "lineTensionLabel": "Line curvature",
            "gridLineStyleLabel": "Line style",
            "showValue": "Show value",
            "showPercentage": "Show percentage",
            "showSubtotal": "Show subtotal",
            "showTotal": "Show total",
            "funnelAlign": "Funnel align",
            "tickDirections": {
                "auto": "Auto",
                "horizontal": "Horizontal",
                "vertical": "Vertical",
                "slanting": "Slanting"
            },
            "gridLineStyles": {
                "solid": "Solid",
                "dashed": "Dashed",
                "dotted": "Dotted"
            },
            "lineTension": {
                "none": "None",
                "soft": "Soft",
                "moderated": "Moderated",
            },
            "pointStyles": {
                "circle": "Circle",
                "triangle": "Triangle",
                "rect": "Square",
                "rectRounded": "Rounded square",
                "rectRot": "Diamond",
                "cross": "Cross",
                "crossRot": "Equis",
                "line": "Line"
            },
            "palettes": {
                "mix1": "Palette 1",
                "mix2": "Palette 2",
                "mix3": "Palette 3",
                "mix4": "Palette 4"
            },
            "ticksStepValueLabel": "Step",
            "showGridLinesLabel": "Show grid lines",
            "fieldsConfig": "Field config",
            "axisConfig": "Axis config",
            "axeConfigButton": "Axis config",
            "axeConfigButton_plural": "Axes config",
            "order": {
                "desc": "Desc",
                "asc": "Asc",
                "default": "From source",
                "custom": "Custom"
            },
            "gaugeConfig": {
                "showLabel": "Show label",
                "labelPosition": "Position",
                "labelStyle": "Format",
                "gaugeWidth": "Bar width",
                "gaugeColor": "Bar color",
                "minValue": "Min value",
                "maxValue": "Max value",
                "showRoundEdges": "Show round edges",
                "showAxisLabel": "Show min and max",
                "axisLabelPosition": "Labels position",
                "gaugeType": "Gauge type",
                "gaugeForm": {
                    "circular": "Circular",
                    "semicircular": "Semicircular",
                    "custom":  "Custom"
                }
            },
            "bulletConfig": {
                "barHeight": "Bar height",
                "metricWidth": "Metric bar width",
                "metricHeight": "Metric bar height",
                "threshold": "Threshold",
                "thresholdSize": "Width",
                "thresholdWidth": "Height"
            },
            "treemapConfig": {
                "borderWidth": "Border width",
                "upperLabelBackgroundColor": "Header background color"
            },
            "radarShape": {
                "circle": "Circle",
                "polygon": "Polygon"
            },
            "complementsConfig": {
                "title": "Visual references",
                "line": "Line",
                "area": "Area",
                "dialog": {
                    "title": "Add visual reference"
                },
                "configDialog": {
                    "subtitle": "Visual reference",
                    "showInTooltip": "Show label and value in tooltip",
                    "showInLegend": "Show label in legend",
                    "labelRequired": "Label is required",
                    "nameRequired": "Name is required"
                }
            },
            "dataZoom": {
                "showToolbox": "Interactive zoom",
                "showDatazoom": "Zoom control in axis",
                "size": "Size",
                "color": "Color",
                "iconColor": "Icon color",
                "iconSize": "Icon size",
                "zoom": "Zoom",
                "restoreZoom": "Zoom back",
                "restore": "Restore"
            },
            "radarShapeLabel": "Radar shape",
            "radarSegments": "Number of divisions",
            "enableAnimation": "Enable animation",
            "graphicAnimation": "Graphic animation",
            "valueAnimation": "Value animation",
            "radius": "Radius",
            "radiusSize": "Radius size",
            "symbolSize": "Symbol size",
            "borderConfig": "Border configuration",
            "applyTo": "Apply to",
            "endLabel": "End",
            "baseAndEndLabel": "Base and end",
            "iconSize": "Page icon size",
            "iconColor": "Page icon color",
            "inactiveColor": "Inactive page icon color",
            "padAngle": "Separation angle",
            "areaLines": {
                "line1": "Line 1",
                "line2": "Line 2",
                "area": "Area"
            },
            "centerLabel": {
                "show": "Show center value",
                "showLabel": "Show text",
                "value": "Show value",
                "text": "Text"
            },
            "thicknessPortions": "Thickness of portions"
        },
        "gaugeTypes": {
            "bullet": "Bullet",
            "gauge" : "Gauge"
        },
        "gaugeConfig": {
            "visualizationTypeLabel": "Visualization type",
            "rangeConfigLabel": "Range Config",
            "minValueLabel": "Minimum value",
            "maxValueLabel": "Maximum value",
            "outerBarLabel": "Base Bar",
            "innerBarLabel": "Value Bar",
            "outerSectorLabel": "Base Sector",
            "innerSectorLabel": "Value Sector"
        },
        "conditionalStyles": {
            "enabledConditionalStyle": "Conditional style enabled",
            "disabledConditionalStyle": "Conditional style disabled",
            "disableConditionalStylesMessage": "Enable conditional style to apply different formatting to data elements based on their values",
            "addRule": "Add rule",
            "deleteAllRules": "Delete all rules",
            "field": "Field",
            "ruleExpression": "Rule expression",
            "stylePreview": "Style preview",
            "styleSample": "Sample of style",
            "clickToConfigure": "Click to configure",
            "fieldSelectedMandatory": "Select a field",
            "indicatorConfig": "Indicator config",
            "elementStyle": "Element style",
            "datalabelStyle": "Datalabel style"
        },
        "customCards": {
            "currentFile": "Current file",
            "uploadFileIdleMessage": "Add file (Drop or click here)",
            "uploadFileReplaceMessage": "Replace file (Drop or click here)",
            "uploadFileRejectedMessage": "File not valid",
            "uploadFileAcceptedMessage": "Drop here the file",
            "uploadFileTypeMessage": "Allowed file extension: ",
            "uploadFileSizeMessage": "Max file size: "
        },
        "customCardLabel": "Custom Card",
        "chooseCardLabel": "Select card",
        "SearchCardLabel": "Search card",
        "interactiveColumnsLabel": "Interactive columns",
        "columnsInUseLabel": "Used columns",
        "allowInteractiveFields": "Allow interactive fields selection",
        "indicatorFormat": "Indicator format",
        "numberFormat": "Number format",
        "internationalNumberFormat": "Comma for decimals, dot for thousands (1.000,00)",
        "englishNumberFormat": "Dot for decimals, comma for thousands (1,000.00)",
        "internationalNumberFormatValue": "1.000,00",
        "englishNumberFormatValue": "1,000.00",
        "dateFormat": "Date format",
        "dateLevel": "Date level",
        "timeZone": "Time zone",
        "showText": "Show text",
        "showIndicator": "Show indicator",
        "editIndicator": "Edit indicator",
        "showVisualElement": "Show vertical traffic light",
        "activeColor": "Active color",
        "inactiveColor": "Inactive color",
        "activeCriteria": "Active criteria",
        "showHeader": "Show header",
        "useStrippedRows": "Use stripped rows",
        "nullValues": "Null values treatment",
        "nullCustomValue": "Custom value",
        "nullOptionShowNothing": "Show nothing",
        "nullOptionShowNull": "Show 'NULL'",
        "nullOptionCustomValue": "Show custom value",
        "allowSort": "Allow interactive sorting",
        "allowMultiSort": "Allow multi sorting",
        "allowFilter": "Allow interactive filtering",
        "filterTypeInput": "Text input",
        "filterTypeSelect": "Selector",
        "filterTypeDateRange": "Date range",
        "filterTypeNumberRange": "Number range",
        "defaultSort": "Default sort",
        "columnTitle": "Column title",
        "totalType": "Total type",
        "totalValue": "Total value",
        "editColumn": "Edit column",
        "backgroundColor": "Background color",
        "showLeftGrid": "Show left grid",
        "showRightGrid": "Show right grid",
        "showMainHeader": "Show main header",
        "showLeftHeader": "Show left header",
        "showRightHeader": "Show right header",
        "showMainFooter": "Show main footer",
        "showLeftFooter": "Show left footer",
        "showRightFooter": "Show right footer",
        "showRowTotals": "Show row totals",
        "showColumnTotals": "Show column totals",
        "headerLabel": "Header",
        "footerLabel": "Footer",
        "groupValues": "Group values",
        "showSubTotals": "Show subtotals",
        "subtotalsLabel": "Subtotals",
        "subtotalsCollapse": "Collapse / expand subtotals",
        "usePredefinedStyle": "Use predefined style",
        "predefinedStyleLabel": "Predefined style",
        "useCustomStyle": "Use custom style",
        "useHeaderCustomStyle": "Header custom style",
        "useFooterCustomStyle": "Totals custom style",
        "useSubtotalsCustomStyle": "Subtotals custom style",
        "rowCustomStyle": "Row custom style",
        "configureRowCustomStyle": "Row conditional style",
        "configureCustomStyle": "Configure conditional style",
        "editHeaders": "Edit headers",
        "editFooters": "Edit footers",
        "editSubtotals": "Edit subtotals",
        "addCondition": "Add condition",
        "deleteAll": "Delete all",
        "chooseDataField": "Select field",
        "mergeCells": "Merge cells",
        "cardConfig": "Card config",
        "cardDataConfig": "Card data config",
        "isDataCard": "Is a data card?",
        "multiQueryCard": "Is a multi query card?",
        "addQueryLabel": "Add query",
        "deleteQueryLabel": "Delete query",
        "selectQueryLabel": "Select query",
        "queryLabel": "Query",
        "queryShortLabel": "Q",
        "affectedFieldLabel": "Affected field",
        "affectedFieldLabel_plural": "Affected fields",
        "joinedQueryLabel": "Joined query",
        "queriesLabel": "Queries",
        "availableFields": "Available fields",
        "selectedFields": "Selected fields",
        "configQueries": "Config queries",
        "configRelations": "Config relationships",
        "selectQueriesWithData": "Select queries with data",
        "headerDynamicRowHeight": "Header height config",
        "dynamicRowHeight": "Row height",
        "imageUrl": "Value as image url",
        "imageUrlTooltip": "Field values will be treated as URLs to render an image inside the cell. If none is available, a 'Not Found' image will be shown",
        "useDynamicRowHeight": "Use dynamic row height",
        "calculateRowHeightField": "Calculate row height using as reference:",
        "calculateRowHeightPrimaryField": "Primary field",
        "calculateRowHeightSecondaryField": "Secondary field",
        "showRowCount": "Show recovered/displayed rows",
        "showRowNumber": "Show row number",
        "verticalPosition": "Vertical position",
        "noDimensionsAvailable": "There are no dimensions available with current filter.",
        "noDimensionsAvailableNoFilter": "There are no dimensions available.",
        "noMetricsAvailable": "There are no metrics available with current filter.",
        "noMetricsAvailableNoFilter": "There are no metrics available.",
        "rowHeight": "Row height",
        "calculatedFields": "Calculated fields",
        "noCalculatedFieldsAvailable": "The aren't any calculated field available. Click the button to add one.",
        "newCalculatedFieldLabel": "New calculated field",
        "mapLabel": "Map",
        "mapStructure": {
            "baseMap": "Base map",
            "showBaseMap": "Show base map",
            "mapCenter": "Map center",
            "latitude": "Latitude",
            "longitude": "Longitude",
            "layers": "Layers",
            "warningAddLayer": "You must add a new query before adding a new layer",
            "showLayerSelector": "Show layer selector",
            "zoom": "Zoom",
            "initial": "Initial",
            "showZoomControls": "Show zoom controls",
            "scrollWheelZoom": "Scroll wheel zoom",
            "legend": "Legend"
        },
        "mapLayerDialog": {
            "layer": "Layer",
            "symbol": "Symbol",
            "symbolTooltip": {
                "p1": "Type of map in which symbols are placed at specific locations, sized and colored according to your data.",
                "p2": "Select between a wide range of symbols to better reflect your POIs (Points Of Interest)."
            },
            "choropleth": "Choropleth",
            "choroplethTooltip": "Type of thematic map in which areas are shaded or patterned in proportion to a metric value that represents an aggregate summary of a geographic characteristic within each area.",
            "heatmap": "Heatmap",
            "heatmapTooltip": "A heatmap is a data visualization technique that shows magnitude of a phenomenon as color in two dimensions. The variation in color may be by hue or intensity, giving obvious visual cues about how the phenomenon is clustered or varies over space."
        },
        "mapLayerSymbolStructure": {
            "fixed": "Fixed",
            "byValue": "By value",
            "buckets": "Buckets",
            "opacity": "Opacity",
            "symbolLocation": "Symbol location",
            "symbolSize": "Symbol size",
            "symbolColorAndIcon": "Symbol color and icon",
            "stroke": "Stroke",
            "tooltip": "Tooltip",
            "showTooltip": "Show tooltip on hover",
            "showPopup": "Show tooltip on click"
        },
        "mapLayerChoroplethStructure": {
            "fixed": "Fixed",
            "byValue": "By value",
            "buckets": "Buckets",
            "opacity": "Opacity",
            "mapCatalog": "Map catalog",
            "color": "Color",
            "stroke": "Stroke",
            "tooltip": "Tooltip",
            "showTooltip": "Show tooltip on hover",
            "showPopup": "Show tooltip on click"
        },
        "mapTileLayers": {
            "biuwer_default": {
                "label": "Default",
                "attribution": "&copy; <a href=\"https://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors"
            },
            "detailed_blue": {
                "label": "Detailed blue",
                "attribution": "&copy; <a href=\"https://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors, Tiles style by <a href=\"https://www.hotosm.org/\" target=\"_blank\">Humanitarian OpenStreetMap Team</a> hosted by <a href=\"https://openstreetmap.fr/\" target=\"_blank\">OpenStreetMap France</a>"
            },
            "topograph": {
                "label": "Topograph",
                "attribution": "Map data: &copy; <a href=\"https://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors, <a href=\"http://viewfinderpanoramas.org\">SRTM</a> | Map style: &copy; <a href=\"https://opentopomap.org\">OpenTopoMap</a> (<a href=\"https://creativecommons.org/licenses/by-sa/3.0/\">CC-BY-SA</a>)"
            },
            "elegant_grey": {
                "label": "Elegant grey",
                "attribution": "&copy; <a href=\"https://stadiamaps.com/\">Stadia Maps</a>, &copy; <a href=\"https://openmaptiles.org/\">OpenMapTiles</a> &copy; <a href=\"http://openstreetmap.org\">OpenStreetMap</a> contributors"
            },
            "elegant_dark": {
                "label": "Elegant dark",
                "attribution": "&copy; <a href=\"https://stadiamaps.com/\">Stadia Maps</a>, &copy; <a href=\"https://openmaptiles.org/\">OpenMapTiles</a> &copy; <a href=\"http://openstreetmap.org\">OpenStreetMap</a> contributors"
            },
            "black_white": {
                "label": "Black & white",
                "attribution": "&copy; <a href=\"https://www.maptiler.com//copyright\">MapTiler</a> contributors"
            },
            "grey_white": {
                "label": "Grey & white",
                "attribution": "&copy; <a href=\"https://www.maptiler.com//copyright\">MapTiler</a> contributors"
            },
            "terrain": {
                "label": "Terrain",
                "attribution": "&copy; <a href=\"https://www.maptiler.com//copyright\">MapTiler</a> contributors"
            },
            "classic": {
                "label": "Classic",
                "attribution": "Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012"
            },
            "satellite": {
                "label": "Satellite",
                "attribution": "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
            },
            "smooth_light": {
                "label": "Smooth light",
                "attribution": "&copy; <a href=\"https://www.maptiler.com//copyright\">MapTiler</a> contributors"
            },
            "smooth_dark": {
                "label": "Smooth dark",
                "attribution": "&copy; <a href=\"https://www.maptiler.com//copyright\">MapTiler</a> contributors"
            },
            "outdoors": {
                "label": "Outdoors",
                "attribution": "&copy; <a href=\"http://www.thunderforest.com/\">Thunderforest</a>, &copy; <a href=\"https://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors"
            },
            "neighbourhood": {
                "label": "Neighbourhood",
                "attribution": "&copy; <a href=\"http://www.thunderforest.com/\">Thunderforest</a>, &copy; <a href=\"https://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors"
            }
        },
        "mapLocationDefinedByOptions": {
            "lat_lng_fields": "Latitude + Longitude fields",
            "administrative_regions": "Administrative regions"
        },
        "dropFieldHere": "Drop {{name}} field here",
        "dropFieldHere_plural": "Drop {{name}} fields here",
        "mapConditionsMessages": {
            "layerMessage": "{{name}} layer",
            "singleMessage": "A {{fieldType}} field",
            "multipleMessage": "At least one {{fieldType}} field",
            "selectOptionMessage_male": "Select a {{option}}",
            "selectOptionMessage_female": "Select a {{option}}"
        },
        "mapPreviewMove": "A movement has been detected in the map preview. Do you want to update the center and initial zoom with the new values?",
        "mapCatalog": {
            "title": "Map Catalog",
            "matchingFieldMeaning": "Relationship meaning",
            "matchingField": "Relationship"
        },
        "mapCatalogMeaningOptions": {
            "countriesIso3": "Countries (ISO3)",
            "countriesName": "Countries (Local name)",
            "countriesEnName": "Countries (English name)",
            "ueCountriesNutsCode": "Europe - Countries (NUTS code)",
            "ueCountriesNutsName": "Europe - Countries (NUTS local name)",
            "spainNuts2Code": "Spain - Autonomous communities (NUTS-2 code)",
            "spainNuts2Name": "Spain - Autonomous communities (NUTS-2 local name)",
            "spainNuts3Code": "Spain - Provinces (NUTS-3 code)",
            "spainNuts3Name": "Spain - Provinces (NUTS-3 local name)",
            "spainCusecCode": "Spain - Censal section (CUSEC code)",
            "usStatesFipsCode": "USA - States (FIPS two-digit code)",
            "usStatesName": "USA - States (Local name)",
            "usCountiesFipsCode": "USA - Counties (FIPS five-digit code)",
            "usCountiesName": "USA - Counties (Local name)"
        },
        "deleteMapLayerMessage": "Are you sure you want to delete <b>\"{{- layer}}\"</b> layer?",
        "deleteQueryMessage": "Are you sure you want to delete the query <b>\"Q{{- query}}\"</b>?<br><br>If the query is deleted, all the configuration in the visualization using any field of the query will also be deleted.",
        "deleteQueryModelMessage": "Are you sure you want to change the datamodel of the query <b>\"Q{{- query}}\"</b>?<br><br>If the datamodel is changed, all the configuration in the query and visualization using any field of the query will also be deleted.",
        "cardQueryOperations": "Query operations",
        "cardQueryRelations": "Query relations",
        "cardQueryDataRelations": "Joined Data",
        "cardQueryCombinationsMainQuery": "Main query",
        "cardQueryCombinations": "Combined queries",
        "cardQueryDataCombinations": "Combined Data",
        "cardQueryRelationsInfo": "Relations are used to combine rows from two or more queries based on one or more related columns.",
        "cardQueryCombinationsInfo_p1": "Combinations are used to concat the results of two or more queries into a single result set.",
        "cardQueryCombinationsInfo_p2": "All queries must have the same number of columns and its data types must be the same for every column in the same position.",
        "calculatedFieldEditor": "Calculated field editor",
        "calculatedExpressionCorrect": "The expression is correct",
        "calculatedExpressionMultipleLocalDatasets": "Using multiple JOINED DATA",
        "calculatedExpressionEmpty": "The expression can not be empty",
        "calculatedExpressionError": "Expression error",
        "deleteQueryFieldMessage": "<b>\"{{- queryField}}\"</b> field is being used.<br><br>If deleted, all settings related to this field will be lost (relations, card calculated fields, viusalization fields, etc.)",
        "changeQueryFieldTypeMessage": "<b>\"{{- queryField}}\"</b> field is being used.<br><br>If the field type is changed, all information related to this field where the new type is incompatible will be lost.",
        "deleteVisualizationFieldMessage": "<b>\"{{- queryField}}\"</b> field is being used.<br><br>If deleted, all navigation actions related to this field will be lost",
    },
    "dataCenter": {
        "connectionCatalog": {
            "headerDesc": "Search, select and configure the <strong>data connection details</strong> to access your data",
            "noConnectionFoundWithFilters": "No connection found in catalog with selected filters",
            "noConnectionFound": "No connection found in catalog",
            "status": {
                "coming_soon": "Coming soon",
                "beta": "Beta"
            },
            "categories": {
                "allCategories": "All Categories",
                "files": "Files",
                "file_stores": "File Stores",
                "applications": "Applications",
                "events": "Events",
                "databases": "Databases",
                "cloud_databases": "Cloud databases",
                "cloud_files": "Cloud files"
            }
        },
        "connectionForm": {
            "connectionType": "Connection type",
            "connectionDetails": "Connection details",
            "selectConnectionType": "Select connection type from catalog",
            "sshTunnel": "SSH Tunnel",
            "databasesWarning": "In order to ensure access to your <strong>{{- connection}}</strong> database, you will need to make sure your server is configured to allow the following addesses to connect: <strong>{{- address}}</strong>",
            "timeZoneWarning1": "Your <strong>{{- connection}}</strong> database must have time zone support enabled. You can check how to do this process in the following link: ",
            "timeZoneWarning2": "If you can't enable time zone support in your <strong>{{- connection}}</strong> database, don't enable time zone support in <strong>Biuwer</strong>, otherwise you won't see any data from date fields.",
            "timeZoneWarningMongo": "Your <strong>{{- connection}}</strong> database must be version 3.6 or higher to support time zone conversion. Don't enable time zone support in <strong>Biuwer</strong> if your database is an older version.",
            "timeZoneDoc": "configuración de zona horaria",
            "mappingConfigWarning": "This connection requires a field mapping",
            "connectionConfig": "Connection configuration",
            "mappingConfig": "Mapping fields",
            "loadData": "Load Data",
            "reloadData": "Reload Data",
            "documentIdSearch": "Search document",
            "documentIdManual": "Manually enter Id",
            "headerParams": "Header parameters",
            "URLParams": "URL parameters",
            "bodyParams": "Body parameters",
            "authorization": {
                "basicAuth": "Basic Auth",
                "token": "Access Token",
                "addKeyTo": "Add key to"
            },
            "pagination": {
                "keyBased": "Key based",
                "incremental": "Incremental",
                "nextKey": "Next key",
                "initialValue": "Initial value",
                "incrementalValue": "Incremental value",
                "sizeKey": "Size key",
                "sizeValue": "Size value",
                "addSizeKeyTo": "Add size key to"
            }
        },
        "connectionMappingForm": {
            "dataPreviewTitle": "Data preview with mapping applied",
            "datasetAndMappingConfigTitle": "Dataset fields",
            "dataSampleTitle": "Obtained data structure",
            "previewData": "Preview data",
            "mappingPath": "Path",
            "noMappeableFields": "Select a dataset to update or add fields to the new dataset to be created.",
            "noPreviewData": "There is no data to preview. If you have configured fields for mapping, press the button \"Preview data\" to see data result."
        },
        "connectionsList": {
            "dataset" :"Dataset",
            "dataset_plural" :"Datasets",
            "datamodel": "Data Model",
            "datamodel_plural": "Data Models"
        },
        "reverseEngineering": {
            "datasetsList": "Datasets list",
            "originalDataType": "Original data type",
            "biuwerDataType": "Biuwer data type",
            "fieldType": "Field type",
            "fieldNotSupported": "Not Supported",
            "selectAll": "Select all",
            "unselectAll": "Unselect all",
            "reverseEngineeringError_p1": "Error obtaining datasets from the selected connection.",
            "reverseEngineeringError_p2": "Check if your connection is configured correctly."
        },
        "title": "Data Center",
        "homeLabel": "Home",
        "technologyLabel": "Technology",
        "technologyLabel_plural": "Technologies",
        "changeDataConnectionTitle": "Change connection",
        "changeDataConnectionWarning": "Are your sure you want to change <b>\"{{- prevConnection}}\"</b> connection for <b>\"{{- newConnection}}\"</b> connection?",
        "changeDataConnectionRelationsWarning": "All tables and relationships of this model will be deleted",
        "deleteApplicationDataConnectionMessage": "WARNING: This is an application-type connection. So, all associated data will also be deleted and data will no longer be updated automatically.",
        "deleteCloudFileDataConnectionMessage": "WARNING: This is a cloud file-type connection. So, all associated data will also be deleted and data will no longer be updated automatically."
    },
    "datasets": {
        "datasetLabel": "Dataset",
        "datasetLabel_plural": "Datasets",
        "numberOfDatasets": "# Datasets",
        "fieldLabel": "Field",
        "fieldLabel_plural": "Fields",
        "recordLabel": "Record",
        "recordLabel_plural": "Records",
        "dataPoliciesLabel": "Data Policies",
        "enabledLabel": "Enabled",
        "disabledLabel": "Disabled",
        "managedLabel": "Managed",
        "notManagedLabel": "Not Managed",
        "managedDatasetLabel": "Managed Dataset",
        "notManagedDatasetLabel": "Not Managed Dataset",
        "lastRefreshedLabel": "Last refreshed",
        "dateLevelLabel": "Date level",
        "managedDatasetInfo": "Data is stored in Biuwer Data Warehouse and queries are executed inside Biuwer.",
        "notManagedDatasetInfo": "Data is stored outside Biuwer and queries are executed in the remote database.",
        "fieldsListDescription": "Showing current dataset fields configuration.",
        "datasetPreviewInfo": "Showing 100 first records.",
        "customizeFieldValues": "Customize values of field",
        "in": "in",
        "personalizeValues": "Customize values",
        "whereAreTranslatedValues": "Where are the translated values?",
        "getTranslationsFromOtherField": "In other fields of the dataset",
        "internallyInThisSameField": "Internally in this same field",
        "originalValues": "Original values",
        "originalValuesInField": "Original values in field",
        "translatedValuesInField": "Translated values in field",
        "translationsValuesEnabled": "Translations of values enabled",
        "basicInformation": "Basic information",
        "isAView": "Is it a view?",
        "notConfigured": "Not configured",
        "multiLanguage": "Multi language",
        "fieldsWithTranslations": "Fields with translations",
        "isInUse": "It's in use?",
        "dataModelsUsage": "# Data Models",
        "cardsUsage": "# Cards",
        "filtersUsage": "# Filters",
        "performance": "Performance",
        "queryResultsCaching": "Query results caching",
        "acceleratedStorage": "Storage acceleration",
        "dataLastUpdated": "Data last updated on",
        "dataNextUpdate": "Data next update on",
        "formatLink1": "Link 1 format",
        "formatLink2": "Link 2 format",
        "dateLevels": {
            "year": "Year",
            "month": "Month",
            "week": "Week",
            "quarter": "Quarter",
            "yearQuarter": "Year-Quarter",
            "yearMonth": "Year-Month",
            "yearWeek": "Year-Week",
            "fullDate": "Full Date",
            "fullDateTime": "Full Date Time",
            "hourOfDay": "Hour of Day"
        },
        "processFile": "Process file",
        "importFromFile": "Import {{type}}",
        "importFileOptions": {
            "newDataset": "Create new Dataset",
            "updateDataset": "Update existing Dataset"
        },
        "fileImportMessages": {
            "fileNotUploaded": "No selected file",
            "fileUploaded": "Selected file",
            "fileNotProcessed": "No file processed",
            "processBeforeMapping": "Process file for mapping fields",
            "dataNotValid": "Error processing file data, check column \"{{field}}\" (data type and format)",
            "genericDataNotValid": "Error processing file data",
        },
        "processFileWarning": "File has changed, process the file to resume",
        "datasetSelectLabel": "Select Dataset",
        "importDatasetActionSelectLabel": "Select update action",
        "aggregationLabel": "Aggregation",
        "aggregations": {
            "sum": "Sum",
            "count": "Count",
            "countDistinct": "Count distinct",
            "avg": "Avg",
            "min": "Min",
            "max": "Max",
            "none": "None",
            "mode": "Mode",
            "median": "Median",
            "standardDeviation": "Standard deviation",
            "variance": "Variance"
        },
        "datasetType": "Dataset type",
        "dialogManaged": {
            "p1": "Data is stored in Biuwer Data Warehouse and queries are executed inside Biuwer.",
            "p2": "Use these Datasets to load data from external files or using a Biuwer Data Prep job."
        },
        "dialogNotManaged": {
            "p1": "Data is stored outside Biuwer and queries are executed in the remote database.",
            "p2": "Use these Datasets to query data from a table or view stored in your databases."
        },
        "dialogStandard": {
            "p1": "Standard fields represent physical columns in tables or fields in views.",
            "p2": "Use Standard fields to query data directly from them."
        },
        "dialogCalculated": {
            "p1": "Calculated fields let you define an expression or formula using other available Standard fields, constants, functions and operators, depending on the field data type.",
            "fnHelp": "The formulas of the calculated fields are processed in the source database and Biuwer has no control over the functions available or over the validation of the formulas"
        },
        "calculatedFieldFnHelp": {
            "p1": "The expressions of the calculated fields of Datasets are processed in the database associated to your connection.",
            "p2": "You can use the functions available in the source database engine. See the Help for more information.",
            "p3": "To check that the expression is correct, after saving the field, access the \"Records\" tab."
        },
        "fieldType": "Field type",
        "dataType": "Data type",
        "hidden": "Hidden",
        "calculated": "Calculated",
        "calculatedField": "Calculated field",
        "deleteDataset": "Delete dataset",
        "deleteDatasetMessage": "Are you sure you want to delete the dataset <b>\"{{- dataset}}\"</b>?",
        "cantDeleteDatasetMessage": "The dataset <b>\"{{- dataset}}\"</b> can't be deleted",
        "cantDeleteDatasetMessage_plural": "The selected datasets can't be deleted",
        "deallocateDatasetMessage": "Are you sure you want to deallocate the dataset <b>\"{{- dataset}}\"</b> from the model?",
        "cantDeallocateDatasetMessage": "The dataset <b>\"{{- dataset}}\"</b> can't be deallocated from the model",
        "deleteDataFieldMessage": "Are you sure you want to delete the field <b>\"{{- dataField}}\"</b>?",
        "cantDeleteDataFieldMessage": "The field <b>\"{{- dataField}}\"</b> can't be deleted",
        "cantDeleteDataFieldMessage_plural": "The selected fields can't be deleted",
        "createDataset": "Create dataset",
        "addField": "Add field",
        "addCalculatedField": "Add calculated field",
        "editCalculatedField": "Edit calculated field",
        "availableFields": "Available fields",
        "functionType": "Function type",
        "functions": "Functions",
        "expression": "Expression",
        "container": "Container",
        "container_plural": "Containers",
        "selectContainers": "Select containers to find data sets:",
        "selectContainersShort": "Select containers:",
        "datasetsNotFound": "Datasets not found",
        "datasetsList": "Datasets list",
        "datasetField": "Dataset field",
        "importLabel": "Import dataset",
        "importData": "Import data",
        "newDataset": "New dataset",
        "addFieldLabel": "Add Field",
        "notAvailableFieldsLabel": "There's no available fields",
        "importFileError": "Error processing the file",
        "fieldAlreadyExists": "Name field already exists in Dataset",
        "importOptions": {
            "delimiter": "Delimiter",
            "quotes": "Quotes",
            "rowHeaders": "First rows contain column names",
            "skipRows": "Skip top rows",
            "dateFormat": "Dates format",
            "customFormat": "Custom format",
            "numeralFormat": "Numbers format",
            "booleanFormat": "Booleans format",
            "thousandsLabel": "Thousands",
            "decimalsLabel": "Decimals",
            "dataWarningLabel": "Check the data type of the following columns, because their content don't match the indicated format",
            "dateFormatMaskInfo": "The values shown in the fields configuration for date-type fields follow this format",
            "dateFormatLangInfo": "This setting will kept in mind the language currently configured in Biuwer",
            "booleanFormatInfo": "Fields you select as type \"Boolean\" only admit two possible values, \"true\" and \"false\". Indicate here what value of your data means \"true\" and what value of your data means \"false\"",
            "numberFormatInfo": "Fields you select as type \"Number\" can include thousands separator and decimal separator. Indicate here what symbol, either a point or a comma, represent each of them in your data.",
            "delimiterOptions": {
                "comma": "Comma",
                "semicolon": "Semicolon",
                "colon": "Colon",
                "space": "Space",
                "tabulation": "Tabulation",
                "custom": "Custom"
            },
            "quotesOptions": {
                "simple": "Simple",
                "double": "Double"
            },
            "importOptionsLabel": "Import options",
            "fieldsConfigLabel": "Fields configuration",
            "importTypeLabel": "Import type",
            "updateActions": {
                "appendLabel": "Add Data",
                "appendInfo": "Attach new imported data with previously imported one",
                "overwriteLabel": "Replace Data",
                "overwriteInfo": "Delete current data and saves the new data"
            }
        },
        "viewCodeDialog": {
            "queryCode": "Query code",
            "schemaValidation": "Validate schema",
            "schemaIsCorrect": "The schema is correct.",
            "schemaIsNotCorrect": "The schema is either incorrect or there are problems with some fields.",
            "schemaHasChanged": "The schema has changed. Click on validate schema button to evaluate it again.",
            "errorHeader": "The database server responded with the following ",
            "errorLabel": "error",
            "clickSynchronize": "The following changes has been detected in the Dataset fields:",
            "fieldsToBe": "Fields to be",
            "addedLabel": "added",
            "deletedLabel": "deleted",
            "noChangesInSchema": "There are no changes in the schema. Click on Synchronize to save changes to fields definition.",
            "synchronizeLabel": "Synchronize",
            "itsAView": "It's a view?",
            "fieldIssues": "Some of the fields marked to delete are in use:",
            "schemaCorrectButIssues": "The schema is correct but there are issues with some fields.",
            "saveOK": "Click on Synchronize to save changes.",
            "saveKO": "Check the query or the fields marked to delete."
        }
    },
    "datamodels": {
        "datamodelLabel": "Datamodel",
        "datamodelLabel_plural": "Models",
        "datamodelType": "Datamodel type",
        "datasetLabel": "Dataset",
        "datasetLabel_plural": "Datasets",
        "datasetSelectLabel": "Select Dataset",
        "relationLabel": "Relationship",
        "relationLabel_plural": "Relationships",
        "numberOfRelations": "# Relationships",
        "combinationLabel": "Combination",
        "combinationLabel_plural": "Combinations",
        "createModel": "Create Datamodel",
        "editModel": "Edit datamodel",
        "deleteModel": "Delete datamodel",
        "chooseModel": "Select datamodel",
        "datamodelsList": "Datamodel list",
        "datasetAddLabel": "Add dataset",
        "datasetDeleteLabel": "Delete dataset",
        "datasetsAvailablesLabel": "Available datasets",
        "datasetsSelectedLabel": "Selected datasets",
        "relationAddLabel": "Add relationship",
        "relationEditLabel": "Edit relationship",
        "relationDeleteLabel": "Delete relationship",
        "datasetsWarningMessage": "You need to have at least 2 datasets in the datamodel to add a relationship",
        "deleteRelationMessage": "Are you sure you want to delete the selected relationship?",
        "deleteDatasetMessage": "Are you sure you want to delete the dataset <b>\"{{- dataset}}\"</b>?",
        "deleteDatamodelMessage": "Are you sure you want to delete the datamodel <b>\"{{- datamodel}}\"</b>?",
        "cantDeleteDatamodelMessage": "The datamodel <b>\"{{- datamodel}}\"</b> can't be deleted",
        "cantDeleteDatamodelMessage_plural": "The selected datamodels can't be deleted",
        "dimensionsLabel": "Dimensions",
        "metricsLabel": "Metrics",
        "selectParentDatamodel": "Select parent datamodel",
        "showingDatasets": "Showing current Datasets in Datamodel configuration",
        "showingRelations": "Showing Relations between Datasets in Datamodel configuration",
        "changeDataConnectionWarning": "If the connection is modified, Datasets and Relations will be deleted from Datamodel",
        "mongoDBWarning": "MongoDB is not designed to use relationships between datasets as a SQL database. Try to use only one dataset. Build relationships at your own risk, with few datasets in a star model.",
        "dialogManaged": {
            "p2": "Use these Data models to include managed datasets."
        },
        "dialogNotManaged": {
            "p2": "Use these Data models to include not managed datasets."
        },
        "multiConnection": {
            "title": "Dynamic Connection",
            "title_plural": "Dynamic Connections",
            "enabled": "Dynamic Connections enabled",
            "disabled": "Dynamic Connections disabled",
            "multiConnectionDisabledMessage": "Dynamics connections config is disabled",
            "noConnectionConfigured": "There is no dynamic connection configured",
            "deleteConfigMessage": "Are you sure you want to delete the dynamic connection <b>\"{{- name}}\"</b>?",
            "addMultiConnectionConfig": "Add dynamic connection",
            "addMultiConnectionUsers": "Add users to dynamic connection config",
            "addMultiConnectionGroups": "Add groups to dynamic connection config"
        }
    },
    "dataConnections": {
        "connectionLabel": "Connection",
        "connectionLabel_plural": "Connections",
        "connectionSelectLabel": "Select Connection",
        "connectionDetails": "Connection details",
        "createConnection": "Create connection",
        "deleteConnection": "Delete connection",
        "editConnection": "Edit connection",
        "testConnection": "Test connection",
        "pauseSync": "Pause synchronization",
        "resumeSync": "Resume synchronization",
        "host": "Host",
        "db": "Database",
        "dbId": "Database Id",
        "authSource": "Authentication DB",
        "user": "User",
        "password": "Password",
        "port": "Port",
        "encrypt": "Encrypt",
        "schema": "Schema",
        "timeZone": "Time zone",
        "protocol": "Protocol",
        "authType": "Authentication type",
        "privateKey": "Private key",
        "passphrase": "Passphrase",
        "serviceAccount": "Service account",
        "privateKeySecurity": "The private key will be stored encrypted and only will be used when the connection requires it",
        "passphraseRequired": "The private key is not protected with a passphrase",
        "passphraseSecurity": "The passphrase will be stored encrypted and only will be used when the connection requires it",
        "connectionRequest": "Testing connection",
        "connectionSuccess": "Connection successful",
        "connectionError": "Connection error",
        "chooseConnectionType": "Select connection type",
        "deleteDataConnectionMessage": "Are you sure you want to delete the connection <b>\"{{- dataConnection}}\"</b>?",
        "cantDeleteDataConnectionMessage": "The connection <b>\"{{- dataConnection}}\"</b> can't be deleted",
        "cantDeleteDataConnectionMessage_plural": "The selected connections can't be deleted",
        "reverseEngineering": "Reverse Engineering",
        "reverseEngineeringError": "Error recovering datasets. Check your connection settings.",
        "project": "Project",
        "projectId": "Project Id",
        "keyFilename": "Path",
        "ssl": "SSL",
        "rejectUnauthorized": "Reject unauthorized connections",
        "apiKey": "API key",
        "clientId": "Client ID",
        "clientSecret": "Client Secret",
        "tenantId": "Tenant",
        "env": "Env",
        "hasCluster": "Is a cluster configuration?",
        "authentication": "Authentication",
        "authKey": "Authentication key",
        "authValue": "Authentication value",
        "method": "Method",
        "url": "URL",
        "hasPagination": "Has pagination?",
        "pagination": "Pagination",
        "headerParams": "Header parameters",
        "urlParams": "URL parameters",
        "bodyParams": "Body parameters",
        "account": "Account",
        "warehouse": "Warehouse",
        "role": "Role"
    },
    "dataPrep": {
        "agents": {
            "title": "Agent",
            "title_plural": "Agents",
            "lastConnected": "Last connected",
            "privateLabel": "Private",
            "privateLabelDesc": "Agent is managed by the organization and only this organization can use this agent. Requires to download agent executable and run it in a private computer.",
            "sharedLabel": "Shared",
            "sharedLabelDesc": "Agent is managed by Biuwer, it’s already configured and ready to use.",
            "editAgentLabel": "Edit Agent",
            "addAgentLabel": "Add Agent",
            "chooseAgentLabel": "Choose Agent",
            "deleteAgentMessage": "Are you sure you want to delete the agent <b>\"{{- agent}}\"</b>?",
            "sharedAgentDeleteMessage": "Are you sure you want to unassign the agent <b>\"{{- agent}}\"</b> from your organization?",
            "deleteAgentInfoMessage": "Every job that used this agent will be unavailable until a valid agent is assigned to them.",
            "noAgentsAvailable": "No agents available"
        },
        "executions": {
            "executionLabel": "Execution",
            "executionLabel_plural": "Executions",
            "job": "Job",
            "status": "Status",
            "startDate": "Start Date",
            "endDate": "End Date",
            "executionTime": "Execution Time",
            "agent": "Agent",
            "executionStatusList": {
                "scheduled": "Scheduled",
                "pending": "Pending",
                "queued": "Queued",
                "running": "Running",
                "completed": "Completed",
                "canceled": "Canceled",
                "error": "Error"
            },
            "seeExecutionDetails": "See execution details",
            "seeJobDetails": "See job details",
            "cancelExecution": "Cancel execution",
            "cancelExecutionMessage":  "Are you sure you want to cancel <b>\"{{- execution}}\"</b> execution?",
            "deleteExecution": "Delete execution",
            "jobExecutionDetail": "Job Execution Detail",
            "jobsAndSteps": "Jobs and Steps",
            "noExecutions": "There are no executions in the last 30 days.",
            "noExecutionsInJob": "This job doesn't have any execution in the last 30 days.",
            "lastExecution": "Last update",
            "nextExecution": "Next update",
            "executionRunning": "Updating"
        },
        "jobs": {
            "jobLabel": "Job",
            "jobLabel_plural": "Jobs",
            "executeJob": "Execute Job",
            "createJobLabel": "Create Job",
            "addJobLabel": "Add Job",
            "updateJobLabel": "Update Job",
            "deleteJobMessage": "Are you sure you want to delete the job <b>\"{{- job}}\"</b>?",
            "deleteJobInfoMessage": "All steps, executions and schedules belonging to this job will be deleted as well.",
            "timeoutLabel": "Timeout",
            "timeoutInfo": "Max execution timeout in seconds. Type 0 to have no limit.",
            "executionSuccess": "Job executed successfully.",
            "isContainer": "Container",
            "containerInfo": "A container job can only have jobs. A non container job can only have steps. To change this attribute, the job can't contain any job or step.",
            "selectJobLabel": "Select job",
            "errorNotification": "Error notifications",
            "recipients": "Recipients",
            "chooseRecipients": "Choose recipients"
        },
        "steps": {
            "stepLabel": "Step",
            "stepLabel_plural": "Steps",
            "addStepLabel": "Create Step",
            "editStepLabel": "Edit Step",
            "deleteStepMessage": "Are you sure you want to delete the step <b>\"{{- step}}\"</b>?",
            "inputsLabel": "Inputs",
            "outputsLabel": "Outputs",
            "noStepsInJob": "This job doesn't have any step. Use the add button to create one."
        },
        "stepTypes": {
            "stepTypeLabel": "Step type",
            "stepTypeLabel_plural": "Step types",
            "chooseStepTypeLabel": "Choose step type"
        },
        "schedules": {
            "scheduleLabel": "Schedule",
            "scheduleLabel_plural": "Schedules",
            "oneTime": "One time",
            "recurrent": "Recurrent",
            "createScheduleLabel": "Create Schedule",
            "editScheduleLabel": "Edit Schedule",
            "deleteScheduleMessage": "Are you sure you want to delete the schedule <b>\"{{- schedule}}\"</b>?",
            "scheduleNotValid": "Schedule not valid",
            "whenLabel": "When",
            "noOptionsLabel": "No options available",
            "monthsLabel": "Months",
            "weekDaysLabel": "Week days",
            "monthDaysLabel": "Month days",
            "jobExecution": "Job will be executed",
            "onLabel": "on",
            "atLabel": "at",
            "onceLabel": "Once",
            "everyLabel": "Every",
            "noSchedulesInJob": "This job doesn't have any schedule. Use the add button to create one.",
            "everyDayLabel": "Every day"
        }
    },
    "styles": {
        "styleLabel": "Style",
        "boxStyleLabel": "Box style",
        "border": {
            "borderLabel": "Border",
            "borderLabel_plural": "Borders",
            "borderStyleSolid": "Solid",
            "borderStyleDotted": "Dotted",
            "borderStyleDashed": "Dashed",
            "borderStyleDouble": "Double"
        },
        "padding": {
            "paddingLabel": "Padding"
        },
        "transparencyLabel": "Transparency",
        "borderStyles": {
            "none": "None",
            "hidden": "Hidden",
            "dotted": "Dotted",
            "dashed": "Dashed",
            "solid": "Solid",
            "double": "Doble",
            "groove": "Groove",
            "ridge": "Ridge",
            "inset": "Inset",
            "outset": "Outset"
        },
        "shadow": {
            "shadowLabel": "Shadow"
        }
    },
    "sorting": {
        "configCustomOrder": "Configure custom sort",
        "availableValues": "Available values",
        "remainingValuesOrder": "Remaining values order",
        "selectedValues": "Selected values",
        "showSelectedValues": "Show selected values"
    },
    "navigation": {
        "title": "Navigation",
        "createLink": "Create link",
        "editLink": "Edit link",
        "parent": "Parent",
        "noNameLink": "Link without name",
        "withoutLink": "Without link",
        "withLink": "With link",
        "behaviour": "Behaviour"
    },
    "actions": {
        "actionLabel": "Action",
        "actionsLabel": "Actions",
        "navigationMode": "Navigation mode",
        "backNavigationInfo": "Return to navigation origin",
        "closeNavigationInfo": "Close navigation mode while staying in the current location",
        "actionName": "Action name",
        "actionType": "Action type",
        "destinationCardSelect": "Select destination card",
        "destinationPageSelect": "Select destination page",
        "navigationOrigin": "Navigation origin",
        "filteringOrigin": "Filtering origin",
        "originFields": "Origin fields",
        "destinationFields": "Destination fields",
        "destinationFiltersMapping": "Destination filters mapping",
        "destinationNoFilters": "There are no filters configured in the selected destination",
        "urlLink": "Link url",
        "actionsEditor": "Action editor",
        "cardField": "Card field",
        "cardField_plural": "Card fields",
        "systemVariable": "System variable",
        "systemVariable_plural": "System variables",
        "cardFilter": "Card filter",
        "pageFilter": "Page filter",
        "mapFieldBy": "Map by:",
        "selectPageFilter": "Select filter in desired pages",
        "destinationFilterConfig": "Destination filter mapping",
        "mapConfig": "Configure the filter mapping",
        "availableFields": "Available fields",
        "availableSystemVariables": "Available System Variables",
        "urlHelp": "You can use system variables and card fields to customize a dynamic url.",
        "includeThemThisWay": "Include them this way",
        "useAlternativeText": "Use alternative text",
        "alternativeText": "Alternative text",
        "applyOn": "Apply on",
        "popupSize": "Popup size",
        "types": {
            "card_navigation": "Navigate to a card",
            "page_navigation": "Navigate to a page",
            "url_link": "Navigate to an external address",
            "card_popup": "Open card in a popup",
            "interactive_filter": "Interactive filter"
        },
        "showAlwaysActionMenu": "Always show the Actions menu"
    },
    "home": {
        "title": "Home",
        "noDataMessage": "No active {{value}} found",
        "noDataMessage_female": "No active {{value}} found",
        "gettingStarted": {
            "title": "Getting started",
            "mainConcepts": "It’s important to get used to the <0>main concepts regarding Biuwer and the navigation basics</0>.",
            "sampleSpace": "We’ve prepared for your Organization a <0>sample Shared Space</0>, called <0>“Film Rentals”</0> to help you and your team get started with Biuwer. It includes one Page with 7 Cards.",
            "sampleArticle": "Please check this article in <0>Biuwer Docs</0> for more details:",
            "glossaryLink": "Glossary",
            "homePageLink": "Home page and navigation",
            "sampleArticleLink": "The Film Rentals space"
        },
        "needHelp": {
            "title": "Need help?",
            "docs": "<0>Biuwer Docs</0> is our knowledge base where you can find detailed explanation on how to use Biuwer.",
            "docsButton": "Access Biuwer Docs",
            "help": "<0>Biuwer Help Center</0> is accessible through our online chat, to get fast support from Biuwer team.",
            "helpButton": "Talk with support"
        }
    },
    "errors": {
        "unknownError": "An error has occurred. Contact support or your primary contact at Biuwer",
        "errorMessage": "Error message",
        "5": "Requested email does not exists",
        "6": "Invalid password",
        "7": "User information provided is not enough. Contact your administrator",
        "8": "Error generating token. Contact your administrator",
        "120": "User is not active. Contact your administrator",
        "121": "Email field is mandatory",
        "122": "Access request has expired or does not exist. Request a new password please",
        "123": "You must provide user ID and email to change the password",
        "124": "Passwords don't match",
        "125": "User is not logged in",
        "126": "Insert a new password please",
        "127": "User not found",
        "128": "Current password is invalid",
        "130": "Processing error. Contact your administrator",
        "11000": "{{varName}} is in use",
        "10000007": "User organization is not active or does not exist",
        "queryDataError": "Error querying data",
        "USER_EXISTS": "User already registered. Contact your administrator",
        "SIGNUP_TOKEN_EXPIRED_NOT_EXIST": "Expired or invalid token",
        "DATASET_FIELD_DEPENDENCIES": "One of the unselected fields from dataset is being used",
        "EMAIL_DOMAIN_NOT_VALID": "Email domain not valid",
        "REQUEST_TOO_RECENT": "This request has been made recently. If you want to repeat it, try in a few minutes",
        "ORDER_ALREADY_PAID": "Order has been already paid",
        "UNABLE_RECOVER_ORDER": "Order not found",
        "ErrorBoundary": {
            "title": "Ooops! Something has gone wrong",
            "message": "Please try again. If you need help, do not hesitate to contact us through the online chat."
        }
    },
    "warnings": {
        "-10000004": "Due to an internal problem, only partial data has been obtained",
        "rowLimitReached": "Row limit reached",
        "noContent": {
            "message": "Currently you don't have access to any {{object}}",
            "message_female": "Currently you don't have access to any {{object}}",
            "createLabel": "Create one",
            "createLabel_female": "Create one",
            "createOrRequestLabel": "Create one or request access",
            "createOrRequestLabel_female": "Create one o request access",
            "requestLabel": "Request access"
        },
        "noQueryExecuted": "No data has been obtained yet for the selected query.",
        "noDataObtainedForPage": "No data has been obtained yet for page",
        "noDataObtainedForCard": "No data has been obtained yet for card",
        "clickPreviewToExecute": "Click Preview to execute query.",
        "clickRunToExecute": "Click Run to execute query.",
        "clickRunToExecutePlural": "Click Run to execute queries."
    },
    "common": {
        "errorMessageLabel": "Error message",
        "enabled": "Enabled",
        "enabled_female": "Enabled",
        "enabled_plural": "Enabled",
        "enabled_female_plural": "Enabled",
        "disabled": "Disabled",
        "disabled_female": "Disabled",
        "disabled_plural": "Disabled",
        "disabled_female_plural": "Disabled",
        "valid": "Valid",
        "valid_female": "Valid",
        "valid_plural": "Valid",
        "valid_female_plural": "Valid",
        "invalid": "Invalid",
        "invalid_female": "Invalid",
        "invalid_plural": "Invalid",
        "invalid_female_plural": "Invalid",
        "config": "Config",
        "generalLabel": "General",
        "securityLabel": "Security",
        "orderLabel": "Order",
        "seeLabel": "See",
        "seeDetailLabel": "See detail",
        "addLabel": "Add",
        "createLabel": "Create",
        "createObject": "Create {{object}}",
        "created": "Created",
        "created_female": "Created",
        "createdOn": "Created on",
        "createdBy": "Created by",
        "updatedOn": "Updated on",
        "updatedBy": "Updated by",
        "cloneLabel": "Clone",
        "editLabel": "Edit",
        "editObject": "Edit {{object}}",
        "deleteLabel": "Delete",
        "activateLabel": "Activate",
        "deactivateLabel": "Deactivate",
        "previewLabel": "Preview",
        "visualizationLabel": "Visualization",
        "saveLabel": "Save",
        "saveChangesLabel": "Save changes",
        "pendingChangesLabel": "The are changes pending to be saved",
        "acceptLabel": "Accept",
        "cancelLabel": "Cancel",
        "chooseLabel": "Select",
        "uploadLabel": "Upload",
        "downloadLabel": "Download",
        "applyLabel": "Apply",
        "applyThisLabel": "Apply {{object}}",
        "configureLabel": "Config",
        "configurationLabel": "Configuration",
        "generalConfigLabel": "General settings",
        "statusLabel": "Status",
        "statusLabel_plural": "Statuses",
        "createdLabel": "Created on {{date}} by {{name}}",
        "updatedLabel": "Last update on {{date}} by {{name}}",
        "sendWelcomeEmailLabel": "Send welcome email",
        "pendingLabel": "Pending",
        "activeLabel": "Active",
        "activeLabel_plural": "Actives",
        "activeLabel_female": "Active",
        "activeLabel_female_plural": "Actives",
        "inactiveLabel": "Inactive",
        "inactiveLabel_plural": "Inactives",
        "inactiveLabel_female": "Inactive",
        "inactiveLabel_female_plural": "Inactives",
        "openedLabel": "Opened",
        "openedLabel_plural": "Opened",
        "openedLabel_female": "Opened",
        "openedLabel_female_plural": "Opened",
        "closedLabel": "Closed",
        "closedLabel_plural": "Closed",
        "closedLabel_female": "Closed",
        "closedLabel_female_plural": "Closed",
        "deletedLabel": "Deleted",
        "loadingLabel": "Loading",
        "noDataFilterMessage": "No {{name}} available with current filter.",
        "noDataMessage": "No {{name}} available",
        "noDataShortMessage": "There are no {{name}}",
        "noDataForAppliedFiltersMessage": "No data for selection",
        "noFiltersAppliedMessage": "Configure filters and apply",
        "noSelectedMessage": "No {{namePlural}} selected",
        "noSelectedMessage_female": "No {{namePlural}} selected",
        "allSelectedMessage": "All {{name}} had been selected",
        "allSelectedMessage_female": "All {{name}} had been selected",
        "selectMessage": "Select a {{name}}",
        "selectMessage_female": "Select a {{name}}",
        "selectActionLabel": "Select",
        "selectLabel": "Select",
        "selectedLabel": "Selected",
        "selectedLabel_female": "Selected",
        "selectedLabel_plural": "Selected",
        "selectedLabel_female_plural": "Selected",
        "selectWeekLabel": "Select a week",
        "dateLabel": "Date",
        "createDateLabel": "Creation date",
        "updatedDateLabel": "Updated date",
        "uploadDateLabel": "Upload date",
        "sizeLabel": "Size",
        "chooseSizeLabel": "Choose size",
        "veryBigLabel": "Very big",
        "bigLabel": "Big",
        "mediumLabel": "Medium",
        "smallLabel": "Small",
        "weekLabel": "Week",
        "numberLabel": "Nº",
        "dotLabel": "Dot",
        "commaLabel": "Comma",
        "bothLabel": "Both",
        "locationLabel": "Location",
        "locationLabel_plural": "Locations",
        "provinceLabel": "Province",
        "noProvinceLabel": "No province",
        "provinceSelectActionLabel": "Select a Province",
        "townLabel": "Town",
        "noTownLabel": "No town",
        "unitSelectLabel": "Select a unit",
        "unitLabel": "Unit",
        "unitLabel_plural": "Units",
        "requiredFieldLabel": "Required field",
        "requiredNumericField": "Field must be numeric",
        "allLabel": "All",
        "allLabel_female": "All",
        "noneLabel": "None",
        "noneLabel_female": "None",
        "quantityLabel": "Quantity",
        "quantityLabel_plural": "Quantities",
        "actionLabel": "Action",
        "actionLabel_plural": "Actions",
        "commentLabel": "Comment",
        "commentLabel_plural": "Comments",
        "noLabel": "No",
        "totalLabel": "Total",
        "totalLabel_plural": "Totals",
        "pctLabelText_short": "Pct",
        "pctLabelText_large": "Percentage",
        "pctLabelSymbol": "%",
        "overviewLabel": "Overview",
        "goBackLabel": "Go back",
        "newLabel": "New",
        "newLabel_female": "New",
        "openLabel": "Open",
        "closeLabel": "Close",
        "archiveLabel": "Archive",
        "idLabel": "Id",
        "cleanLabel": "Clean",
        "andLabel": "and",
        "orLabel": "or",
        "inLabel": "in",
        "recordsObtained": "Obtained records",
        "recordsShown": "Shown",
        "showingLabel": "Showing",
        "showLabel": "Show",
        "dataLabel": "Data",
        "searchLabel": "Search",
        "deleteRow": "Delete row",
        "deleteRowDeleteMessage": "Are you sure you want to delete the selected row?",
        "updateLabel": "Update",
        "duplicateLabel": "Duplicate",
        "codeLabel": "Code",
        "yes": "Yes",
        "no": "No",
        "hideLabel": "Hide",
        "stickLabel": "Stick",
        "nameLabel": "Name",
        "descLabel": "Description",
        "typeLabel": "Type",
        "emailLabel": "Email",
        "calculate": "Calculate",
        "recalculate": "Recalculate",
        "character": "Character",
        "character_plural": "Characters",
        "invalidDate": "Invalid date",
        "itAlreadyExists": "{{objectVarName}} already exists",
        "itAlreadyExists_female": "{{objectVarName}} already exists",
        "informationLabel": "Information",
        "integrationLabel": "Integration",
        "statisticLabel": "Statistic",
        "statisticLabel_plural": "Statistics",
        "mainInformationLabel": "Main information",
        "generalInformationLabel": "General information",
        "fieldMustBeNumber": "Field must be numeric",
        "noResult": "No result",
        "noResult_plural": "No results",
        "phoneLabel": "Phone",
        "addressLabel": "Address",
        "preferencesLabel": "Preferences",
        "generateLabel": "Generate",
        "selectedXVariables": "You have selected {{numberVariables}} {{variableLabel}}",
        "stopApplying": "Stop applying",
        "listLabel": "List",
        "listingLabel": "List",
        "internalLabel": "Internal",
        "externalLabel": "External",
        "linkLabel": "Link",
        "filenameLabel": "File name",
        "widthLabel": "Width",
        "heightLabel": "Height",
        "valueLabel": "Value",
        "valueLabel_plural": "Values",
        "allowedValueLabel": "Allowed value",
        "allowedValueLabel_plural": "Allowed values",
        "defaultValueLabel": "Default value",
        "menuLabel": "Menu",
        "menuLabel_plural": "Menus",
        "shownLabel": "Shown",
        "hiddenLabel": "Hidden",
        "deactivatedLink": "Deactivated link",
        "menuTreeLabel": "Menu tree",
        "descriptionLabel": "Description",
        "backLabel": "Back",
        "rowLabel": "Row",
        "rowsLabel": "Rows",
        "metricLabel": "Metric",
        "metricLabel_plural": "Metrics",
        "dimensionLabel": "Dimension",
        "y-axisLabel": "Y axis",
        "x-axisLabel": "X axis",
        "associated-axis": "Associated axis",
        "colorBy": "Color by",
        "dimensionLabel_plural": "Dimensions",
        "breakByLabel": "Break by",
        "insideLabel": "Inside",
        "outsideLabel": "Outside",
        "leftLabel": "Left",
        "rightLabel": "Right",
        "centerLabel": "Center",
        "topLabel": "Top",
        "bottomLabel": "Bottom",
        "topLeftLabel": "Top left",
        "topCenterLabel": "Top center",
        "bottomLeftLabel": "Bottom left",
        "bottomCenterLabel": "Bottom center",
        "topRightLabel": "Top right",
        "bottomRightLabel": "Bottom right",
        "topAndBottom": "Top and bottom",
        "inFrontLabel": "In front",
        "behindLabel": "Behind",
        "formatLabel": "Format",
        "minMaxFormatLabel": "Min/Max format",
        "slicesLimit": "Limit displayed values",
        "slicesNumber": "Number of values",
        "showSliceLimit": "Show remainder in accumulated",
        "slicesDefaultName": "Others",
        "labelLabel": "Label",
        "minLabel": "Min",
        "minLabel_female": "Min",
        "maxLabel": "Max",
        "maxLabel_female": "Max",
        "columnLabel": "Column",
        "columnsLabel": "Columns",
        "separatorLabel": "Separator",
        "styleLabel": "Style",
        "conditionalStyle": "Conditional style",
        "rowConditionalStyle": "Row conditional style",
        "chartConditionalStyle": "Chart conditional style",
        "of": "of",
        "positionLabel": "Position",
        "legendLabel": "Legend",
        "runLabel": "Run",
        "titleLabel": "Title",
        "congratulationsLabel": "Congratulations!",
        "favoriteLabel": "Favorite",
        "favoriteLabel_plural": "Favorites",
        "favoritesFirst": "Favorites First",
        "welcome": "Welcome",
        "welcomeTo": "Welcome to {{value}}",
        "welcomeText": "Connect your data, visualize them and obtain knowledge to improve your business objectives",
        "fontLabel": "Font",
        "alignmentLabel": "Alignment",
        "colorLabel": "Color",
        "colorLabel_plural": "Colors",
        "decorationLabel": "Text decoration",
        "decimalsLabel": "Decimals",
        "textLabel": "Text",
        "numberTypeLabel": "Number type",
        "chooseNumberTypeLabel": "Select number type",
        "numberLongLabel": "Number",
        "percentageLabel": "Percentage",
        "currencyLabel": "Currency",
        "chooseCurrencyLabel": "Select currency",
        "symbolPositionLabel": "Symbol position",
        "beforeLabel": "Before",
        "afterLabel": "After",
        "alwaysShowAllDecimalsLabel": "Always show all decimals",
        "thousandsSeparatorLabel": "Thousands separator",
        "useParenthesisAsNegative": "Parenthesis as negative",
        "useNumericPrefix": "Use numeric prefixes",
        "currencyEuroLabel": "Euro (€)",
        "currencyEuroLongLabel": "Euro (EUR, €)",
        "currencyDollarLabel": "Dollar ($)",
        "currencyDollarLongLabel": "US Dollar (USD, $)",
        "currencyPoundLabel": "Pound (£)",
        "currencyPoundLongLabel": "British Pound (GBP, £)",
        "currencyYenLabel": "Yen (¥)",
        "currencyYenLongLabel": "Japanese Yen (JPY, ¥)",
        "currencyYuanLabel": "Yuan (元)",
        "currencyYuanLongLabel": "Chinese Yuan (CNY, 元)",
        "originLabel": "Origin",
        "usingLabel": "using",
        "aliasLabel": "Alias",
        "ofLabel": "of",
        "dimension": "Dimension",
        "measure": "Metric",
        "changeLabel": "Change",
        "importLabel": "Import",
        "readLabel": "Read",
        "printIt": "Print {{object}}",
        "generatedByLabel": "Generated by BIUWER",
        "logicLabel": "Logic",
        "expressionNotValidated": "Invalid expression",
        "seeObject": "View {{object}}",
        "deleteObject": "Delete {{object}}",
        "configureObject": "Configure {{object}}",
        "builtinLabel": "Builtin",
        "builtinLabel_female": "Builtin",
        "builtinLabel_plural": "Builtin",
        "builtinLabel_female_plural": "Builtin",
        "customLabel": "Custom",
        "customLabel_female": "Custom",
        "customLabel_plural": "Custom",
        "customLabel_female_plural": "Custom",
        "nullLabel": "NULL",
        "marginLabel": "Margin",
        "marginLabel_plural": "Margins",
        "nextLabel": "Next",
        "autodetect": "Autodetect",
        "recordBehavior": "Record behavior",
        "showRecordsWithoutValue": "Show records without value",
        "showRecordsWithoutValueInfo": {
            "p1": "This option applies the filters through sub queries, before applying the relationships between Datasets, thus achieving to show in all cases the records that have no value.",
            "p2": "This makes sense with OUTER JOINS applied to the query and with a configuration in which two or more tables are involved by the application of the configured filters.",
            "p3": "For example, if in a Data Model you relate a time dimension and a table of facts, in whose data there are no values every day, but you want them to always appear every day even if there is no data in some of them."
        },
        "rowLimit": "Row limit",
        "recordLimit": "Record limit",
        "unlimited": "Unlimited records",
        "numberOfRecords": "Number of records",
        "numberOfRows": "Number of rows",
        "recoveredRows": "Recovered rows",
        "displayedRows": "Displayed rows",
        "noGroupBy": "Don't group values",
        "formula": "Formula",
        "layout": "Layout",
        "updateAvailableLabel": "There is a new version of Biuwer available",
        "updateAppLabel": "Update",
        "absoluteLabel": "Absolute",
        "relativeLabel": "Relative",
        "timeMetric": "It is a time metric?",
        "timeLevel": "Time level",
        "timeReferenceType": "Time reference type",
        "timeReferenceOption": "Time reference option",
        "timeFormat": "Time format",
        "lastConnection": "Last connection",
        "lastUpdate": "Last update",
        "fieldType": "Field type",
        "multiValueLabelDesc": "Allow multiple values",
        "multiValueLabel": "Múltiple",
        "visibleLabel": "Visible",
        "editableLabel": "Editable",
        "enumerate": "Enumerate",
        "destinationLabel": "Destination",
        "public": "Public",
        "private": "Private",
        "enable": "Enable",
        "disable": "Disable",
        "validFrom": "Valid from",
        "validTo": "Valid to",
        "copyToClipboard": "Copy to clipboard",
        "variableLabel": "Variable",
        "basic": "Basic",
        "standard": "Standard",
        "advanced": "Advanced",
        "selectObject": "Select a {{object}}",
        "selectObject_female": "Select a {{object}}",
        "assign" : "Assign",
        "assignObject": "Assign {{object}}",
        "unassign" : "Unassign",
        "unassignObject": "Unassign {{object}}",
        "help": "Help",
        "usage": {
            "usageLabel": "Usage",
            "changeHistory": "History of changes",
            "whereItsUsed": "Where it's used",
            "whereItsUsed_male": "Where it's used the",
            "whereItsUsed_female": "Where it's used the",
            "usageStats": "Usage statistics",
            "emptyContent": "This {{object}} it's not related with another object.",
            "emptyContent_female": "This {{object}} it's not related with another object."
        },
        "trialLayer": {
            "trialingTodayLabel": "Your Organization's trial period ends today.",
            "trialingLabel": "Your Organization's trial period ends in 1 day.",
            "trialingLabel_plural": "Your Organization's trial period ends in {{count}} days.",
            "trialExpiredLabel": "Your Organization's trial period has expired.",
            "configureSubscriptionLabel": "Configure your subscription now",
            "chatWithUsLabel": "Chat with us and get an extension"
        },
        "verified": "Verified",
        "unverified": "Unverified",
        "categoryLabel": "Category",
        "cancelled": "Cancelled",
        "cancelled_female": "Cancelled",
        "featureWall": {
            "p1": "Upgrade available",
            "p2": "The feature you are trying to access is available in higher plans.",
            "p3": "Upgrade your plan to enjoy this one and many more features",
            "seePlans": "See Plans",
            "limitP1": "Limit reached",
            "limitP2": "Your organization has used <0>{{current}} of {{limit}} {{entity}}</0> included in current subscription.",
            "limitP2_plural": "Your organization has used <0>{{current}} of {{limit}} {{entity}}</0> included in current subscription.",
            "limitP3": "Upgrade your plan to extend current limits.",
            "chatWithUs": "Chat with us"
        },
        "predefined": "Predefined",
        "sample": "Sample",
        "noValue": "No value",
        "key": "Key",
        "value": "Value",
        "comingSoon": "Coming soon",
        "button": "Button",
        "writeEmail": "Write email"
    },
    "timeReferenceOptions": {
        "startOfMinute": "Start of minute",
        "startOfHour": "Start of hour",
        "startOfDay": "Start of day",
        "startOfWeek": "Start of week",
        "startOfMonth": "Start of month",
        "startOfYear": "Start of year",
    },
    "dataTypes": {
        "string": "Text",
        "number": "Number",
        "date": "Date",
        "datetime": "Datetime",
        "boolean": "Boolean"
    },
    "measure": {
        "timeUnits": {
            "second": "second",
            "second_plural": "seconds",
            "minute": "minute",
            "minute_plural": "minutes",
            "hour": "hour",
            "hour_plural": "hours",
            "week": "week",
            "week_plural": "weeks",
            "quarter": "quarter",
            "quarter_plural": "quarters",
            "month": "month",
            "month_plural": "months",
            "year": "year",
            "year_plural": "years",
            "day": "day",
            "day_plural": "days"
        }
    },
    "payment": {
        "paymentInformationLabel": "Payments and billing information",
        "paymentMethod": "Payment method",
        "paymentMethod_plural": "Payment methods",
        "directDebitMethod": "Debit recept",
        "accountNumberIBAN": "Account number (IBAN)",
        "accountNumberIBANIncorrectMessage": "Invalid IBAN code",
        "creditCardSuccess": "Card payment has been processed successfully",
        "creditCardError": {
            "declined": "Your card has been declined",
            "incomplete_card": "Please, fill your card's data",
            "incomplete_cvc": "Your card's security code is incomplete",
            "incomplete_expiry": "Your card's expiration date is incomplete",
            "incomplete_number": "Your card number is incomplete",
            "expired_card": "Your card has expired",
            "card_declined_card_not_supported": "This card is not supported",
            "invalid_brand": "This card brand is not supported",
            "incorrect_cvc": "Your card's security code is incorrect",
            "incorrect_number": "Your card's number is incorrect",
            "invalid_cvc": "Your card's security code is invalid",
            "invalid_expiry_month": "Your card's expiration date in invalid",
            "invalid_expiry_month_past": "Your card's expiration date is in the past",
            "invalid_expiry_year": "Your card's expiration year is invalid",
            "invalid_expiry_year_past": "Your card's expiration year is in the past",
            "invalid_number": "Your card's number is invalid",
            "unexpected": "An unexpected error has occurred",
            "authentication_failure": "We couldn't authenticate the payment card",
            "payment_intent_authentication_failure": "We couldn't authenticate the payment card",
            "setup_intent_authentication_failure": "We couldn't authenticate the payment card",
            "insufficient_funds": "Card has not enough funds"
        },
        "creditCardAlert": {
            "noCardMessage": "In order to pay with payment card, you need to add it previously",
            "noCardCheckoutMessage": "In order to pay with payment card , you need to add it in Organization Details page",
            "invalidCardMessage": "The payment card is not valid, update it to continue using it",
            "invalidCardCheckoutMessage": "The payment card is not valid, update it in Organization Details page",
            "deleteCardMessage": "Are you sure you want to delete the card ending in {{last4}}? It will no longer be available to pay for orders"
        },
        "creditCards": {
            "title": "Card",
            "title_plural": "Cards",
            "description": "We accept VISA and MasterCard debit or credit cards",
            "addLabel": "Add card",
            "editLabel": "Edit card",
            "setAsDefault": "Set as default"
        },
        "status": {
            "pending": "Pending",
            "pending_female": "Pending",
            "pending_plural": "Pending",
            "pending_female_plural": "Pending",
            "paid": "Paid",
            "paid_female": "Paid",
            "paid_plural": "Paid",
            "paid_female_plural": "Paid",
            "cancelled": "Cancelled",
            "cancelled_female": "Cancelled",
            "cancelled_plural": "Cancelled",
            "cancelled_female_plural": "Cancelled"
        },
        "paymentLinkPage": {
            "title": "Order payment",
            "orderDetails": "Order details",
            "orderAlreadyPaid": "The order number {{orderNumber}} is already paid",
            "invalidPaymentLink": "The payment link is not valid"
        }
    },
    "tables": {
        "configureLabel": "Table configuration"
    },
    "oauth": {
        "title": "Oauth Authorization",
        "authorize": "Get authorization",
        "authorizedWith": "Authorized with {{user}}",
        "changeAuthorization": "Change authorization",
        "googleAuthorize": "Authorize Google",
        "facebookAuthorize": "Authorize Facebook",
        "authorizing": "Authorizing",
        "canceling": "Canceling",
        "reGoogleAuthorize": "Change Google authorization",
        "relatedFields": {
            "analyticsAccount": "Account",
            "property": "Property",
            "view": "View",
            "spreadsheet": "Document identification",
            "documentMode": "Document identification",
            "account": "Account",
            "managerAccount": "Manager Account",
            "adAccount": "Ad Account"
        }
    },
    "notifications": {
        "createSuccess_male": "{{name}} created successfully",
        "createSuccess_female": "{{name}} created successfully",
        "createSuccess_male_plural": "{{name}} created successfully",
        "createSuccess_female_plural": "{{name}} created successfully",

        "addSuccess_male": "{{name}} added successfully",
        "addSuccess_female": "{{name}} added successfully",
        "addSuccess_male_plural": "{{name}} added successfully",
        "addSuccess_female_plural": "{{name}} added successfully",

        "assignSuccess_male": "{{name}} assigned successfully",
        "assignSuccess_female": "{{name}} assigned successfully",
        "assignSuccess_male_plural": "{{name}} assigned successfully",
        "assignSuccess_female_plural": "{{name}} assigned successfully",

        "updateSuccess_male": "{{name}} updated successfully",
        "updateSuccess_female": "{{name}} updated successfully",
        "updateSuccess_male_plural": "{{name}} updated successfully",
        "updateSuccess_female_plural": "{{name}} updated successfully",

        "moveSuccess_male": "{{name}} moved successfully",
        "moveSuccess_female": "{{name}} moved successfully",
        "moveSuccess_male_plural": "{{name}} moved successfully",
        "moveSuccess_female_plural": "{{name}} moved successfully",

        "duplicateSuccess_male": "{{name}} duplicated successfully",
        "duplicateSuccess_female": "{{name}} duplicated successfully",
        "duplicateSuccess_male_plural": "{{name}} duplicated successfully",
        "duplicateSuccess_female_plural": "{{name}} duplicated successfully",

        "copyToClipboardSuccess_male": "{{name}} copied to clipboard successfully",
        "copyToClipboardSuccess_female": "{{name}} copied to clipboard successfully",
        "copyToClipboardSuccess_male_plural": "{{name}} copied to clipboard successfully",
        "copyToClipboardSuccess_female_plural": "{{name}} copied to clipboard successfully",

        "cancelSuccess_male": "{{name}} canceled successfully",
        "cancelSuccess_female": "{{name}} canceled successfully",
        "cancelSuccess_male_plural": "{{name}} canceled successfully",
        "cancelSuccess_female_plural": "{{name}} canceled successfully",

        "deleteSuccess_male": "{{name}} deleted successfully",
        "deleteSuccess_female": "{{name}} deleted successfully",
        "deleteSuccess_male_plural": "{{name}} deleted successfully",
        "deleteSuccess_female_plural": "{{name}} deleted successfully",

        "unassignSuccess_male": "{{name}} unassigned successfully",
        "unassignSuccess_female": "{{name}} unassigned successfully",
        "unassignSuccess_male_plural": "{{name}} unassigned successfully",
        "unassignSuccess_female_plural": "{{name}} unassigned successfully",

        "importSuccess_male": "{{name}} imported successfully",
        "importSuccess_female": "{{name}} imported successfully",
        "importSuccess_male_plural": "{{name}} imported successfully",
        "importSuccess_female_plural": "{{name}} imported successfully",

        "sendWelcomeEmailSuccess": "Welcome email sent successfully",
        "sendWelcomeEmailError": "Error sending welcome email",
        "sendingWelcomeEmail": "Sending welcome email...",

        "favoritesUpdated": "Favorites updated",
        "switchedToAdvancedSuccess": "User {{name}} switched to Advanced successfully",
        "switchedToBasicSuccess": "User {{name}} switched to Basic successfully",

        "downloadPDFSuccessMessage": "PDF generated successfully, saving file ...",
        "downloadFileSuccessMessage": "File generated successfully, saving file ...",

        "error": "An error has occurred",
        "errorPrintPDF": "Error generating PDF",
        "errorPrintFile": "Error generating file",

        "generatingFile":"Generating file ...",
        "downloadingFile": "Downloading file ...",
        "generatingPDF":"Generating PDF ...",
        "downloadingPDF": "Downloading PDF ...",

        "nextExecution": "The data will be obtained on {{date}}",
        "currentExecution": "The data will be available in a few minutes",
        "spaceLink": "Access the content in the space",
        "accessContent": "Access the content",
    },
    "timeUnits": {
        "second_short": "s",
        "second_long": "second",
        "second_long_plural": "seconds",
        "minute_short": "mi",
        "minute_long": "minute",
        "minute_long_plural": "minutes",
        "hour_short": "h",
        "hour_long": "hour",
        "hour_long_plural": "hours",
        "day_short": "d",
        "day_long": "day",
        "day_long_plural": "days",
        "month_short": "m",
        "month_long": "month",
        "month_long_plural": "months",
        "year_short": "y",
        "year_long": "year",
        "year_long_plural": "years"
    },
    "iconLabels": {
        "icon":"Icon",
        "icon_plural":"Icons",
        "display_icon_question":"Display Icon?"
    },
    "countries": {
        "AF": "Afghanistan",
        "AL": "Albania",
        "DE": "Germany",
        "AD": "Andorra",
        "AO": "Angola",
        "AI": "Anguilla",
        "AQ": "Antarctica",
        "AG": "Antigua and Barbuda",
        "SA": "Saudi Arabia",
        "DZ": "Algeria",
        "AR": "Argentina",
        "AM": "Armenia",
        "AW": "Aruba",
        "AU": "Australia",
        "AT": "Austria",
        "AZ": "Azerbaijan",
        "BS": "Bahamas (the)",
        "BH": "Bahrain",
        "BD": "Bangladesh",
        "BB": "Barbados",
        "BE": "Belgium",
        "BZ": "Belize",
        "BJ": "Benin",
        "BM": "Bermuda",
        "BY": "Belarus",
        "MM": "Myanmar",
        "BO": "Bolivia",
        "BA": "Bosnia and Herzegovina",
        "BW": "Botswana",
        "BR": "Brazil",
        "BN": "Brunei",
        "BG": "Bulgaria",
        "BF": "Burkina Faso",
        "BI": "Burundi",
        "BT": "Bhutan",
        "CV": "Cabo Verde",
        "KH": "Cambodia",
        "CM": "Cameroon",
        "CA": "Canada",
        "TD": "Chad",
        "CL": "Chile",
        "CN": "China",
        "CY": "Cyprus",
        "VA": "Holy See (the)",
        "CO": "Colombia",
        "KM": "Comoros (the)",
        "CG": "Congo (the)",
        "CD": "Congo (the Democratic Republic of the)",
        "KR": "Korea (the Republic of)",
        "KP": "Korea (the Democratic People's Republic of)",
        "CI": "Côte d'Ivoire",
        "CR": "Costa Rica",
        "HR": "Croatia",
        "CU": "Cuba",
        "CW": "Curaçao",
        "DK": "Denmark",
        "DJ": "Djibouti",
        "DM": "Dominica",
        "EC": "Ecuador",
        "EG": "Egypt",
        "SV": "El Salvador",
        "AE": "United Arab Emirates (the)",
        "ER": "Eritrea",
        "SI": "Slovenia",
        "ES": "Spain - Peninsula and Balearic Islands",
        "ES_CANARY": "Spain - Canary Islands",
        "US": "United States of America (the)",
        "EE": "Estonia",
        "ET": "Ethiopia",
        "FJ": "Fiji",
        "PH": "Philippines (the)",
        "FI": "Finland",
        "FR": "France",
        "GA": "Gabon",
        "GM": "Gambia (the)",
        "GE": "Georgia",
        "GH": "Ghana",
        "GI": "Gibraltar",
        "GD": "Grenada",
        "GR": "Greece",
        "GL": "Greenland",
        "GP": "Guadeloupe",
        "GU": "Guam",
        "GT": "Guatemala",
        "GG": "Guernsey",
        "GY": "Guyana",
        "GF": "French Guiana",
        "GN": "Guinea",
        "GQ": "Equatorial Guinea",
        "GW": "Guinea-Bissau",
        "HT": "Haiti",
        "HN": "Honduras",
        "HK": "Hong Kong",
        "HU": "Hungary",
        "IN": "India",
        "ID": "Indonesia",
        "IQ": "Irak",
        "IR": "Iran (Islamic Republic of)",
        "IE": "Ireland",
        "BV": "Bouvet Island",
        "CX": "Christmas Island",
        "IS": "Iceland",
        "KY": "Cayman Islands (the)",
        "CK": "Cook Islands (the)",
        "CC": "Cocos (Keeling) Islands (the)",
        "FO": "Faroe Islands (the)",
        "HM": "Heard Island and McDonald Islands",
        "FK": "Falkland Islands (the) [Malvinas]",
        "MP": "Northern Mariana Islands (the)",
        "MH": "Marshall Islands (the)",
        "UM": "United States Minor Outlying Islands (the)",
        "PW": "Palau",
        "SB": "Solomon Islands",
        "SJ": "Svalbard and Jan Mayen",
        "TK": "Tokelau",
        "TC": "Turks and Caicos Islands (the)",
        "VI": "Virgin Islands (U.S.)",
        "VG": "Virgin Islands (British)",
        "WF": "Wallis and Futuna",
        "IL": "Israel",
        "IT": "Italy",
        "JM": "Jamaica",
        "JP": "Japan",
        "JO": "Jordan",
        "KZ": "Kazakhstan",
        "KE": "Kenya",
        "KG": "Kyrgyzstan",
        "KI": "Kiribati",
        "KW": "Kuwait",
        "LA": "Lao People's Democratic Republic (the)",
        "LS": "Lesotho",
        "LV": "Latvia",
        "LB": "Lebanon",
        "LR": "Liberia",
        "LY": "Libya",
        "LI": "Liechtenstein",
        "LT": "Lithuania",
        "LU": "Luxembourg",
        "MK": "Republic of North Macedonia",
        "MG": "Madagascar",
        "MY": "Malaysia",
        "MW": "Malawi",
        "MV": "Maldives",
        "ML": "Mali",
        "MT": "Malta",
        "MA": "Morocco",
        "MQ": "Martinique",
        "MU": "Mauritius",
        "MR": "Mauritania",
        "YT": "Mayotte",
        "MX": "Mexico",
        "FM": "Micronesia (Federated States of)",
        "MD": "Moldova (the Republic of)",
        "MC": "Monaco",
        "MN": "Mongolia",
        "ME": "Montenegro",
        "MS": "Montserrat",
        "MZ": "Mozambique",
        "NA": "Namibia",
        "NR": "Nauru",
        "NP": "Nepal",
        "NI": "Nicaragua",
        "NE": "Niger (the)",
        "NG": "Nigeria",
        "NU": "Niue",
        "NF": "Norfolk Island",
        "NO": "Norway",
        "NC": "New Caledonia",
        "NZ": "New Zealand",
        "OM": "Oman",
        "NL": "Netherlands (the)",
        "PA": "Panama",
        "PG": "Papua New Guinea",
        "PK": "Pakistan",
        "PY": "Paraguay",
        "PE": "Peru",
        "PN": "Pitcairn",
        "PF": "French Polynesia",
        "PL": "Poland",
        "PT": "Portugal",
        "PR": "Puerto Rico",
        "QA": "Qatar",
        "GB": "United Kingdom of Great Britain and Northern Ireland (the)",
        "CF": "Central African Republic (the)",
        "CZ": "Czechia",
        "ZA": "South Africa",
        "DO": "Dominican Republic (the)",
        "SK": "Slovakia",
        "RE": "Réunion",
        "RW": "Rwanda",
        "RO": "Romania",
        "RU": "Russian Federation (the)",
        "EH": "Western Sahara",
        "KN": "Saint Kitts and Nevis",
        "WS": "Samoa",
        "AS": "American Samoa",
        "SM": "San Marino",
        "VC": "Saint Vincent and the Grenadines",
        "SH": "Saint Helena, Ascension and Tristan da Cunha",
        "LC": "Saint Lucia",
        "ST": "Sao Tome and Principe",
        "SN": "Senegal",
        "RS": "Serbia",
        "SC": "Seychelles",
        "SL": "Sierra Leone",
        "SG": "Singapore",
        "SY": "Syrian Arab Republic",
        "SO": "Somalia",
        "LK": "Sri Lanka",
        "PM": "Saint Pierre and Miquelon",
        "SZ": "Eswatini",
        "SD": "Sudan (the)",
        "SE": "Sweden",
        "CH": "Switzerland",
        "SR": "Suriname",
        "TH": "Thailand",
        "TW": "Taiwan (Province of China)",
        "TZ": "Tanzania, United Republic of",
        "TJ": "Tajikistan",
        "TF": "French Southern Territories (the)",
        "TL": "Timor-Leste",
        "TG": "Togo",
        "TO": "Tonga",
        "TT": "Trinidad and Tobago",
        "TN": "Tunisia",
        "TM": "Turkmenistan",
        "TR": "Turkey",
        "TV": "Tuvalu",
        "UA": "Ukraine",
        "UG": "Uganda",
        "UY": "Uruguay",
        "UZ": "Uzbekistan",
        "VU": "Vanuatu",
        "VE": "Venezuela (Bolivarian Republic of)",
        "VN": "Viet Nam",
        "YE": "Yemen",
        "YU": "Yugoslavia",
        "ZM": "Zambia",
        "ZW": "Zimbabwe"
    }
};