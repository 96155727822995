import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import FileSaver from "file-saver"
import html2canvas from '@cantoo/html2canvas';

// Components
import HtmlDataGrid from "@biuwer/common/src/components/html-data-grid"
import HtmlDataTable from "@biuwer/common/src/components/html-data-table"

// Libs
import vtLib from "@biuwer/cards/src/card-types/vertical-card-lib";
import querySystem from '@biuwer/core/src/query-system';
import { CARD_SCREEN_BODY_ID, CARD_BODY_CLASS, PAGE_BODY_ID } from '@biuwer/common/src/libs/content/content-constants';

/**
 * Store the received blob as a file and downloads it on client device using FileSaver library
 * @param res, response given by superagent request
 */
export function saveFile(res) {
    let startIndex, endIndex, docName;

    // get the file name from header response
    if (res.header['content-disposition'].indexOf('"') !== -1) {
        startIndex = res.header['content-disposition'].indexOf('"') + 1;
        endIndex = res.header['content-disposition'].length - 1;
    } else if (res.header['content-disposition'].indexOf('=') !== -1) {
        startIndex = res.header['content-disposition'].indexOf('=') + 1;
    } else {
        startIndex = res.header['content-disposition'] = 0;
    }

    if (endIndex) {
        docName = res.header['content-disposition'].substring(startIndex, endIndex);
    } else {
        docName = res.header['content-disposition'].substring(startIndex);
    }

    // res.xhr.response contains the Blob object containing Document binaries, ready to saveAs
    FileSaver.saveAs(res.xhr.response, docName);
}

/**
 * Aux function to convert base64 files to Blob
 * @param data
 * @param contentType
 * @returns {Promise<Blob>}
 */
const b64toBlob = async (data, contentType = 'application/octet-stream') => {
    const url = `data:${contentType};base64,${data}`;
    const response = await fetch(url);
    return await response.blob();
};

export const b64ToText = (data) => {
    return decodeURIComponent(Array.prototype.map.call(atob(data), (c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''));
};

export const textTob64 = (data) => {
    return btoa(encodeURIComponent(data).replace(/%([0-9A-F]{2})/g, (match, p1) => {
        return String.fromCharCode(parseInt(p1, 16))
    }));
};

/**
 * Aux function to get the content type of the given file format/extension
 * @param extension
 * @returns {Promise<string>}
 */
const getContentType = async (extension) => {
    switch (extension) {
        case 'csv':
            return 'text/csv';
        case 'xls':
        case 'xlsx':
            return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        case 'pdf':
            return 'application/pdf';
        default:
            return 'application/octet-stream';
    }
};

/**
 * Store the received base64 string as a file and downloads it on client device using FileSaver library
 * @param data
 * @param format
 * @param name
 * @returns {*}
 */
export const saveDoc = async (data, format, name) => {

    const contentType = await getContentType(format);

    FileSaver.saveAs(await b64toBlob(data, contentType), name);
};

/**
 * Convert given card body html element to canvas and return it as base64 png img
 * @param card, card body html element
 */
function cardBodyToCanvas(card) {
    return new Promise((resolve, reject) => {
        html2canvas(card, { logging: false })
            .then(canvas => {
                resolve(canvas.toDataURL('image/png'));
            })
            .catch((err) => {
                reject(err);
            });
    });
}

/**
 * Convert given card body html element as a simple html element formatted for printing
 * @param cardBodyEl, card body html element
 * @param cardMetaData, card meta data given as props to react card element renderer
 * @param cardData, card wrapper react element refered
 * @param extraData, card wrapper react element refered
 */
 async function cardBodyToHTML(cardBodyEl, cardMetaData, cardData, extraData) {
    return new Promise(async (resolve, reject) => {
        let cardProps = {};
        let newCardBody;
        try {
            const tableColumns = vtLib.getCardTableColumns({ cardMetaData });
            const tableSettings = vtLib.getTableSettings({ cardMetaData, tableColumns });
            switch (cardMetaData.card_type) {
                case 'VerticalTable':
                    // Get react DOM element by refs and its props
                    cardProps.tableSettings = {
                        ...tableSettings,
                        showHeader: true,
                        sortable: false,
                        filterable: false,
                        defaultSortFields: [...tableSettings.defaultSortFields || []],
                        defaultSortDirections: [...tableSettings.defaultSortDirections || []],
                        headerStyle: {},
                        rowStyle: {},
                        footerStyle: {}
                    };
                    cardProps.tableColumns = tableColumns.filter(column => column.dataField !== 'rowNumber').map(column => {
                        return {
                            ...column,
                            sortable: false,
                            filterable: false
                        }
                    });
                    const localCardData = querySystem.getLocalCardData({ cardData: cardData, card: cardMetaData });
                    cardProps.tableData = localCardData[0];
                    cardBodyEl.style.setProperty('height', '');
                    newCardBody = renderToStaticMarkup(<HtmlDataTable {...cardProps}/>);
                    break;
                    case 'CrossTable':
                        cardProps = {
                            tableSettings: {
                                ...extraData.tableSettings,
                                leftHeaderStyle: extraData.leftHeaderStyle,
                                leftGridStyle: extraData.leftGridStyle,
                                leftFooterStyle: extraData.leftFooterStyle,
                                mainHeaderStyle: extraData.mainHeaderStyle,
                                mainGridStyle: extraData.mainGridStyle,
                                mainFooterStyle: extraData.mainFooterStyle,
                                rightHeaderStyle: extraData.rightHeaderStyle,
                                rightGridStyle: extraData.rightGridStyle,
                                rightFooterStyle: extraData.rightFooterStyle,
                            },
                            tableData: {
                                leftHeaderData: extraData.leftHeaderData.map(leftHeader => {
                                    return leftHeader.map(header => {
                                        return {
                                            ...header,
                                            functions: {
                                                ...header.functions,
                                                renderer: !!header.functions.renderer ? 'cellRenderer1' : ''
                                            }
                                        }
                                    });
                                }),
                                leftGridData: extraData.leftGridData.map(leftGrid => {
                                    return leftGrid.map(grid => {
                                        return {
                                            ...grid,
                                            functions: {
                                                ...grid.functions,
                                                renderer: !!grid.functions.renderer ? 'cellRenderer1' : ''
                                            }
                                        }
                                    });
                                }),
                                leftFooterData: extraData.leftFooterData.map(leftFooter => {
                                    return leftFooter.map(footer => {
                                        return {
                                            ...footer,
                                            functions: {
                                                ...footer.functions,
                                                renderer: !!footer.functions.renderer ? 'cellRenderer1' : ''
                                            },
                                            style: {
                                                ...footer.style,
                                                fontWeight: footer.style.fontWeight === 'bold' ? 600 : footer.style.fontWeight
                                            }
                                        }
                                    });
                                }),
                                mainHeaderData: extraData.mainHeaderData.map(mainHeader => {
                                    return mainHeader.map(header => {
                                        return {
                                            ...header,
                                            functions: {
                                                ...header.functions,
                                                renderer: !!header.functions.renderer ? 'cellRenderer1' : ''
                                            }
                                        }
                                    });
                                }),
                                mainGridData: extraData.mainGridData.map(mainGrid => {
                                    return mainGrid.map(grid => {
                                        return {
                                            ...grid,
                                            functions: {
                                                ...grid.functions,
                                                renderer: !!grid.functions.renderer ? 'cellRenderer1' : ''
                                            }
                                        }
                                    });
                                }),
                                mainFooterData: extraData.mainFooterData.map(mainFooter => {
                                    return mainFooter.map(footer => {
                                        return {
                                            ...footer,
                                            functions: {
                                                ...footer.functions,
                                                renderer: !!footer.functions.renderer ? 'cellRenderer1' : ''
                                            },
                                            style: {
                                                ...footer.style,
                                                fontWeight: footer.style.fontWeight === 'bold' ? 600 : footer.style.fontWeight
                                            }
                                        }
                                    });
                                }),
                                rightHeaderData: extraData.rightHeaderData.map(rightHeader => {
                                    return rightHeader.map(header => {
                                        return {
                                            ...header,
                                            functions: {
                                                ...header.functions,
                                                renderer: !!header.functions.renderer ? 'cellRenderer1' : ''
                                            }
                                        }
                                    });
                                }),
                                rightGridData: extraData.rightGridData.map(rightGrid => {
                                    return rightGrid.map(grid => {
                                        return {
                                            ...grid,
                                            functions: {
                                                ...grid.functions,
                                                renderer: !!grid.functions.renderer ? 'cellRenderer1' : ''
                                            }
                                        }
                                    });
                                }),
                                rightFooterData: extraData.rightFooterData.map(rightFooter => {
                                    return rightFooter.map(footer => {
                                        return {
                                            ...footer,
                                            functions: {
                                                ...footer.functions,
                                                renderer: !!footer.functions.renderer ? 'cellRenderer1' : ''
                                            },
                                            style: {
                                                ...footer.style,
                                                fontWeight: footer.style.fontWeight === 'bold' ? 600 : footer.style.fontWeight
                                            }
                                        }
                                    });
                                }),
                            }
                        };
                        cardBodyEl.style.setProperty('height', '');
                        newCardBody = renderToStaticMarkup(<HtmlDataGrid {...cardProps}/>);
                        break;
                    default:
                    newCardBody = cardBodyEl.innerHTML;
                    break;
            }
        } catch (err) {
            resolve(cardBodyEl.innerHTML);
        }

        resolve(newCardBody);
    });
}

/**
 * Auxiliar function to get the transformation process of a card body based of its card type
 * @param cardType
 * @param cardAdjust
 * @returns {*}
 */
function detectCardPrintingMode(cardType, cardAdjust) {
    if (cardAdjust && cardType !== 'CrossTable') {
        return 'canvas';
    }

    switch (cardType) {
        case 'VerticalTable':
        case 'CrossTable':
            return 'html';
        case 'KPI':
        case 'Chart':
        case 'Custom':
            return 'canvas';
        default:
            return null;
    }
}

/**
 * Auxiliar function for convert HTMLCollection to Array
 * @param collection, html collection
 * @returns {Array}
 */
function convertHTMLCollectionToArray(collection) {
    let result = [];
    for (let element of collection) {
        result.push(element);
    }
    return result;
}

/**
 * Generate an array of arrays structure with card body HTML elements.
 * First array is defined by the row, second by the column and third by the card position in column.
 * @param pageBodyElement
 * @returns {Array}
 */
function getCardStructure(pageBodyElement) {
    let pageRows = convertHTMLCollectionToArray(pageBodyElement.getElementsByClassName('pageRow')),
        pageCols = pageRows.map(row => {
            return convertHTMLCollectionToArray(row.getElementsByClassName('pageCol'));
        }),
        structureCards = [];
    pageCols.forEach(col => {
        structureCards.push(col.map(c => {
            return convertHTMLCollectionToArray(c.getElementsByClassName('card-body'));
        }));
    });

    return structureCards;
}

/**
 * Prepare card HTML element to be printed.
 * Only used when called print card, not for convert card HTML elements from page
 * @param fromPage
 * @param cardAdjust
 * @param cardId
 * @param cardDetail
 * @param cardData
 */
 export const prepareCardForPrint = async (fromPage, cardAdjust, cardId, cardDetail, cardData) => {
        try {

            const cardType = cardDetail.getIn(["payload", "card_type"]);
            const cardRoute = cardDetail.get("cardRoute");

            let newDocument = document.cloneNode(true),
                newCardBody,
                customCardIcon = convertHTMLCollectionToArray(newDocument.getElementsByClassName('custom-header-icon'));

            for (let customIcon of customCardIcon) {
                customIcon.innerHTML = null;
            }

            let originalCardDocument, cardBody;

            // Card comes from a page view screen
            if (fromPage) {
                let structureCards = getCardStructure(newDocument.getElementById(PAGE_BODY_ID)),
                    originalStructure = getCardStructure(document.getElementById(PAGE_BODY_ID));
                cardRoute.forEach((path, index) => {
                    if (index === 0) {
                        originalCardDocument = originalStructure[path];
                        cardBody = structureCards[path];
                    } else {
                        originalCardDocument = originalCardDocument[path];
                        cardBody = cardBody[path];
                    }
                    if (index === cardRoute.length-1) {
                        if (cardType === 'Custom') {
                            originalCardDocument = originalCardDocument.childNodes[0];
                            cardBody = cardBody.childNodes[0];
                        }
                        cardBody.style.height = 'auto';
                    }
                });
            } else {
                // Card comes from a card view screen
                if (cardType !== 'Custom') {
                    originalCardDocument = document.getElementById(CARD_SCREEN_BODY_ID).getElementsByClassName(CARD_BODY_CLASS)[0];
                    cardBody = newDocument.getElementById(CARD_SCREEN_BODY_ID).getElementsByClassName(CARD_BODY_CLASS)[0];
                } else {
                    originalCardDocument = document.getElementById(CARD_SCREEN_BODY_ID).getElementsByClassName(CARD_BODY_CLASS)[0].childNodes[0];
                    cardBody = newDocument.getElementById(CARD_SCREEN_BODY_ID).getElementsByClassName(CARD_BODY_CLASS)[0].childNodes[0];
                }
            }

            // Check which transformation must be done to cardBody
            switch (detectCardPrintingMode(cardType, cardAdjust)) {
                case 'html':
                    newCardBody = await cardBodyToHTML(cardBody, cardDetail.get("payload").toJS(), cardData, cardDetail.get("virtualizedMultiGridProps") && cardDetail.get("virtualizedMultiGridProps").toJS());
                    break;
                case 'canvas':
                    if (cardAdjust) {
                        const customCardDocument = document.getElementById(`${cardId}-${cardRoute && cardRoute.join('-')}`);
                        if (customCardDocument) {
                            originalCardDocument = customCardDocument;
                        }
                        let canvas = await cardBodyToCanvas(originalCardDocument);
                        newCardBody = `<img src="${canvas}" style="display: block; max-width: 100%; height: auto; page-break-inside: avoid"/>`;
                    } else if (cardType === 'Custom' || cardType === "Chart") {
                        let canvas = await cardBodyToCanvas(originalCardDocument);
                        newCardBody = `<img src="${canvas}" style="display: block; max-width: 100%; height: auto; page-break-inside: avoid"/>`;
                    } else {
                        newCardBody = cardBody.innerHTML;
                    }
                    break;
                default:
                    newCardBody = `<div style="height: auto">${cardBody.innerHTML}</div>`;
                    break;
            }
            return newCardBody;
        } catch (err) {
            return null;
        }
}

export default {
    prepareCardForPrint,
    saveFile,
    saveDoc,
    b64ToText,
    textTob64
};