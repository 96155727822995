import { List, fromJS, Iterable } from 'immutable';
import moment from "moment";
import { isNil, upperFirst, capitalize, cloneDeep } from "lodash";
import { getFilterExpressionFromURLParam } from "../../filters-lib";
import History from "@biuwer/core/src/history";

/**
 * Filters a string data array using an operation and a searchString
 * @param {Array<*>} data Data to filter
 * @param {String} operation Operation to apply
 * @param {String} searchString Text to search
 * @returns {Array<*>}
 */
export const filterStringData = (data, operation, searchString) => {

    let filteredData = [], regEx;

    if (data && data.length > 0) {
        filteredData = data.filter(f => {
            let include = true, field = f?.label || f;

            // If no value for the field, filter it
            if (isNil(field)) {
                include = false;
            }

            // contains --> If searchString is not found inside the field, filter it
            if (operation === 'contains' && !isNil(field) && field.toString().toLowerCase().indexOf(searchString.toLowerCase()) === -1) {
                include = false;
            } else if (operation === 'notContains' && !isNil(field) && field.toString().toLowerCase().indexOf(searchString.toLowerCase()) !== -1) {
                include = false;
            }

            // startsWith --> If field does not start with the searchString, filter it
            if (operation === 'startsWith' && searchString) {
                regEx = new RegExp('^' + searchString.toLowerCase());
                if (!isNil(field) && !field.toString().toLowerCase().match(regEx)) {
                    include = false;
                }
            } else if (operation === 'notStartsWith' &&  searchString) {
                regEx = new RegExp('^' + searchString.toLowerCase());
                if (!isNil(field) && field.toString().toLowerCase().match(regEx)) {
                    include = false;
                }
            }

            // endsWith --> If field does not end with the searchString, filter it
            if (operation === 'endsWith' && searchString) {
                regEx = new RegExp(searchString.toLowerCase() + '$');
                if (!isNil(field) && !field.toString().toLowerCase().match(regEx)) {
                    include = false;
                }
            } else if (operation === 'notEndsWith' && searchString) {
                regEx = new RegExp(searchString.toLowerCase() + '$');
                if (!isNil(field) && field.toString().toLowerCase().match(regEx)) {
                    include = false;
                }
            }

            // equals --> If searchString is not exactly field, filter it
            if (operation === 'equals' && !isNil(field) && field.toString().toLowerCase() !== searchString.toLowerCase()) {
                include = false;
            } else if (operation === 'notEquals' && !isNil(field) && field.toString().toLowerCase() === searchString.toLowerCase()) {
                include = false;
            }

            // Null and not null
            if (operation === 'isNull') {
                include = false;
            } else if (operation === 'isNotNull'  && !isNil(field) && field.toString().toLowerCase()) {
                include = true;
            }

            return include;
        });
    }
    return filteredData;
}

/**
 * Filters a number data array using an operation and two search strings
 * @param {Array<*>} data Data to filter
 * @param {String} operation Operation to apply
 * @param {String} searchString1 String to search
 * @param {String} searchString2 String to search
 * @returns {Array<*>}
 */
export const filterNumberData = (data, operation, searchString1, searchString2) => {

    let filteredData = [];

    if (data && data.length > 0) {
        filteredData = data.filter(field => {
            let include = true,
                fieldValue = field;

            // If no value for the field, filter it
            if (isNil(fieldValue)) {
                include = false;
            }

            // greaterThan --> If searchString1 >= fieldValue, filter it
            if (operation === 'greaterThan' && !isNil(fieldValue) && searchString1 && Number(searchString1) >= fieldValue) {
                include = false;
            }

            // greaterOrEqualThan --> If searchString1 > fieldValue, filter it
            if (operation === 'greaterOrEqualThan' && !isNil(fieldValue) && searchString1 && Number(searchString1) > fieldValue) {
                include = false;
            }

            // smallerThan --> If searchString1 <= fieldValue, filter it
            if (operation === 'smallerThan' && !isNil(fieldValue) && searchString1 && Number(searchString1) <= fieldValue) {
                include = false;
            }

            // smallerOrEqualThan --> If searchString1 < fieldValue, filter it
            if (operation === 'smallerOrEqualThan' && !isNil(fieldValue) && searchString1 && Number(searchString1) < fieldValue) {
                include = false;
            }

            // between --> If searchString1 > fieldValue and searchString2 < fieldValue, filter it
            if (operation === 'between' && !isNil(fieldValue)) {
                if (searchString1 && Number(searchString1) > fieldValue) {
                    include = false;
                }
                if (searchString2 && Number(searchString2) < fieldValue) {
                    include = false;
                }
            }

            if (operation === 'notBetween' && !isNil(fieldValue)) {
                if ((searchString1 && searchString2 && Number(searchString1) <= fieldValue && Number(searchString2) >= fieldValue) || (Number(searchString1) + Number(searchString2) === 0) || (Number(searchString1) > Number(searchString2))) {
                    include = false;
                }
            }

            // equals --> If searchString1 !== fieldValue, filter it
            if(operation === 'equals' && !isNil(fieldValue) && searchString1 && Number(searchString1) !== fieldValue) {
                include = false;
            }

            // notEquals --> If searchString1 === fieldValue, filter it
            if(operation === 'notEquals' && !isNil(fieldValue) && searchString1 && Number(searchString1) === fieldValue) {
                include = false;
            }

            // Null and not null
            if (operation === 'isNull') {
                include = false;
            } else if (operation === 'isNotNull' && !isNil(fieldValue) && searchString1 && Number(searchString1)) {
                include = true;
            }

            return include;
        });
    }
    return filteredData;
}

/**
 * Filters data with the values introduced
 * @param {Array<*>} data Field data obtained from server.
 * @param {String} operation Operation applied to filter (equals, greater than, between, etc).
 * @param {String} searchString1 Value introduced by user. Used in all operations. In between operations is used as "from".
 * @param {String} searchString2 Value introduced by user. Only used in between operations as "to".
 * @param {String} dateLevel Date level introduced by user. Necessary to correctly compare dates.
 * @returns {Array<*>}
 */
export const filterDateData = (data, operation, searchString1, searchString2, dateLevel) => {

    let filteredData = [];
    if (data && data.length > 0) {
        filteredData = data.filter(field => {
            let include = true,
                fieldValue = field;

            // If no value for the field, filter it
            if (!fieldValue) {
                include = false;
            }

            let dateLevelMask, level = dateLevel;
            switch (dateLevel) {
                case 'year':
                    dateLevelMask = 'Y';
                    break;
                case 'quarter':
                    dateLevelMask = 'Y-Q';
                    break;
                case 'month':
                    dateLevelMask = 'Y-M';
                    break;
                case 'day':
                    dateLevelMask = 'Y-M-D';
                    break;
                case 'hour_of_day':
                    level = 'hour';
                    dateLevelMask = 'H';
                    break;
                default:
                    break;
            }

            // Compare dates taking into account date level and operation
            switch (operation) {
                case 'greaterThan':
                    if (fieldValue && searchString1) {
                        include = moment(fieldValue, dateLevelMask).isAfter(moment(searchString1, dateLevelMask), level);
                    }
                    break;
                case 'greaterOrEqualThan':
                    if (fieldValue && searchString1) {
                        include = moment(fieldValue, dateLevelMask).isSameOrAfter(moment(searchString1, dateLevelMask), level);
                    }
                    break;
                case 'smallerThan':
                    if (fieldValue && searchString1) {
                        include = moment(fieldValue, dateLevelMask).isBefore(moment(searchString1, dateLevelMask), level);
                    }
                    break;
                case 'smallerOrEqualThan':
                    if (fieldValue && searchString1) {
                        include = moment(fieldValue, dateLevelMask).isSameOrBefore(moment(searchString1, dateLevelMask), level);
                    }
                    break;
                case 'between':
                    if (fieldValue && searchString1) {
                        include = moment(fieldValue, dateLevelMask).isBetween(moment(searchString1, dateLevelMask), moment(searchString2, dateLevelMask), level, '[]');
                    }
                    break;
                case 'notBetween':
                    if (fieldValue && searchString1) {
                        include = !moment(fieldValue, dateLevelMask).isBetween(moment(searchString1, dateLevelMask), moment(searchString2, dateLevelMask), level, '[]');
                    }
                    break;
                case 'equals':
                    if (fieldValue && searchString1) {
                        include = moment(fieldValue, dateLevelMask).isSame(moment(searchString1, dateLevelMask), level);
                    }
                    break;
                case 'notEquals':
                    if (fieldValue && searchString1) {
                        include = !moment(fieldValue, dateLevelMask).isSame(moment(searchString1, dateLevelMask), level);
                    }
                    break;
                case 'isNull':
                    include = false;
                    break;
                case 'isNotNull':
                    include = true;
                    break;
                default:
                    break;
            }

            return include;
        });
    }
    return filteredData;
}

/**
 * Transform dates to the desired format
 * @param {String} dateLevel Aggregation level applied to date
 * @param {String} value Date as received from the server
 * @param {Function} t i18next translate function
 * @param {String} dateFormat Format to apply when date level is "day"
 * @returns {String}
 */
export const stringifyDate = (dateLevel, value, t, dateFormat) => {

    switch (dateLevel) {
        case 'year':
            return value;
        case 'quarter':
            return `${t('filters.dates.quarterSymbol') + moment(value, 'Y-Q').format('Q')} ${moment(value, 'Y-Q').format('Y')}`;
        case 'month':
            return moment(value, 'Y-M').format('MMMM Y');
        case 'day':
            if (dateFormat) {
                return moment(value, 'Y-M-D').format(dateFormat);
            } else {
                return `${moment(value, 'Y-M-D').format('D')} ${upperFirst(moment(value, 'Y-M-D').format('MMMM Y'))}`;
            }
        default:
            return value;
    }
}

/**
 * Transform predefined date ranges in the desired format
 * @param {String} dateLevel Aggregation level applied to date
 * @param {String} selectedRange Selected range
 * @param {String} operation Selected operation
 * @param {Function} t i18next translate function
 * @returns {String}
 */
export const stringifyDateRange = (dateLevel, selectedRange, operation, t) => {

    let stringifiedDate = `${operation === 'notPredefinedRange' ? `${t('filters.excludeLabel')} ` : ''}`;
    switch (dateLevel) {
        case 'year':
            switch (selectedRange) {
                case 'thisYear':
                    stringifiedDate += moment().format('Y');
                    break;
                case 'previousYear':
                    stringifiedDate += moment().subtract(1, 'year').format('Y');
                    break;
                case 'previousTwoYears':
                    stringifiedDate += `${moment().subtract(2, 'year').format('Y')} >> ${moment().subtract(1, 'year').format('Y')}`;
                    break;
                case 'twoYearsAgo':
                    stringifiedDate += moment().subtract(2, 'year').format('Y');
                    break;
                case 'lastTwoYears':
                    stringifiedDate += `${moment().subtract(1, 'year').format('Y')} >> ${moment().format('Y')}`;
                    break;
                case 'lastThreeYears':
                    stringifiedDate += `${moment().subtract(2, 'year').format('Y')} >> ${moment().format('Y')}`;
                    break;
                case 'yearToDate':
                    stringifiedDate += `${moment().startOf('year').format('D MMMM Y')} >> ${moment().format('D MMMM Y')}`;
                    break;
                default:
                    break;
            }
            break;
        case 'quarter':
            switch (selectedRange) {
                case 'thisQuarter':
                    stringifiedDate += `${t('filters.dates.quarterSymbol') + moment().format('Q')} ${moment().format('Y')}`;
                    break;
                case 'previousQuarter':
                    stringifiedDate += `${t('filters.dates.quarterSymbol') + moment().subtract(1, 'quarter').format('Q')} ${moment().subtract(1, 'quarter').format('Y')}`;
                    break;
                case 'previousTwoQuarters':
                    stringifiedDate += t('filters.dates.quarterSymbol') + moment().subtract(2, 'quarter').format('Q') + ' ' + moment().subtract(2, 'quarter').format('Y') + ' >> '
                        + t('filters.dates.quarterSymbol') + moment().subtract(1, 'quarter').format('Q') + ' ' + moment().subtract(1, 'quarter').format('Y');
                    break;
                case 'twoQuartersAgo':
                    stringifiedDate += `${t('filters.dates.quarterSymbol') + moment().subtract(2, 'quarter').format('Q')} ${moment().subtract(2, 'quarter').format('Y')}`;
                    break;
                case 'threeQuartersAgo':
                    stringifiedDate += `${t('filters.dates.quarterSymbol') + moment().subtract(3, 'quarter').format('Q')} ${moment().subtract(3, 'quarter').format('Y')}`;
                    break;
                case 'lastTwoQuarters':
                    stringifiedDate += t('filters.dates.quarterSymbol') + moment().subtract(1, 'quarter').format('Q') + ' ' + moment().subtract(1, 'quarter').format('Y') + ' >> '
                        + t('filters.dates.quarterSymbol') + moment().format('Q') + ' ' + moment().format('Y');
                    break;
                case 'lastThreeQuarters':
                    stringifiedDate += t('filters.dates.quarterSymbol') + moment().subtract(2, 'quarter').format('Q') + ' ' + moment().subtract(2, 'quarter').format('Y') + ' >> '
                        + t('filters.dates.quarterSymbol') + moment().format('Q') + ' ' + moment().format('Y');
                    break;
                case 'lastFourQuarters':
                    stringifiedDate += t('filters.dates.quarterSymbol') + moment().subtract(3, 'quarter').format('Q') + ' ' + moment().subtract(3, 'quarter').format('Y') + ' >> '
                        + t('filters.dates.quarterSymbol') + moment().format('Q') + ' ' + moment().format('Y');
                    break;
                case 'quarterToDate':
                    stringifiedDate += `${moment().startOf('quarter').format('D MMMM Y')} >> ${moment().format('D MMMM Y')}`;
                    break;
                default:
                    break;
            }
            break;
        case 'month':
            switch (selectedRange) {
                case 'thisMonth':
                    stringifiedDate += upperFirst(moment().format('MMMM Y'));
                    break;
                case 'previousMonth':
                    stringifiedDate += upperFirst(moment().subtract(1, 'month').format('MMMM Y'));
                    break;
                case 'previousTwoMonths':
                    stringifiedDate += `${upperFirst(moment().subtract(2, 'month').format('MMMM Y'))} >> ${upperFirst(moment().subtract(1, 'month').format('MMMM Y'))}`;
                    break;
                case 'twoMonthsAgo':
                    stringifiedDate += upperFirst(moment().subtract(2, 'month').format('MMMM Y'));
                    break;
                case 'threeMonthsAgo':
                    stringifiedDate += upperFirst(moment().subtract(3, 'month').format('MMMM Y'));
                    break;
                case 'lastTwoMonths':
                    stringifiedDate += `${upperFirst(moment().subtract(1, 'month').format('MMMM Y'))} >> ${upperFirst(moment().format('MMMM Y'))}`;
                    break;
                case 'lastThreeMonths':
                    stringifiedDate += `${upperFirst(moment().subtract(2, 'month').format('MMMM Y'))} >> ${upperFirst(moment().format('MMMM Y'))}`;
                    break;
                case 'lastSixMonths':
                    stringifiedDate += `${upperFirst(moment().subtract(5, 'month').format('MMMM Y'))} >> ${upperFirst(moment().format('MMMM Y'))}`;
                    break;
                case 'lastTwelveMonths':
                    stringifiedDate += `${upperFirst(moment().subtract(11, 'month').format('MMMM Y'))} >> ${upperFirst(moment().format('MMMM Y'))}`;
                    break;
                case 'monthToDate':
                    stringifiedDate += `${moment().startOf('month').format('D MMMM Y')} >> ${moment().format('D MMMM Y')}`;
                    break;
                default:
                    break;
            }
            break;
        case 'week':
            switch (selectedRange) {
                case 'thisWeek':
                    stringifiedDate += `${t('filters.dates.week')} ${moment().format('W')} ${moment().format('Y')}`;
                    break;
                case 'lastWeek':
                    stringifiedDate += `${t('filters.dates.week')} ${moment().subtract(1, 'week').format('W Y')}`;
                    break;
                case 'previousTwoWeeks':
                    stringifiedDate += `${t('filters.dates.week')} ${moment().subtract(2, 'week').format('W Y')} >> ${t('filters.dates.week')} ${moment().subtract(1, 'week').format('W Y')}`;
                    break;
                case 'twoWeeksAgo':
                    stringifiedDate += `${t('filters.dates.week')} ${moment().subtract(2, 'week').format('W Y')}`;
                    break;
                case 'threeWeeksAgo':
                    stringifiedDate += `${t('filters.dates.week')} ${moment().subtract(3, 'week').format('W Y')}`;
                    break;
                case 'lastTwoWeeks':
                    stringifiedDate += `${t('filters.dates.week')} ${moment().subtract(1, 'week').format('W Y')} >> ${t('filters.dates.week')} ${moment().format('W')} ${moment().format('Y')}`;
                    break;
                case 'lastThreeWeeks':
                    stringifiedDate += `${t('filters.dates.week')} ${moment().subtract(2, 'week').format('W Y')} >> ${t('filters.dates.week')} ${moment().format('W')} ${moment().format('Y')}`;
                    break;
                case 'lastFourWeeks':
                    stringifiedDate += `${t('filters.dates.week')} ${moment().subtract(3, 'week').format('W Y')} >> ${t('filters.dates.week')} ${moment().format('W')} ${moment().format('Y')}`;
                    break;
                case 'lastSixWeeks':
                    stringifiedDate += `${t('filters.dates.week')} ${moment().subtract(5, 'week').format('W Y')} >> ${t('filters.dates.week')} ${moment().format('W')} ${moment().format('Y')}`;
                    break;
                case 'lastEightWeeks':
                    stringifiedDate += `${t('filters.dates.week')} ${moment().subtract(7, 'week').format('W Y')} >> ${t('filters.dates.week')} ${moment().format('W')} ${moment().format('Y')}`;
                    break;
                case 'lastTwelveWeeks':
                    stringifiedDate += `${t('filters.dates.week')} ${moment().subtract(11, 'week').format('W Y')} >> ${t('filters.dates.week')} ${moment().format('W')} ${moment().format('Y')}`;
                    break;
                case 'weekToDate':
                    stringifiedDate += `${moment().startOf('week').format('D MMMM Y')} >> ${moment().format('D MMMM Y')}`;
                    break;
                default:
                    break;
            }
            break;
        case 'day':
            switch (selectedRange) {
                case 'today':
                    stringifiedDate += `${moment().format('D')} ${upperFirst(moment().format('MMMM Y'))}`;
                    break;
                case 'yesterday':
                    stringifiedDate += `${moment().subtract(1, 'day').format('D')} ${upperFirst(moment().subtract(1, 'day').format('MMMM Y'))}`;
                    break;
                case 'previousTwoDays':
                    stringifiedDate += moment().subtract(2, 'day').format('D') + ' ' + upperFirst(moment().subtract(2, 'day').format('MMMM Y')) + ' >> '
                        + moment().subtract(1, 'day').format('D') + ' ' + upperFirst(moment().subtract(1, 'day').format('MMMM Y'));
                    break;
                case 'twoDaysAgo':
                    stringifiedDate += `${moment().subtract(2, 'day').format('D')} ${upperFirst(moment().subtract(2, 'day').format('MMMM Y'))}`;
                    break;
                case 'threeDaysAgo':
                    stringifiedDate += `${moment().subtract(3, 'day').format('D')} ${upperFirst(moment().subtract(3, 'day').format('MMMM Y'))}`;
                    break;
                case 'currentWeek':
                    stringifiedDate += moment().startOf('week').format('D') + ' ' + upperFirst(moment().startOf('week').format('MMMM Y')) + ' >> '
                        + moment().format('D') + ' ' + upperFirst(moment().format('MMMM Y'));
                    break;
                case 'lastSevenDays':
                    stringifiedDate += moment().subtract(6, 'day').format('D') + ' ' + upperFirst(moment().subtract(6, 'day').format('MMMM Y')) + ' >> '
                        + moment().format('D') + ' ' + upperFirst(moment().format('MMMM Y'));
                    break;
                case 'lastFourteenDays':
                    stringifiedDate += moment().subtract(13, 'day').format('D') + ' ' + upperFirst(moment().subtract(13, 'day').format('MMMM Y')) + ' >> '
                        + moment().format('D') + ' ' + upperFirst(moment().format('MMMM Y'));
                    break;
                case 'lastThirtyDays':
                    stringifiedDate += moment().subtract(29, 'day').format('D') + ' ' + upperFirst(moment().subtract(29, 'day').format('MMMM Y')) + ' >> '
                        + moment().format('D') + ' ' + upperFirst(moment().format('MMMM Y'));
                    break;
                case 'lastSixtyDays':
                    stringifiedDate += moment().subtract(59, 'day').format('D') + ' ' + upperFirst(moment().subtract(59, 'day').format('MMMM Y')) + ' >> '
                        + moment().format('D') + ' ' + upperFirst(moment().format('MMMM Y'));
                    break;
                default:
                    break;
            }
            break;
        case 'hour_of_day':
            switch(selectedRange) {
                case 'lastHour':
                    stringifiedDate += `${moment().subtract(1, 'hour').format('D')} ${upperFirst(moment().subtract(1, 'hour').format('MMMM Y HH:mm:ss'))} >> ${moment().format('D')} ${upperFirst(moment().format('MMMM Y HH:mm:ss'))}`;
                    break;
                case 'lastTwoHours':
                    stringifiedDate += `${moment().subtract(2, 'hour').format('D')} ${upperFirst(moment().subtract(2, 'hour').format('MMMM Y HH:mm:ss'))} >> ${moment().format('D')} ${upperFirst(moment().format('MMMM Y HH:mm:ss'))}`;
                    break;
                case 'lastThreeHours':
                    stringifiedDate += `${moment().subtract(3, 'hour').format('D')} ${upperFirst(moment().subtract(3, 'hour').format('MMMM Y HH:mm:ss'))} >> ${moment().format('D')} ${upperFirst(moment().format('MMMM Y HH:mm:ss'))}`;
                    break;
                case 'lastFourHours':
                    stringifiedDate += `${moment().subtract(4, 'hour').format('D')} ${upperFirst(moment().subtract(4, 'hour').format('MMMM Y HH:mm:ss'))} >> ${moment().format('D')} ${upperFirst(moment().format('MMMM Y HH:mm:ss'))}`;
                    break;
                case 'lastSixHours':
                    stringifiedDate += `${moment().subtract(6, 'hour').format('D')} ${upperFirst(moment().subtract(6, 'hour').format('MMMM Y HH:mm:ss'))} >> ${moment().format('D')} ${upperFirst(moment().format('MMMM Y HH:mm:ss'))}`;
                    break;
                case 'lastEightHours':
                    stringifiedDate += `${moment().subtract(8, 'hour').format('D')} ${upperFirst(moment().subtract(8, 'hour').format('MMMM Y HH:mm:ss'))} >> ${moment().format('D')} ${upperFirst(moment().format('MMMM Y HH:mm:ss'))}`;
                    break;
                case 'lastTwelveHours':
                    stringifiedDate += `${moment().subtract(12, 'hour').format('D')} ${upperFirst(moment().subtract(12, 'hour').format('MMMM Y HH:mm:ss'))} >> ${moment().format('D')} ${upperFirst(moment().format('MMMM Y HH:mm:ss'))}`;
                    break;
                case 'lastTwentyFourHours':
                    stringifiedDate += `${moment().subtract(24, 'hour').format('D')} ${upperFirst(moment().subtract(24, 'hour').format('MMMM Y HH:mm:ss'))} >> ${moment().format('D')} ${upperFirst(moment().format('MMMM Y HH:mm:ss'))}`;
                    break;
                default:
                    break;
            }
            break;
        default:
            break;
    }
    return stringifiedDate;
}

/**
 * Generates an array with the keys of each time section
 * @param {String} key Key to be modified
 * @param {String} format Time format applied in section
 * @returns {Array<String>}
 */
export const getTimeValues = (key, format) => {

    let timeValues, timeValuesMin, timeValuesMax;
    let formatInputs = 0;

    if (format.indexOf('y(') > -1) formatInputs++;
    if (format.indexOf('M(') > -1) formatInputs++;
    if (format.indexOf('d(') > -1) formatInputs++;
    if (format.indexOf('h(') > -1 || format.indexOf('h:') > -1) formatInputs++;
    if (format.indexOf('m(') > -1 || format.indexOf('m:') > -1) formatInputs++;
    if (format.indexOf('s(') > -1 || format.indexOf('s:') > -1) formatInputs++;

    if (format && formatInputs) {
        switch (formatInputs) {
            case 1:
                timeValues = ['timeValue1'];
                timeValuesMin = ['timeValuesMin1'];
                timeValuesMax = ['timeValuesMax1'];
                break;
            case 2:
                timeValues = ['timeValue1', 'timeValue2'];
                timeValuesMin = ['timeValuesMin1', 'timeValuesMin2'];
                timeValuesMax = ['timeValuesMax1', 'timeValuesMax2'];
                break;
            case 3:
                timeValues = ['timeValue1', 'timeValue2', 'timeValue3'];
                timeValuesMin = ['timeValuesMin1', 'timeValuesMin2', 'timeValuesMin3'];
                timeValuesMax = ['timeValuesMax1', 'timeValuesMax2', 'timeValuesMax3'];
                break;
            default:
                break;
        }
    }

    switch (key) {
        case 'min':
            return timeValuesMin;
        case 'max':
            return timeValuesMax;
        case 'value':
            return timeValues;
        default:
            break;
    }
}

/**
 * Generates a time part label given a time part expression
 * @param {String} timePart Time part expression
 * @param {Funtion} t i18next translate function
 * @returns {String}
 */
export const getTimeLabel = (timePart, t) => {

    if (timePart) {
        if (timePart === 'y' || timePart.indexOf('years') > -1 || timePart.indexOf('años') > -1) return capitalize(t(`timeUnits.year_long_plural`));
        if (timePart === 'M' || timePart.indexOf('months') > -1 || timePart.indexOf('meses') > -1) return capitalize(t(`timeUnits.month_long_plural`));
        if (timePart === 'd' || timePart.indexOf('days') > -1 || timePart.indexOf('días') > -1) return capitalize(t(`timeUnits.day_long_plural`));
        if (timePart === 'h' || timePart.indexOf('hours') > -1 || timePart.indexOf('horas') > -1) return capitalize(t(`timeUnits.hour_long_plural`));
        if (timePart === 'mi' || timePart.indexOf('minutes') > -1 || timePart.indexOf('minutos') > -1) return capitalize(t(`timeUnits.minute_long_plural`));
        if (timePart === 's' || timePart.indexOf('seconds') > -1 || timePart.indexOf('segundos') > -1) return capitalize(t(`timeUnits.second_long_plural`));
    }
}

/**
 * Generates the default value for the predefinedValue tab in date filters, based on date level
 * @param {String} dateLevel Selected date level
 * @returns {String}
 */
export const getPredefinedValue = (dateLevel) => {

    switch (dateLevel) {
        case 'year':
            return 'thisYear';
        case 'quarter':
            return 'thisQuarter';
        case 'month':
            return 'thisMonth';
        case 'week':
            return 'thisWeek';
        case 'day':
            return 'today';
        default:
            break;
    }
}

/**
 * Calculates the initial tab based on the filter configuration
 * @param {String} dataType Filter data type
 * @param {String} filterType Filter type
 * @param {String} fieldType Field type
 * @param {String} operation Selected operation
 * @param {Boolean} multiValue Indicates if the filter is multi valued or not
 * @param {Boolean} useCustomList Indicates if the filter is using a custom list or not
 * @param {Array<*>} currentTabs Current available tabs
 * @returns {Number} Index of initial tab
 */
export const getInitialTab = (dataType, filterType, fieldType, operation, multiValue, useCustomList, currentTabs) => {

    switch (dataType) {
        case 'string':

            if (filterType === 'Field' || useCustomList) {
                switch (operation) {
                    case 'inList':
                    case 'notInList':
                        return currentTabs.findIndex((tab) => tab === "selection") >= 0 ? currentTabs.findIndex((tab) => tab === "selection") : 0;
                    case 'contains':
                    case 'notContains':
                    case 'startsWith':
                    case 'notStartsWith':
                    case 'endsWith':
                    case 'notEndsWith':
                    case 'isNull':
                    case 'isNotNull':
                        return currentTabs.findIndex((tab) => tab === "expression") >= 0 ? currentTabs.findIndex((tab) => tab === "expression") : 1;
                    case 'equals':
                    case 'notEquals':
                        if (fieldType === 'value' || (useCustomList && !multiValue)) {
                            return currentTabs.findIndex((tab) => tab === "selection") >= 0 ? currentTabs.findIndex((tab) => tab === "selection") : 0;
                        } else {
                            return currentTabs.findIndex((tab) => tab === "expression") >= 0 ? currentTabs.findIndex((tab) => tab === "expression") : 1;
                        }
                    case 'top':
                    case 'notTop':
                    case 'bottom':
                    case 'notBottom':
                    case 'interval':
                    case 'notInterval':
                        return currentTabs.findIndex((tab) => tab === "top_bottom") >= 0 ? currentTabs.findIndex((tab) => tab === "top_bottom") : 2;
                    default:
                        return 0;
                }
            } else {
                return 0;
            }

        case 'number':

            if (filterType === 'Field' || useCustomList) {
                switch (operation) {
                    case 'inList':
                    case 'notInList':
                        return currentTabs.findIndex((tab) => tab === "selection") >= 0 ? currentTabs.findIndex((tab) => tab === "selection") : 0;
                    case 'greaterThan':
                    case 'smallerThan':
                    case 'between':
                    case 'notBetween':
                    case 'isNull':
                    case 'isNotNull':
                        return currentTabs.findIndex((tab) => tab === "expression") >= 0 ? currentTabs.findIndex((tab) => tab === "expression") : 1;
                    case 'equals':
                    case 'notEquals':
                        if (fieldType === 'value' || (useCustomList && !multiValue)) {
                            return currentTabs.findIndex((tab) => tab === "selection") >= 0 ? currentTabs.findIndex((tab) => tab === "selection") : 0;
                        } else {
                            return currentTabs.findIndex((tab) => tab === "expression") >= 0 ? currentTabs.findIndex((tab) => tab === "expression") : 1;
                        }
                    case 'top':
                    case 'notTop':
                    case 'bottom':
                    case 'notBottom':
                    case 'interval':
                    case 'notInterval':
                        return currentTabs.findIndex((tab) => tab === "top_bottom") >= 0 ? currentTabs.findIndex((tab) => tab === "top_bottom") : 2;
                    default:
                        return currentTabs.findIndex((tab) => tab === "expression") >= 0 ? currentTabs.findIndex((tab) => tab === "expression") : 1;
                }
            } else {
                return 0;
            }

        case 'date':
        case 'datetime':
            if (filterType === 'Field' || useCustomList) {
                switch (operation) {
                    case 'inList':
                    case 'notInList':
                        return currentTabs.findIndex((tab) => tab === "selection") >= 0 ? currentTabs.findIndex((tab) => tab === "selection") : 0;
                    case 'between':
                    case 'notBetween':
                    case 'greaterThan':
                    case 'greaterOrEqualThan':
                    case 'smallerThan':
                    case 'smallerOrEqualThan':
                        return currentTabs.findIndex((tab) => tab === "expression") >= 0 ? currentTabs.findIndex((tab) => tab === "expression") : 1;
                    case 'predefinedRange':
                    case 'notPredefinedRange':
                        return currentTabs.findIndex((tab) => tab === "predefined") >= 0 ? currentTabs.findIndex((tab) => tab === "predefined") : 2;
                    case 'equals':
                    case 'notEquals':
                        if (fieldType === 'value' || (useCustomList && !multiValue)) {
                            return currentTabs.findIndex((tab) => tab === "selection") >= 0 ? currentTabs.findIndex((tab) => tab === "selection") : 0;
                        } else {
                            return currentTabs.findIndex((tab) => tab === "expression") >= 0 ? currentTabs.findIndex((tab) => tab === "expression") : 1;
                        }
                    case 'top':
                    case 'notTop':
                    case 'bottom':
                    case 'notBottom':
                    case 'interval':
                    case 'notInterval':
                        return currentTabs.findIndex((tab) => tab === "top_bottom") >= 0 ? currentTabs.findIndex((tab) => tab === "top_bottom") : 3;
                    default:
                        return 1;
                }
            } else {
                switch (operation) {
                    case 'inList':
                    case 'notInList':
                        return currentTabs.findIndex((tab) => tab === "expression") >= 0 ? currentTabs.findIndex((tab) => tab === "expression") : 0;
                    case 'predefinedRange':
                    case 'notPredefinedRange':
                        return currentTabs.findIndex((tab) => tab === "predefined") >= 0 ? currentTabs.findIndex((tab) => tab === "predefined") : 1;
                    default:
                        return 0;
                }
            }
        case 'boolean':
            return 0;
        default:
            return 0;
    }
}

/**
 * Checks if filter comes from drill through
 * @param {Object} filter filter object
 * @returns {Object | Boolean}
 */
export const checkDrillThrough = (filter) => {
    const location = History.getLocation();

    const filterId = Iterable.isIterable(filter) ? filter.get("_id") : filter._id;

    if (location?.state?.fromDrillThrough) {
        return location.state.filters.find((historyFilter) => historyFilter._id === filterId);
    } else {
        return false;
    }
}

/**
 * Checks if filter comes from url
 * @param {Object} filter filter object
 * @returns {Object | Boolean}
 */
export const checkURLParam = (filter) => {
    const history = History.getHistory();

    if (filter?.url_param?.enabled) {
        return getFilterExpressionFromURLParam(filter, history);
    } else {
        return false;
    }
}

/**
 * Checks if filter comes from drill through
 * @param {Object} filter filter object
 * @param {Array<Object>} popupFilters filters from popup action
 * @returns {Object | Boolean}
 */
export const checkPopupFilter = (filter, popupFilters) => {
    return popupFilters?.find((f) => f.get("_id") === filter.get("_id")) ?? false;
}

/**
 * Check filters config (drill through, popup and url params)
 * and returns the value of given key inside filter expression.
 * If filter has no expression config, returns key value from default_expression.
 *
 * @param {Object} params Object params
 * @param {Object} params.filter Filter object
 * @param {String} params.key Object key to recover value
 * @param {Array<Object>} [params.popupFilters] Filters from popup action
 * @returns {*}
 */
export function getValueFromFilterExpression({ filter, key, popupFilters = [] }) {

    // Recover filters drill trough config if available
    const interactiveFilter = checkDrillThrough(filter);

    // Recover filters popup config if available
    const popupFilter = checkPopupFilter(filter, popupFilters);

    // Recover filters URL param config if available
    const urlParamFilter = checkURLParam(cloneDeep(filter).toJS());

    if (interactiveFilter) {
        return interactiveFilter?.expression?.[key] && fromJS(interactiveFilter.expression[key]);
    } else if (popupFilter) {
        return popupFilter.getIn(["expression", key]);
    } else if (urlParamFilter) {
        return (urlParamFilter?.[key] && fromJS(urlParamFilter[key]));
    } else {
        if (filter && !isNil(filter.getIn(['expression']))) {
            if (key === 'values' && filter.getIn(['expression', 'all_values'])) {
                return List([]);
            } else {
                return filter.getIn(['expression', key]);
            }
        } else if (filter && !isNil(filter.getIn(['default_expression']))) {
            if (key === 'values' && filter.getIn(['default_expression', 'all_values'])) {
                return List([]);
            } else {
                return filter.getIn(['default_expression', key]);
            }
        } else {
            return null;
        }
    }
}

/**
 * Checks if a value is a number
 * @param {*} input
 * @returns {Boolean}
 */
export const isNumeric = (input) => {
    // eslint-disable-next-line
    return (input - 0) == input && ('' + input).trim().length > 0;
}

export default {
    filterStringData,
    filterNumberData,
    filterDateData,
    stringifyDate,
    stringifyDateRange,
    getTimeValues,
    getTimeLabel,
    getPredefinedValue,
    getInitialTab,
    checkDrillThrough,
    checkURLParam,
    getValueFromFilterExpression,
    isNumeric
}