import  { find } from "lodash";
import { colorMain } from "@biuwer/core/src/theme/scss/_var.scss?export";

export const DATE_AND_DATETIME_DATA_TYPES = ['date', 'datetime'];

export const BOOLEAN_DATA_TYPES= ['boolean'];

export const NUMERIC_DATA_TYPES = ['number'];

export const MAX_LONG_LABEL = 200;

export const STACKED_TOTAL_SERIE_NAME = 'bw_stacked_total';

export const SCATTER_CHART_RADIUS_KEY = 'bw_scatter_radius';

export const BREAK_BY_UNDEFINED_VALUE = 'common.noValue';

export const DEFAULT_NUMBER_TYPE = 'Number';

export const DEFAULT_NUMBER_FORMAT = '0.[00]';

export const DEFAULT_AXIS_LINE_STYLE = {
    show: true,
    width: 1,
    type: 'solid',
    color: '#E8E8E8',
    lineDash: [0, 0]
};

export const DEFAULT_LABELS_FONT_STYLE = {
    style: 'normal',
    weight: 'normal',
    family: 'Exo',
    size: 13,
    color: "#000000"
};

export const DEFAULT_LEGEND_FONT_STYLE = {
    style: "normal",
    weight: "normal",
    family: "Exo",
    size: 12,
    color: "#333333"
}

export const DEFAULT_TOOLTIP_FONT_STYLE = {
    style: "normal",
    weight: "normal",
    family: "Exo",
    size: 14,
    color: "#616161"
}

export const DEFAULT_TICKS_FONT_STYLE = {
    style: "normal",
    weight: "normal",
    family: "Exo",
    size: 12,
    color: "#616161"
}

export const DEFAULT_INTERACTIVE_FILTERS_STYLE = {
    opacity: 0.2
}

// gap to apply to visible axisLabel in some situations like when position is horizontal
export const addNameGap = 10;

// legendGapSymbol = the default with of legend.itemGap is 10 and the default width of legend.itemWidth is 25
export const legendGapSymbol = 35;

export const MIN_VISUALIZATION_ASPECT_RATIO = 35;
export const CHART_TYPES_WITH_PCT_IN_TOOLTIP = ['pie', 'doughnut', 'funnel', 'horizontalFunnel', 'gauge', 'stacked100Bar', 'stacked100HorizontalBar', 'bullet', 'treemap'];
export const CHART_TYPES_WITH_SLICES_LIMIT_AVAILABLE = ['pie', 'doughnut', 'funnel', 'horizontalFunnel', 'bar', 'line', 'area', 'mixed', 'horizontalBar', 'stackedBar', 'stackedHorizontalBar', 'radar', 'treemap', 'stacked100Bar', 'stacked100HorizontalBar', 'bullet'];
export const CHART_TYPES_WITH_BORDER = ['bar', 'horizontalBar', 'stackedBar', 'stackedHorizontalBar', 'stacked100Bar', 'stacked100HorizontalBar', 'pie', 'doughnut'];
export const HORIZONTAL_BAR_CHART_TYPES = ["horizontalBar", "stackedHorizontalBar", "stacked100HorizontalBar"];
export const CHART_TYPES_WITH_ACCUMULATED_SERIES = ["stackedBar", "stackedHorizontalBar"]
export const CIRCULAR_CHART_TYPES = ["pie", "doughnut"]
export const CHART_TYPES_WITH_DATA_PROCESSED_AT_100_PCT = ["stacked100Bar", "stacked100HorizontalBar"]
export const CHART_TYPES_WITH_TICKS_ORIENTATION_CONFIGURATION = ["bar", "stackedBar", "stackedHorizontalBar", "stacked100Bar", "stacked100HorizontalBar", "line", "area", "mixed", "horizontalBar", "scatter", "bubbles"]
export const SCATTER_CHART_TYPES = ["scatter", "bubbles"];
export const CHART_TYPES_WITH_COMPLEMENTS = ["bar", "line", "horizontalBar"]
export const CHART_TYPES_WITH_DATA_ZOOM = ["bar", "line", "area", "horizontalBar", "stackedBar", "horizontalStackedBar", "stacked100Bar", "horizontalStacked100Bar", "scatter", "bubbles"]

export const GAUGE_STRUCTURE_CONFIG = {
    circular: {
        horizontal: {
            top: {
                startAngle: 360,
                endAngle: 0,
                clockwise: true
            },
            bottom: {
                startAngle: 0,
                endAngle: 360,
                clockwise: false
            }
        },
        vertical: {
            left: {
                startAngle: 90,
                endAngle: -270,
                clockwise: true
            },
            right: {
                startAngle: 90,
                endAngle: -270,
                clockwise: false
            }
        }
    },
    semicircular: {
        horizontal: {
            top: {
                startAngle: 180,
                endAngle: -0,
                clockwise: true
            },
            bottom: {
                startAngle: 180,
                endAngle: 0,
                clockwise: false
            }
        },
        vertical: {
            left: {
                startAngle: 90,
                endAngle: -90,
                clockwise: false
            },
            right: {
                startAngle: 90,
                endAngle: -90,
                clockwise: true
            }
        }
    }
};

export const DEFAULT_GAUGE_BACKGROUND_COLOR = '#E6EBF8';
export const DEFAULT_GAUGE_MIN_VALUE = 0;
export const DEFAULT_GAUGE_MAX_VALUE = 100;
export const DEFAULT_GAUGE_WIDTH = 20;
export const DEFAULT_GAUGE_AXIS_LABEL_POSITION = 'inside';
export const DEFAULT_HORIZONTAL_GAUGE_DATALABEL_POSITION = 'top';
export const DEFAULT_GAUGE_START_ANGLE = GAUGE_STRUCTURE_CONFIG.semicircular.horizontal.top.startAngle;
export const DEFAULT_GAUGE_END_ANGLE = GAUGE_STRUCTURE_CONFIG.semicircular.horizontal.top.endAngle;
export const DEFAULT_GAUGE_CLOCKWISE = GAUGE_STRUCTURE_CONFIG.semicircular.horizontal.top.clockwise;

export const DEFAULT_BULLET_BACKGROUND_COLOR = '#E6EBF8';
export const DEFAULT_BULLET_THRESHOLD_BACKGROUND_COLOR = '#000000';
export const DEFAULT_BULLET_COLOR = '#64B5F6';
export const DEFAULT_BULLET_MIN_VALUE = 0;
export const DEFAULT_BULLET_MAX_VALUE = 1000;
export const DEFAULT_BULLET_WIDTH = 20;
export const DEFAULT_BULLET_AXIS_LABEL_POSITION = 'bottom';
export const DEFAULT_HORIZONTAL_BULLET_DATALABEL_POSITION = 'top';
export const DEFAULT_BULLET_THRESHOLD_WIDTH = 20;
export const DEFAULT_BULLET_THRESHOLD_SIZE = 5;

export const TREEMAP_MIN_BORDER_WIDTH = 0;
export const TREEMAP_MAX_BORDER_WIDTH = 5;
export const DEFAULT_TREEMAP_BORDER_WIDTH = 1;
export const DEFAULT_TREEMAP_UPPERLABEL_BACKGROUND_COLOR = 'white';

export const DEFAULT_BORDER_WIDTH = 0;
export const DEFAULT_BORDER_MAX_WIDTH = 10;
export const DEFAULT_BORDER_WIDTH_STEP_SIZE = 1;
export const DEFAULT_BORDER_COLOR = 'transparent';
export const DEFAULT_BORDER_RADIUS = 0;
export const DEFAULT_BORDER_MAX_RADIUS = 25;
export const DEFAULT_BORDER_RADIUS_STEP_SIZE = 1;
export const DEFAULT_BORDER_POSITION = "top";
export const DEFAULT_BORDER_STYLE = "solid";
export const DEFAULT_HEATMAP_COLOR = 'transparent';

export const DEFAULT_SLICES_NUMBER = 2;
export const DEFAULT_PAD_ANGLE = 0;
export const DEFAULT_PAD_ANGLE_MAX_VALUE = 20;
export const DEFAULT_PAD_ANGLE_STEP_SIZE = 1;

export const DEFAULT_LINE_WIDTH = 2;
export const DEFAULT_LINE_TENSION = 0;

export const DEFAULT_COMPLEMENT_FIXED_VALUE = 0;
export const DEFAULT_COMPLEMENT_LABEL_POSITION = 'topRight';
export const DEFAULT_COMPLEMENT_AREA_POSITION = 'behind';
export const DEFAULT_COMPLEMENT_AREA_OPACITY = 0.7;
export const COMPLEMENT_AREA_MIN_OPACITY = 0;
export const COMPLEMENT_AREA_MAX_OPACITY = 1;
export const COMPLEMENT_AREA_STEP_OPACITY = 0.1;

export const DEFAULT_DATA_ZOOM_HEIGHT = 30;
export const DEFAULT_DATA_ZOOM_MIN_HEIGHT = 20;
export const DEFAULT_DATA_ZOOM_MAX_HEIGHT = 70;
export const DATA_ZOOM_STEP_SIZE = 1;
export const DEFAULT_DATA_ZOOM_COLOR = colorMain;
export const DEFAULT_DATA_ZOOM_OPACITY = 0.2;
export const DEFAULT_DATA_ZOOM_FONT_SIZE = 10;

export const DEFAULT_TOOLBOX_ZOOM_POSITION = "topRight";
export const DEFAULT_TOOLBOX_ZOOM_COLOR = colorMain;
export const DEFAULT_TOOLBOX_ZOOM_SIZE = 15;

export const DEFAULT_DOUGHNUT_MIN_RADIUS = 35;
export const DEFAULT_DOUGHNUT_THICKNESS_LEVEL = 10;
export const DOUGHNUT_THICKNESS_MIN_LEVEL = 1;
export const DOUGHNUT_THICKNESS_MAX_LEVEL = 20;

export const DEFAULT_TOOLTIP_SHOW_VALUE = true;
export const TOOLTIP_ALLOW_SHOW_VALUE_WITH_ONE_METRIC = ["stackedBar", "stackedHorizontalBar", "stacked100Bar", "stacked100HorizontalBar"]

// Chart catalog
export const chartCatalog = [
    {
        label: 'Lines',
        value: 'line',
        type: 'line',
        family: 'lines',
        requiresDimension: true,
        requiresBreakBy: false,
        breakable: true,
        allowComplements: true,
        xAxis: {
            enable: true,
            multiple: false,
            dimensionAvailable: false,
            metricsAvailable: false,
            orientation: 'horizontal'
        },
        yAxis: {
            enable: true,
            multiple: true,
            dimensionAvailable: false,
            metricsAvailable: true,
            orientation: 'vertical'
        },
        colorPalette: false,
        orientation: 'vertical',
        icon: 'img/charts/line-chart.svg',
        conditions: {
            dimension: { min: 1, max : 1 },
            metrics: { min: 1 },
            breaker: { max: 1 },
            complements: { min: 0 }
        }
    }, {
        label: 'Areas',
        value: 'area',
        type: 'line',
        family: 'areas',
        requiresDimension: true,
        requiresBreakBy: false,
        breakable: true,
        allowComplements: true,
        xAxis: {
            enable: true,
            multiple: false,
            dimensionAvailable: false,
            metricsAvailable: false,
            orientation: 'horizontal'
        },
        yAxis: {
            enable: true,
            multiple: true,
            dimensionAvailable: false,
            metricsAvailable: true,
            orientation: 'vertical'
        },
        colorPalette: false,
        orientation: 'vertical',
        icon: 'img/charts/area-chart.svg',
        conditions: {
            dimension: { min: 1, max : 1 },
            metrics: { min: 1 },
            breaker: { max: 1 },
            complements: { min: 0 }
        }
    }, {
        label: 'Vertical Bars',
        value: 'bar',
        type: 'bar',
        family: 'bars',
        requiresDimension: true,
        requiresBreakBy: false,
        breakable: true,
        allowComplements: true,
        xAxis: {
            enable: true,
            multiple: false,
            dimensionAvailable: false,
            metricsAvailable: false,
            orientation: 'horizontal'
        },
        yAxis: {
            enable: true,
            multiple: true,
            dimensionAvailable: false,
            metricsAvailable: true,
            orientation: 'vertical'
        },
        colorPalette: false,
        orientation: 'vertical',
        icon: 'img/charts/vertical-bar-chart.svg',
        conditions: {
            dimension: { min: 1, max : 1 },
            metrics: { min: 1 },
            breaker: { max: 1 },
            complements: { min: 0 }
        }
    }, {
        label: 'Horizontal Bars',
        value: 'horizontalBar',
        type: 'horizontalBar',
        family: 'bars',
        xAxis: {
            enable: true,
            multiple: false,
            dimensionAvailable: false,
            metricsAvailable: false,
            orientation: 'vertical'
        },
        yAxis: {
            enable: true,
            multiple: true,
            dimensionAvailable: false,
            metricsAvailable: true,
            orientation: 'horizontal'
        },
        requiresDimension: true,
        requiresBreakBy: false,
        breakable: true,
        allowComplements: true,
        colorPalette: false,
        orientation: 'horizontal',
        icon: 'img/charts/horizontal-bar-chart.svg',
        conditions: {
            dimension: { min: 1, max : 1 },
            metrics: { min: 1 },
            breaker: { max: 1 },
            complements: { min: 0 }
        }
    }, {
        label: 'Stacked Vertical Bars',
        value: 'stackedBar',
        type: 'bar',
        family: 'bars',
        orientation: 'vertical',
        requiresDimension: true,
        requiresBreakBy: true,
        breakable: true,
        allowComplements: true,
        xAxis: {
            enable: true,
            multiple: false,
            dimensionAvailable: false,
            metricsAvailable: false,
            orientation: 'horizontal'
        },
        yAxis: {
            enable: true,
            multiple: true,
            dimensionAvailable: false,
            metricsAvailable: true,
            orientation: 'vertical'
        },
        colorPalette: true,
        icon: 'img/charts/stacked-vertical-chart.svg',
        conditions: {
            dimension: { min: 1, max : 1 },
            metrics: { min: 1, max: 1 },
            breaker: { min: 1 },
            complements: { min: 0 }
        }
    }, {
        label: 'Stacked Horizontal Bars',
        value: 'stackedHorizontalBar',
        type: 'horizontalBar',
        family: 'bars',
        requiresDimension: true,
        requiresBreakBy: true,
        breakable: true,
        allowComplements: true,
        xAxis: {
            enable: true,
            multiple: false,
            dimensionAvailable: false,
            metricsAvailable: false,
            orientation: 'vertical'
        },
        yAxis: {
            enable: true,
            multiple: true,
            dimensionAvailable: false,
            metricsAvailable: true,
            orientation: 'horizontal'
        },
        colorPalette: true,
        orientation: 'horizontal',
        icon: 'img/charts/stacked-horizontal-chart.svg',
        conditions: {
            dimension: { min: 1, max : 1 },
            metrics: { min: 1, max: 1 },
            breaker: { min: 1 },
            complements: { min: 0 }
        }
    }, {
        label: 'Mixes Lines/Bars',
        value: 'mixed',
        type: 'bar',
        family: 'mixed',
        requiresDimension: true,
        requiresBreakBy: false,
        breakable: false,
        allowComplements: true,
        xAxis: {
            enable: true,
            multiple: false,
            dimensionAvailable: false,
            metricsAvailable: false,
            orientation: 'horizontal'
        },
        yAxis: {
            enable: true,
            multiple: true,
            dimensionAvailable: false,
            metricsAvailable: true,
            orientation: 'vertical'
        },
        colorPalette: false,
        orientation: 'vertical',
        icon: 'img/charts/mixed-chart.svg',
        conditions: {
            dimension: { min: 1, max : 1 },
            metrics: { min: 1 },
            breaker: { max: 0 },
            complements: { min: 0 }
        }
    }, {
        label: 'Donut',
        value: 'doughnut',
        type: 'doughnut',
        family: 'pies',
        requiresDimension: true,
        requiresBreakBy: false,
        breakable: false,
        xAxis: {
            enable: false,
            multiple: false,
            dimensionAvailable: false,
            metricsAvailable: false,
        },
        yAxis: {
            enable: false,
            multiple: false,
            dimensionAvailable: false,
            metricsAvailable: false,
        },
        colorPalette: true,
        orientation: 'vertical',
        icon: 'img/charts/doughnut-chart.svg',
        conditions: {
            dimension: { min: 1, max : 1 },
            metrics: { min: 1, max: 1 },
            breaker: { max: 0 }
        }
    }, {
        label: 'Pie',
        value: 'pie',
        type: 'pie',
        family: 'pies',
        requiresDimension: true,
        requiresBreakBy: false,
        breakable: false,
        xAxis: {
            enable: false,
            multiple: false,
            dimensionAvailable: false,
            metricsAvailable: false,
        },
        yAxis: {
            enable: false,
            multiple: false,
            dimensionAvailable: false,
            metricsAvailable: false,
        },
        colorPalette: true,
        icon: 'img/charts/pie-chart.svg',
        conditions: {
            dimension: { min: 1, max : 1 },
            metrics: { min: 1, max: 1 },
            breaker: { max: 0 }
        }
    }, {
        label: 'Radar',
        value: 'radar',
        type: 'radar',
        family: 'pies',
        requiresDimension: true,
        requiresBreakBy: false,
        breakable: true,
        xAxis: {
            enable: true,
            multiple: false,
            dimensionAvailable: false,
            metricsAvailable: false,
            orientation: 'horizontal'
        },
        yAxis: {
            enable: false,
            multiple: false,
            dimensionAvailable: false,
            metricsAvailable: false,
        },
        colorPalette: false,
        icon: 'img/charts/radar-chart.svg',
        conditions: {
            dimension: { min: 1, max : 1 },
            metrics: { min: 1 },
            breaker: { max: 0 }
        }
    }, {
        label: 'Funnel',
        value: 'funnel',
        type: 'funnel',
        family: 'funnel',
        requiresDimension: true,
        requiresBreakBy: false,
        breakable: false,
        xAxis: {
            enable: false,
            multiple: false,
            dimensionAvailable: false,
            metricsAvailable: false,
        },
        yAxis: {
            enable: false,
            multiple: false,
            dimensionAvailable: false,
            metricsAvailable: false,
        },
        colorPalette: true,
        orientation: 'vertical',
        funnelAlign: 'center',
        icon: 'img/charts/funnel-chart.svg',
        conditions: {
            dimension: { min: 1, max : 1 },
            metrics: { min: 1, max: 1 },
            breaker: { max: 0 }
        }
    }, {
        label: 'Horizontal Funnel',
        value: 'horizontalFunnel',
        type: 'horizontalFunnel',
        family: 'funnel',
        requiresDimension: true,
        requiresBreakBy: false,
        breakable: false,
        xAxis: {
            enable: false,
            multiple: false,
            dimensionAvailable: false,
            metricsAvailable: false,
        },
        yAxis: {
            enable: false,
            multiple: false,
            dimensionAvailable: false,
            metricsAvailable: false,
        },
        colorPalette: true,
        orientation: 'horizontal',
        funnelAlign: 'center',
        icon: 'img/charts/horizontal-funnel-chart.svg',
        conditions: {
            dimension: { min: 1, max : 1 },
            metrics: { min: 1, max: 1 },
            breaker: { max: 0 }
        }
    }, {
        label: 'Gauges',
        value: 'gauge',
        type: 'gauge',
        family: 'gauges',
        breakable: false,
        requiresDimension: false,
        requiresBreakBy: false,
        xAxis: {
            enable: false,
            multiple: false,
            dimensionAvailable: false,
            metricsAvailable: false,
        },
        yAxis: {
            enable: false,
            multiple: false,
            dimensionAvailable: false,
            metricsAvailable: false,
        },
        colorPalette: false,
        orientation: 'horizontal',
        icon: 'img/charts/gauge-chart.svg',
        conditions: {
            dimension: { min: 0, max: 0 },
            metrics: { min: 1, max: 1 },
            breaker: { max: 0 }
        }
    }, {
        label: 'Stacked 100 Vertical Bars',
        value: 'stacked100Bar',
        type: 'bar',
        family: 'bars',
        orientation: 'vertical',
        requiresDimension: true,
        requiresBreakBy: true,
        breakable: true,
        xAxis: {
            enable: true,
            multiple: false,
            dimensionAvailable: false,
            metricsAvailable: false,
            orientation: 'horizontal'
        },
        yAxis: {
            enable: true,
            multiple: true,
            dimensionAvailable: false,
            metricsAvailable: true,
            orientation: 'vertical'
        },
        colorPalette: true,
        icon: 'img/charts/stacked-vertical-chart-100.svg',
        conditions: {
            dimension: { min: 1, max : 1 },
            metrics: { min: 1, max: 1 },
            breaker: { min: 1 }
        }
    }, {
        label: 'Stacked 100 Horizontal Bars',
        value: 'stacked100HorizontalBar',
        type: 'horizontalBar',
        family: 'bars',
        requiresDimension: true,
        requiresBreakBy: true,
        breakable: true,
        xAxis: {
            enable: true,
            multiple: false,
            dimensionAvailable: false,
            metricsAvailable: false,
            orientation: 'vertical'
        },
        yAxis: {
            enable: true,
            multiple: true,
            dimensionAvailable: false,
            metricsAvailable: true,
            orientation: 'horizontal'
        },
        colorPalette: true,
        orientation: 'horizontal',
        icon: 'img/charts/stacked-horizontal-chart-100.svg',
        conditions: {
            dimension: { min: 1, max : 1 },
            metrics: { min: 1, max: 1 },
            breaker: { min: 1 }
        }
    }, {
        label: 'Scatter',
        value: 'scatter',
        type: 'scatter',
        family: 'scatters',
        orientation: 'vertical',
        requiresDimension: true,
        requiresBreakBy: false,
        breakable: true,
        allowComplements: true,
        xAxis: {
            enable: true,
            multiple: false,
            dimensionAvailable: true,
            metricsAvailable: true,
            orientation: 'horizontal'
        },
        yAxis: {
            enable: true,
            multiple: false,
            dimensionAvailable: false,
            metricsAvailable: true,
            orientation: 'vertical'
        },
        colorPalette: true,
        icon: 'img/charts/scatter-chart.svg',
        conditions: {
            dimension: { min: 1, max : 1 },
            metrics: { min: 1, max: 1 },
            breaker: { max: 1 },
            complements: { min: 0 }
        }
    },
    {
        label: 'Bubbles',
        value: 'bubbles',
        type: 'scatter',
        family: 'scatters',
        orientation: 'vertical',
        requiresDimension: true,
        requiresBreakBy: false,
        requiresRadius: true,
        breakable: true,
        allowComplements: true,
        xAxis: {
            enable: true,
            multiple: false,
            dimensionAvailable: true,
            metricsAvailable: true,
            orientation: 'horizontal'
        },
        yAxis: {
            enable: true,
            multiple: false,
            dimensionAvailable: false,
            metricsAvailable: true,
            orientation: 'vertical'
        },
        colorPalette: true,
        icon: 'img/charts/bubble-chart.svg',
        conditions: {
            dimension: { min: 1, max : 1 },
            metrics: { min: 1, max: 1 },
            breaker: { max: 1 },
            radius: { min: 1, max: 1 },
            complements: { min: 0 }
        },
    },
    {
        label: 'Bullet',
        value: 'bullet',
        type: 'bullet',
        family: 'bullet',
        breakable: false,
        requiresDimension: true,
        requiresBreakBy: false,
        requiresThreshold: true,
        xAxis: {
            enable: false,
            multiple: false,
            dimensionAvailable: false,
            metricsAvailable: false,
        },
        yAxis: {
            enable: false,
            multiple: false,
            dimensionAvailable: false,
            metricsAvailable: false,
        },
        colorPalette: false,
        orientation: 'horizontal',
        icon: 'img/charts/bullet-chart.svg',
        conditions: {
            dimension: { min: 0, max: 1 },
            metrics: { min: 1, max: 1 },
            threshold: { min: 0, max: 1 },
            breaker: { max: 0 }
        }
    },
    {
        label: 'Treemap',
        value: 'treemap',
        type: 'treemap',
        family: 'tree',
        breakable: true,
        requiresDimension: true,
        requiresBreakBy: false,
        requiresThreshold: false,
        xAxis: {
            enable: false,
            multiple: false,
            dimensionAvailable: false,
            metricsAvailable: false,
        },
        yAxis: {
            enable: false,
            multiple: false,
            dimensionAvailable: false,
            metricsAvailable: false,
        },
        colorPalette: true,
        orientation: 'horizontal',
        icon: 'img/charts/treemap-chart.svg',
        conditions: {
            dimension: { min: 1, max: 1 },
            metrics: { min: 1, max: 1 },
            threshold: { max: 0 },
            breaker: { max: 1 }
        }
    },
    {
        label: 'Heatmap',
        value: 'heatmap',
        type: 'heatmap',
        family: 'heatmap',
        requiresDimension: true,
        requiresBreakBy: false,
        requiresRadius: false,
        requiresHeatmap: true,
        breakable: false,
        xAxis: {
            enable: true,
            multiple: false,
            dimensionAvailable: true,
            metricsAvailable: false,
            orientation: 'horizontal'
        },
        yAxis: {
            enable: true,
            multiple: false,
            dimensionAvailable: true,
            metricsAvailable: false,
            orientation: 'vertical'
        },
        colorPalette: true,
        icon: 'img/charts/heatmap-chart.svg',
        conditions: {
            dimension: { min: 1, max : 1 },
            metrics: { min: 1, max: 1 },
            breaker: { min: 1, max: 1 }
        },
    }
];

// Axis style options section
export const ticksDirection = [
    {
        label: 'cards.chartConfig.tickDirections.auto',
        value: ''
    }, {
        label: 'cards.chartConfig.tickDirections.horizontal',
        value: 'horizontal'
    }, {
        label: 'cards.chartConfig.tickDirections.vertical',
        value: 'vertical'
    }, {
        label: 'cards.chartConfig.tickDirections.slanting',
        value: 'slanting'
    }
];

// Grid line styles
export const gridLineStyles = [
    { label: 'cards.chartConfig.gridLineStyles.solid', value: '0,0' },
    { label: 'cards.chartConfig.gridLineStyles.dashed', value: '5,10' },
    { label: 'cards.chartConfig.gridLineStyles.dotted', value: '2,3' }
];


// Line style options section
export const lineTensionOptions = [
    {
        label: 'cards.chartConfig.lineTension.none',
        value: 0
    },
    {
        label: 'cards.chartConfig.lineTension.soft',
        value: 0.1
    },
    {
        label: 'cards.chartConfig.lineTension.moderated',
        value: 0.4
    }
];

// Point styles
export const pointStyleOptions = [
    {
        label: 'cards.chartConfig.pointStyles.circle',
        value: 'circle'
    },
    {
        label: 'cards.chartConfig.pointStyles.triangle',
        value: 'triangle'
    },
    {
        label: 'cards.chartConfig.pointStyles.rect',
        value: 'rect'
    },
    {
        label: 'cards.chartConfig.pointStyles.rectRounded',
        value: 'rectRounded'
    },
    {
        label: 'cards.chartConfig.pointStyles.rectRot',
        value: 'rectRot'
    },
    {
        label: 'cards.chartConfig.pointStyles.cross',
        value: 'cross'
    },
    {
        label: 'cards.chartConfig.pointStyles.crossRot',
        value: 'crossRot'
    },
    {
        label: 'cards.chartConfig.pointStyles.line',
        value: 'line'
    }
];

export const borderStylesOptions = [
    { label: 'cards.chartConfig.gridLineStyles.solid', value: 'solid' },
    { label: 'cards.chartConfig.gridLineStyles.dashed', value: 'dashed' },
    { label: 'cards.chartConfig.gridLineStyles.dotted', value: 'dotted' }
];

export const complementsOptions = [{
    label: 'cards.chartConfig.complementsConfig.line', value: 'line'
}, {
    label: 'cards.chartConfig.complementsConfig.area', value: 'area'
}];

/**
 * Returns chart info object from a specific chart type from catalog with all data related (type, family, orientation, etc)
 * @param {String} name Name of the chart in the chartCatalog
 * @returns {Object | null} chart info as JSON object
 */
export const getChartFromCatalog = (name) => {
    let chart = find(chartCatalog, { value: name });
    if (chart) {
        return chart;
    } else return null;
}

export default {
    DATE_AND_DATETIME_DATA_TYPES,
    BOOLEAN_DATA_TYPES,
    NUMERIC_DATA_TYPES,
    MAX_LONG_LABEL,
    STACKED_TOTAL_SERIE_NAME,
    SCATTER_CHART_RADIUS_KEY,
    BREAK_BY_UNDEFINED_VALUE,
    DEFAULT_LABELS_FONT_STYLE,
    CHART_TYPES_WITH_PCT_IN_TOOLTIP,
    CHART_TYPES_WITH_SLICES_LIMIT_AVAILABLE,
    CHART_TYPES_WITH_BORDER,
    HORIZONTAL_BAR_CHART_TYPES,
    DEFAULT_BORDER_COLOR,
    DEFAULT_BORDER_WIDTH,
    DEFAULT_BORDER_MAX_WIDTH,
    DEFAULT_BORDER_WIDTH_STEP_SIZE,
    DEFAULT_BORDER_RADIUS,
    DEFAULT_BORDER_MAX_RADIUS,
    DEFAULT_BORDER_RADIUS_STEP_SIZE,
    DEFAULT_BORDER_POSITION,
    DEFAULT_BORDER_STYLE,
    DEFAULT_HEATMAP_COLOR,
    GAUGE_STRUCTURE_CONFIG,
    DEFAULT_HORIZONTAL_GAUGE_DATALABEL_POSITION,
    DEFAULT_GAUGE_AXIS_LABEL_POSITION,
    DEFAULT_GAUGE_BACKGROUND_COLOR,
    DEFAULT_GAUGE_WIDTH,
    DEFAULT_GAUGE_MIN_VALUE,
    DEFAULT_GAUGE_MAX_VALUE,
    DEFAULT_GAUGE_START_ANGLE,
    DEFAULT_GAUGE_END_ANGLE,
    DEFAULT_GAUGE_CLOCKWISE,
    DEFAULT_AXIS_LINE_STYLE,
    DEFAULT_NUMBER_TYPE,
    DEFAULT_NUMBER_FORMAT,
    MIN_VISUALIZATION_ASPECT_RATIO,
    DEFAULT_BULLET_BACKGROUND_COLOR,
    DEFAULT_BULLET_MIN_VALUE,
    DEFAULT_BULLET_MAX_VALUE,
    DEFAULT_BULLET_WIDTH,
    DEFAULT_BULLET_AXIS_LABEL_POSITION,
    DEFAULT_BULLET_THRESHOLD_WIDTH,
    DEFAULT_BULLET_THRESHOLD_SIZE,
    DEFAULT_HORIZONTAL_BULLET_DATALABEL_POSITION,
    TREEMAP_MIN_BORDER_WIDTH,
    TREEMAP_MAX_BORDER_WIDTH,
    DEFAULT_TREEMAP_BORDER_WIDTH,
    DEFAULT_SLICES_NUMBER,
    DEFAULT_TREEMAP_UPPERLABEL_BACKGROUND_COLOR,
    DEFAULT_LINE_WIDTH,
    DEFAULT_LINE_TENSION,
    addNameGap,
    legendGapSymbol,
    chartCatalog,
    ticksDirection,
    gridLineStyles,
    lineTensionOptions,
    pointStyleOptions,
    getChartFromCatalog,
    borderStylesOptions,
    complementsOptions
};