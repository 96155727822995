import { useDispatch } from 'react-redux';
import request from "@biuwer/common/src/libs/superagent";
import { EventLogger } from '@biuwer/core/src/event-logger';

import Auth from "@biuwer/redux/src/system/auth/auth-lib";
import History from "@biuwer/core/src/history";
import i18n from "@biuwer/core/src/i18n";
import gqlRequest from '@biuwer/core/src/graphql-request';

import { addNotification } from "@biuwer/redux/src/system/notifications/notifications-actions";

import { defaultContext } from "@biuwer/redux/src/config/constants";
import { CARDS_DETAIL, CARDS_LIST, SPACE_CARDS_LIST, CARD_DETAIL } from "./cards-gql";
import { DATAFILTERS } from "../../data/filters/filters-gql";
import { cleanCardBeforeUpdate } from "@biuwer/cards/src/clean-card-before-update-lib";

/*
 * Cards action types
 */
export const CARDS_INITIALIZE = 'CARDS_INITIALIZE';
export const CARD_LIST_INITIALIZE = 'CARD_LIST_INITIALIZE';
export const CARD_DETAIL_INITIALIZE = 'CARD_DETAIL_INITIALIZE';

export const CARDS_FETCH_REQUEST = 'CARDS_FETCH_REQUEST';
export const CARDS_FETCH_SUCCESS = 'CARDS_FETCH_SUCCESS';
export const CARDS_FETCH_ERROR = 'CARDS_FETCH_ERROR';

export const CARD_FETCH_REQUEST = 'CARD_FETCH_REQUEST';
export const CARD_FETCH_SUCCESS = 'CARD_FETCH_SUCCESS';
export const CARD_FETCH_ERROR = 'CARD_FETCH_ERROR';

export const CARD_UPDATE_REQUEST = 'CARD_UPDATE_REQUEST';
export const CARD_UPDATE_SUCCESS = 'CARD_UPDATE_SUCCESS';
export const CARD_UPDATE_ERROR = 'CARD_UPDATE_ERROR';
export const CARD_UPDATE_META = 'CARD_UPDATE_META';

export const CARD_MOVE_REQUEST = 'CARD_MOVE_REQUEST';
export const CARD_MOVE_SUCCESS = 'CARD_MOVE_SUCCESS';
export const CARD_MOVE_ERROR = 'CARD_MOVE_ERROR';

export const CARD_DUPLICATE_REQUEST = 'CARD_DUPLICATE_REQUEST';
export const CARD_DUPLICATE_SUCCESS = 'CARD_DUPLICATE_SUCCESS';
export const CARD_DUPLICATE_ERROR = 'CARD_DUPLICATE_ERROR';

export const CARD_CREATE_REQUEST = 'CARD_CREATE_REQUEST';
export const CARD_CREATE_SUCCESS = 'CARD_CREATE_SUCCESS';
export const CARD_CREATE_ERROR = 'CARD_CREATE_ERROR';

export const CARD_DELETE_REQUEST = 'CARD_DELETE_REQUEST';
export const CARD_DELETE_SUCCESS = 'CARD_DELETE_SUCCESS';
export const CARD_DELETE_ERROR = 'CARD_DELETE_ERROR';

export const CARD_FILTERS_UPSERT_REQUEST = 'CARD_FILTERS_UPSERT_REQUEST';
export const CARD_FILTERS_UPSERT_SUCCESS = 'CARD_FILTERS_UPSERT_SUCCESS';
export const CARD_FILTERS_UPSERT_ERROR = 'CARD_FILTERS_UPSERT_ERROR';

export const CARD_FILTERS_CLEAN_REQUEST = 'CARD_FILTERS_CLEAN_REQUEST';
export const CARD_FILTERS_CLEAN_SUCCESS = 'CARD_FILTERS_CLEAN_SUCCESS';
export const CARD_FILTERS_CLEAN_ERROR = 'CARD_FILTERS_CLEAN_ERROR';

// Generic Returns Skeleton
const generateSkeleton = (type, body, context = defaultContext, extraArgs, showNotification = true) => {
    let skeleton = { type, context }, notification;
    const history = History.getHistory();

    switch(type.substring(type.lastIndexOf('_') + 1, type.length)){
        case 'REQUEST':
            skeleton = {
                ...skeleton,
                isFetching: true,
                issue: false
            };

            break;
        case 'ERROR':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: true,
                issuePayload: {
                    status: body.status,
                    code: body.statusCode,
                    message: body.message
                }
            };

            break;
        case 'SUCCESS':
            skeleton = {
                ...skeleton,
                isFetching: false,
                issue: body.status === 2,
                payload: body
            };

            if(body.status === 2){
                skeleton.issuePayload = {
                    status: body.status,
                    // code: body.issuePayload.code,
                    message: body.message
                }
            }
            break;
        default:
            break;
    }

    switch(type){
        case CARD_CREATE_SUCCESS:
            skeleton.created = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.createSuccess', { name: i18n.t('cards.cardsLabel'), context: 'female', count: 1 })
            };
            // Log create card event
            let eventDetail = {
                _id: body._id,
                name: body.name,
                card_type: body.card_type
            };
            EventLogger('event', 'content', 'cards', 'card_created', eventDetail);
            break;
        case CARD_UPDATE_REQUEST:
            skeleton.pageId = extraArgs.pageId;
            skeleton.cardId = extraArgs.cardId;
            break;
        case CARD_UPDATE_SUCCESS:
            skeleton.pageId = extraArgs.pageId;
            skeleton.cardId = extraArgs.cardId;
            skeleton.updated = true;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.updateSuccess', { name: i18n.t('cards.cardsLabel'), context: 'female', count: 1 })
            };
            break;
        case CARD_UPDATE_ERROR:
            skeleton.pageId = extraArgs.pageId;
            skeleton.cardId = extraArgs.cardId;
            notification = {
                styleType: 'error',
                message: i18n.t('notifications.error')
            };
            break;
        case CARD_UPDATE_META:
            skeleton.meta = body;
            break;
        case CARD_DELETE_SUCCESS:
            skeleton.deleted = true;
            skeleton.cardId = extraArgs.cardId;
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.deleteSuccess', { name: i18n.t('cards.cardsLabel'), context: 'female', count: 1 })
            };
            break;
        case CARD_FETCH_ERROR:
            skeleton.cardId = extraArgs.cardId;
            break;
        case CARD_FILTERS_UPSERT_REQUEST:
            skeleton.cardId = extraArgs.cardId;
            break;
        case CARD_FILTERS_UPSERT_SUCCESS:
            skeleton.cardId = extraArgs.cardId;
            break;
        case CARD_FILTERS_UPSERT_ERROR:
            skeleton.cardId = extraArgs.cardId;
            break;
        case CARD_MOVE_SUCCESS:
            notification = {
                styleType: 'success',
                message: i18n.t('notifications.moveSuccess', { name: i18n.t('cards.cardsLabel'), context: 'female', count: 1 })
            };
            break;
        case CARD_DUPLICATE_SUCCESS:
            notification = {
                styleType: 'success',
                action: {
                    onClick: () => { history.push(`/cards/${body._id}`) },
                    text: i18n.t('common.seeObject', { object: i18n.t('cards.cardsLabel') })
                },
                message: i18n.t('notifications.duplicateSuccess', { name: i18n.t('cards.cardsLabel'), context: 'female', count: 1 })
            };
            break;
        case CARD_CREATE_ERROR:
        case CARD_DELETE_ERROR:
        case CARD_MOVE_ERROR:
        case CARD_DUPLICATE_ERROR:
            notification = {
                styleType: 'error',
                message: i18n.t('notifications.error')
            };
            break;
        default:
            break;
    }

    return (dispatch) => {

        dispatch(skeleton);

        if (notification && showNotification) {
            dispatch(addNotification(notification));
        }
    };
};

/**
 * Initialize Card
 */
export const initializeCards = (context = defaultContext, baseContext) => {
    return {
        type: CARDS_INITIALIZE,
        context,
        baseContext
    };
};

export const initializeDetailCard = (context = defaultContext) => {
    return {
        type: CARD_DETAIL_INITIALIZE,
        context
    }
};

export const initializeListCard = (context = defaultContext) => {
    return {
        type: CARD_LIST_INITIALIZE,
        context
    }
};

export const getCard = (cardId, context = defaultContext, gql = CARDS_DETAIL) => {
    return async dispatch => {

        dispatch(generateSkeleton(CARD_FETCH_REQUEST, null, context));

        try {
            const card = await gqlRequest({
                queryName: "readCard",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "Float!",
                    name: "_id",
                    data: Number(cardId)
                }]
            });

            dispatch(generateSkeleton(CARD_FETCH_SUCCESS, card, context));
        } catch (err) {
            dispatch(generateSkeleton(CARD_FETCH_ERROR, err, context, { cardId }));
        }
    }
};

export const queryCards = (query = {}, context = defaultContext, gql = CARDS_LIST) => {
    return async dispatch => {
        try {
            dispatch(generateSkeleton(CARDS_FETCH_REQUEST, null, context));

            const cards = await gqlRequest({
                queryType: "query",
                queryName: "readCards",
                token: Auth.getLocalJwt(),
                queryGql: gql,
                variables: [{
                    name: "query",
                    type: "QueryCardInput!",
                    data: query
                }]
            })

            dispatch(generateSkeleton(CARDS_FETCH_SUCCESS, cards, context))
        } catch (err) {
            dispatch(generateSkeleton(CARDS_FETCH_ERROR, err, context));
        }
    }
};

export const queryPageCards = (query = {}, context = defaultContext, gql = CARDS_LIST) => {
    return async dispatch => {
        try {
            const gqlQuery = {
                query: `query($query: QueryCardInput!) {
                    readCards(query: $query) {
                        ${gql}
                    }
                }`,
                variables: {
                    query: query
                }
            };

            const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(CARDS_FETCH_REQUEST, null, context));

            const res = await request
                .post('/api/gql/')
                .send(gqlQuery)
                .set('Authorization', `Bearer ${token}`)
                .set("app", Auth.getAuthApp())
                .on('response', (response) => Auth.checkResponse(response));

            if (res) {
                if (res.body && res.body.data && res.body.data.readCards) {
                    res.body.data.readCards.forEach((card, index) => {
                        if (res.body.errors && res.body.errors.length > 0) {
                            res.body.errors.forEach(error => {
                                if (error.path && error.path.length > 1 && error.path[0] === "readCards" && error.path[1] === index) {
                                    card.error = true;
                                }
                            });
                        }
                        dispatch(generateSkeleton(CARD_FETCH_SUCCESS, card, `${context}_${card._id}`));
                    });
                    dispatch(generateSkeleton(CARDS_FETCH_SUCCESS, [], context));
                } else if (res.body.errors && res.body.errors.length > 0) {
                    dispatch(generateSkeleton(CARDS_FETCH_ERROR, res.body.errors[0], context));
                }
            }
        } catch (err) {
            dispatch(generateSkeleton(CARDS_FETCH_ERROR, err, context));
        }
    }
};

function mapCardInput(card) {
    return {
        name: card.name,
        desc: card.desc,
        theme: card.theme,
        card_type: card.card_type,
        data_card: card.data_card,
        ...card.space_id && { space_id: card.space_id._id || card.space_id },
        multi_query: card.multi_query,
        ...card.height && { height: Number(card.height) },
        ...card.width && { width: Number(card.width) },
        show_always_action_menu: card?.show_always_action_menu || false,
        show_title: card.show_title,
        show_footer: card.show_footer,
        show_row_limit_warning: card.show_row_limit_warning,
        title: card.title,
        auto_apply_filters: card.auto_apply_filters,
        queries: card.queries,
        query_relations_type: card.query_relations_type,
        query_relations: card.query_relations,
        query_combinations_main_query: card.query_combinations_main_query,
        query_combinations: card.query_combinations,
        calculated_fields: card.calculated_fields,
        filters: card.filters,
        actions: card.actions,
        visualization: card.visualization,
        customCard: card.customCard,
        local_datasets: card.local_datasets,
        i18n: card.i18n,
        filters_config: card.filters_config
    };
}

/**
 * Create space action
 */
export const createCard = (card, context = defaultContext, gql = CARDS_DETAIL) => {
    return async dispatch => {

        card = cleanCardBeforeUpdate(card);

        dispatch(generateSkeleton(CARD_CREATE_REQUEST, null, context));

        try {
            const createdCard = await gqlRequest({
                queryType: "mutation",
                queryName: "createCard",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "CardInput!",
                    name: "card",
                    data: mapCardInput(card)
                }]
            });

            dispatch(generateSkeleton(CARD_CREATE_SUCCESS, createdCard, context));
        } catch (err) {
            dispatch(generateSkeleton(CARD_CREATE_ERROR, err, context));
        }
    };
};

export const updateCard = (card, context = defaultContext, gql = CARDS_DETAIL) => {
    return async dispatch => {
        try {
            card = cleanCardBeforeUpdate(card);

            dispatch(generateSkeleton(CARD_UPDATE_REQUEST, null, context, { cardId: card._id }));

            const updatedCard = await gqlRequest({
                queryType: "mutation",
                queryName: "updateCard",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "Float!",
                    name: "_id",
                    data: card._id
                }, {
                    type: "CardInput!",
                    name: "card",
                    data: mapCardInput(card)
                }]
            });

            dispatch(generateSkeleton(CARD_UPDATE_SUCCESS, updatedCard, context, { cardId: card._id }));
        } catch (err) {
            dispatch(generateSkeleton(CARD_UPDATE_ERROR, err, context, { cardId: card._id }));
        }
    }
};

/**
 * Duplicate Card action
 */
export const duplicateCard = (cardId, options, context = defaultContext, gql = CARDS_DETAIL) => {
    return async dispatch => {
        try {

            // const token = Auth.getLocalJwt();
            dispatch(generateSkeleton(CARD_DUPLICATE_REQUEST, null, context, { cardId }));

            const duplicatedCard = await gqlRequest({
                queryType: "mutation",
                queryName: "duplicateCard",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "Float!",
                    name: "_id",
                    data: Number(cardId)
                }]
            });

            dispatch(generateSkeleton(CARD_DUPLICATE_SUCCESS, duplicatedCard, context, { cardId }));
        } catch (err) {
            dispatch(generateSkeleton(CARD_DUPLICATE_ERROR, err, context, { cardId }));
        }
    };
};

/**
 * Move Card action
 */
export const moveCard = (cardId, options, context = defaultContext, gql = CARDS_DETAIL) => {
    return async dispatch => {
        try {
            dispatch(generateSkeleton(CARD_MOVE_REQUEST, null, context, { cardId }));

            const movedCard = await gqlRequest({
                queryType: "mutation",
                queryName: "moveCard",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "Float!",
                    name: "_id",
                    data: Number(cardId)
                }, {
                    type: "Float!",
                    name: "newParent",
                    data: Number(options.newParentId)
                }]
            });

            dispatch(generateSkeleton(CARD_MOVE_SUCCESS, movedCard, context, { cardId }));
        } catch (err) {
            dispatch(generateSkeleton(CARD_MOVE_ERROR, err, context, { cardId }));
        }
    };
};

/**
 * Delete card action
 */
export const deleteCard = (cardId, context = defaultContext, gql = CARDS_DETAIL) => {
    return async dispatch => {
        try {

            dispatch(generateSkeleton(CARD_DELETE_REQUEST, null, context));

            const deletedCard = await gqlRequest({
                queryType: "mutation",
                queryName: "deleteCard",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "Float!",
                    name: "_id",
                    data: Number(cardId)
                }]
            });

            dispatch(generateSkeleton(CARD_DELETE_SUCCESS, deletedCard, context, {cardId}));
        } catch (err) {
            dispatch(generateSkeleton(CARD_DELETE_ERROR, err, context, { cardId }));
        }
    };
};

/**
 * Upsert card filters action
 */
export const upsertCardFilters = (datafilters, cardId, context = defaultContext, gql = DATAFILTERS) => {
    return async dispatch => {
        try {
            dispatch(generateSkeleton(CARD_FILTERS_UPSERT_REQUEST, null, context, { cardId }));

            if (Auth.getAuthApp() === "share") {

                // Redirect new filters to reducer, don't upsert in bd
                return dispatch(generateSkeleton(CARD_FILTERS_UPSERT_SUCCESS, datafilters, context, {cardId}));
            }

            const upsertCardFiltersResult = await gqlRequest({
                queryType: "mutation",
                queryName: "upsetDataFilters",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "[JSONObject!]!",
                    name: "datafilters",
                    data: datafilters
                }]
            });

            dispatch(generateSkeleton(CARD_FILTERS_UPSERT_SUCCESS, upsertCardFiltersResult, context, { cardId }));
        } catch (err) {
            dispatch(generateSkeleton(CARD_FILTERS_UPSERT_ERROR, err, context, { cardId }));
        }
    };
};

export const cleanCardFilters = (objectType, object, datafilters, cardId, context = defaultContext, gql = DATAFILTERS) => {
    return async dispatch => {
        try {
            dispatch(generateSkeleton(CARD_FILTERS_CLEAN_REQUEST, null, context, { cardId }));

            if (Auth.getAuthApp() === "share") {

                // Redirect new filters to reducer, don't upsert in bd
                return dispatch(generateSkeleton(CARD_FILTERS_CLEAN_SUCCESS, datafilters, context, {cardId}));
            }

            const cleanCardFiltersResult = gqlRequest({
                queryType: "mutation",
                queryName: "cleanDataFilters",
                queryGql: gql,
                token: Auth.getLocalJwt(),
                variables: [{
                    type: "String!",
                    name: "objectType",
                    data: objectType.toLowerCase()
                }, {
                    type: "JSONObject!",
                    name: "object",
                    data: object
                }]
            });

            dispatch(generateSkeleton(CARD_FILTERS_CLEAN_SUCCESS, cleanCardFiltersResult, context, { cardId }));
        } catch (err) {
            dispatch(generateSkeleton(CARD_FILTERS_CLEAN_ERROR, err, context, { cardId }));
        }
    };
};

/**
 * Update card internal metadata
 */
export const updateCardMeta = (cardId, meta, context = defaultContext) => {
    return async dispatch => {
        dispatch(generateSkeleton(CARD_UPDATE_META, meta, context, { cardId }));
    }
};

export const useCardsActions = () => {

    const dispatch = useDispatch();

    return {
        initializeCards: async (context, baseContext) => dispatch(initializeCards(context, baseContext)),
        getSpaceCards: async (spaceId, context, gql = SPACE_CARDS_LIST) => dispatch(queryCards({ space_id: spaceId }, context, gql)),
        createCard: async (card, context, gql = CARD_DETAIL) => dispatch(createCard(card, context, gql)),
        updateCard: async (card, context, gql = SPACE_CARDS_LIST) => dispatch(updateCard(card, context, gql)),
        duplicateCard: async (cardId, context, gql = SPACE_CARDS_LIST) => dispatch(duplicateCard(cardId, null, context, gql)),
        deleteCard: async (cardId, context, gql = SPACE_CARDS_LIST) => dispatch(deleteCard(cardId, context, gql)),
        getCard: async (cardId, context, gql = CARD_DETAIL) => dispatch(getCard(cardId, context, gql))
    };

};