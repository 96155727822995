import { Map, fromJS } from "immutable";
import { defaultContext } from "@biuwer/redux/src/config/constants";
import history from "@biuwer/core/src/history.js"
import { isBiuwerApp } from "@biuwer/common/src/libs/utils-lib.js"

import {
    CARDS_INITIALIZE,
    CARDS_FETCH_REQUEST, CARDS_FETCH_SUCCESS, CARDS_FETCH_ERROR,
    CARD_FETCH_REQUEST, CARD_FETCH_SUCCESS, CARD_FETCH_ERROR,
    CARD_UPDATE_REQUEST, CARD_UPDATE_SUCCESS, CARD_UPDATE_ERROR, CARD_UPDATE_META,
    CARD_DUPLICATE_REQUEST, CARD_DUPLICATE_SUCCESS, CARD_DUPLICATE_ERROR,
    CARD_MOVE_REQUEST, CARD_MOVE_SUCCESS, CARD_MOVE_ERROR,
    CARD_CREATE_REQUEST, CARD_CREATE_SUCCESS, CARD_CREATE_ERROR,
    CARD_DELETE_REQUEST, CARD_DELETE_SUCCESS, CARD_DELETE_ERROR,
    CARD_FILTERS_UPSERT_REQUEST, CARD_FILTERS_UPSERT_SUCCESS, CARD_FILTERS_UPSERT_ERROR,
    CARD_FILTERS_CLEAN_REQUEST, CARD_FILTERS_CLEAN_SUCCESS, CARD_FILTERS_CLEAN_ERROR,
    CARD_DETAIL_INITIALIZE, CARD_LIST_INITIALIZE
} from "./cards-actions.js";

import { PAGE_FETCH_REQUEST, PAGE_FETCH_SUCCESS, PAGE_UPDATE_SUCCESS } from "../pages/pages-actions.js"

import {
    UPDATE_PROFILE_SUCCESS, CHANGE_OWNER_SUCCESS
} from "@biuwer/redux/src/system/security/security-actions";

import {
    RESOURCE_TOKEN_CREATE_SUCCESS, RESOURCE_TOKEN_DELETE_SUCCESS
} from "@biuwer/redux/src/share/resource-tokens/resource-tokens-actions";

import {
    DATAFILTERS_UPSERT_REQUEST, DATAFILTERS_UPSERT_SUCCESS, DATAFILTERS_UPSERT_ERROR
} from "@biuwer/redux/src/data/filters/filters-actions"

const initialState = fromJS({
    detail_primary: {},
    list_primary: {}
});

/**
 * Cards Reducer
 */
export default function cards(state = initialState, action) {
    let newState, index, card, listUpdatedPayload, detailUpdatedPayload, filterIndex, cardId = null, isCurrentCard = false;

    // Get list and detail context
    const list = `list_${(action && action.context) || defaultContext}`;
    const detail = `detail_${(action && action.context) || defaultContext}`;

    switch(action.type) {
        case PAGE_FETCH_SUCCESS:
        case PAGE_UPDATE_SUCCESS:

            newState = state
            const location = history.getLocation()
            const pathname = location.pathname
            const isShareApp = !isBiuwerApp()

            const pageId = action?.payload?._id ?? ""

            const isSharePath = isShareApp && (pathname.includes("/share") || pathname.includes(`/pages/${pageId}`))
            const isBiuwerPath = !isShareApp && (pathname.includes(`/pages/${pageId}`) || pathname.includes(`/collections/`))

            if (isSharePath || isBiuwerPath) {
                action.payload?.cards?.forEach(card => {
                    newState = newState
                        .mergeIn([`${detail}_${card._id}`], Map({
                            isFetching: action.isFetching,
                            issue: action.issue,
                            payload: fromJS(card),
                            loaded: true
                        }))
                })
                newState = newState
                    .mergeIn([list], Map({
                        isFetching: action.isFetching,
                        issue: action.issue,
                        payload: fromJS([]),
                        loaded: true
                    }))
            }
            return newState
        case CARDS_INITIALIZE:
            // Initialize all matching contexts
            if (action.baseContext) {
                newState = state;
                state.mapKeys(key => {
                    if (key.indexOf(action.baseContext) !== -1) newState = newState.setIn([key], Map({}));
                });
                return newState;
            }
            newState = state
                .setIn([detail], Map({}))
                .setIn([list], Map({}));

            return newState;
        case CARD_DETAIL_INITIALIZE:
            newState = state
                .setIn([detail], Map({}));
            return newState;
        case CARD_LIST_INITIALIZE:
            newState = state
                .setIn([list], Map({}));
            return newState;
        case PAGE_FETCH_REQUEST:
        case CARDS_FETCH_REQUEST:

            newState = state;

            // Delete created, update, deleted keys if exists
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);
            if (state.getIn([list, 'deleted'])) newState = newState.removeIn([list, 'deleted']);

            newState = newState
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    loaded: false
                }));

            return newState;
        case CARDS_FETCH_SUCCESS:
            newState = state
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    payload: fromJS(action.payload),
                    loaded: true
                }));

            return newState;
        case CARDS_FETCH_ERROR:
            newState = state
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload),
                    loaded: true
                }));

            return newState;
        case CARD_FETCH_REQUEST:

            newState = state;

            // Delete created, update, deleted keys if exists
            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([detail, 'deleted'])) newState = newState.removeIn([detail, 'deleted']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }));

            return newState;
        case CARD_FETCH_SUCCESS:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    payload: fromJS(action.payload)
                }));

            return newState;
        case CARD_FETCH_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }));

            return newState;
        case CARD_CREATE_REQUEST:

            newState = state;

            if (state.getIn([detail, 'created'])) newState = newState.removeIn([detail, 'created']);
            if (state.getIn([list, 'created'])) newState = newState.removeIn([list, 'created']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }));

            return newState;
        case CARD_CREATE_SUCCESS:

            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    created: action.created,
                    payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {
                listUpdatedPayload = state.getIn([list, 'payload']);
                listUpdatedPayload = listUpdatedPayload.push(fromJS(action.payload));

                newState = newState
                    .mergeIn([list], Map({
                        isFetching: action.isFetching,
                        error: action.error,
                        created: action.created,
                        payload: listUpdatedPayload
                    }));
            }

            return newState;
        case CARD_CREATE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }));

            return newState;
        case CARD_UPDATE_REQUEST:

            newState = state;

            // Check if "delete" key exists
            if (state.getIn([detail, 'delete'])) newState = newState.removeIn([detail, 'delete']);
            if (state.getIn([list, 'delete'])) newState = newState.removeIn([list, 'delete']);

            // Check if "updated" key exists
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }));

            return newState;
        case CARD_UPDATE_SUCCESS:

            // Update payload with updated card
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    updated: action.updated,
                    payload: detailUpdatedPayload
                }));

            if (action && action.context && action.context === 'dataCardEditor' && newState.get(`detail_cardWrapper_${action.cardId}`)) {
                newState = newState
                    .mergeIn([`detail_cardWrapper_${action.cardId}`], Map({
                        isFetching: action.isFetching,
                        issue: action.issue,
                        updated: action.updated,
                        payload: detailUpdatedPayload
                    }));
            }

            if (state.getIn([list, 'payload'])) {

                // Find the user index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex(item => item.get('_id') === action.payload._id);

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.setIn([index], fromJS(action.payload));
                    newState = newState
                        .mergeIn([list], Map({
                            isFetching: action.isFetching,
                            issue: action.issue,
                            updated: action.updated,
                            payload: listUpdatedPayload
                        }));
                }
            }

            return newState;
        case CARD_UPDATE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }));

            return newState;
        case CARD_UPDATE_META:
            newState = state
                .mergeIn([detail], Map(fromJS(action.meta)))
                // .mergeIn([list], Map(action.meta))
                ;
            return newState;
        case CARD_DUPLICATE_REQUEST:
        case CARD_MOVE_REQUEST:

            newState = state;

            // Check if "delete" key exists
            if (state.getIn([detail, 'delete'])) newState = newState.removeIn([detail, 'delete']);
            if (state.getIn([list, 'delete'])) newState = newState.removeIn([list, 'delete']);

            // Check if "updated" key exists
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);

            newState = newState
                .mergeIn([detail], { isFetching: action.isFetching })
                .mergeIn([list], { isFetching: action.isFetching });
            return newState;
        case CARD_DUPLICATE_SUCCESS:

            // Update payload with updated page
            // detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    updated: action.updated,
                    // payload: detailUpdatedPayload
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the user index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex(item => item.get('_id') === action.payload._id);

                if (index !== -1) {
                    listUpdatedPayload = listUpdatedPayload.setIn([index], fromJS(action.payload));
                } else {
                    listUpdatedPayload = listUpdatedPayload.push(fromJS(action.payload));
                }

                newState = newState
                    .mergeIn([list], Map({
                        isFetching: action.isFetching,
                        issue: action.issue,
                        updated: action.updated,
                        payload: listUpdatedPayload
                    }));
            }

            return newState;
        case CARD_MOVE_SUCCESS:

            newState = state;

            if (state.getIn([detail, 'payload', '_id']) === action.payload._id) {
                detailUpdatedPayload = Map({});
                newState = newState
                    .mergeIn([detail], {
                        isFetching: action.isFetching,
                        issue: action.issue,
                        payload: detailUpdatedPayload
                    });
            }

            if (state.getIn([list, 'payload'])) {
                listUpdatedPayload = state.getIn([list, 'payload']).filter(page => page.get('_id') !== action.payload._id);
                newState = newState
                    .mergeIn([list], {
                        isFetching: action.isFetching,
                        issue: action.issue,
                        payload: listUpdatedPayload
                    });
            }

            return newState;
        case CARD_DUPLICATE_ERROR:
        case CARD_MOVE_ERROR:
            newState = state
                .mergeIn([list], {
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                })
                .mergeIn([detail], {
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                });
            return newState;
        case CARD_DELETE_REQUEST:

            newState = state;

            // Check if "delete" key exists
            if (state.getIn([detail, 'delete'])) newState = newState.removeIn([detail, 'delete']);
            if (state.getIn([list, 'delete'])) newState = newState.removeIn([list, 'delete']);

            // Check if "updated" key exists
            if (state.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);
            if (state.getIn([list, 'updated'])) newState = newState.removeIn([list, 'updated']);

            newState = newState
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue
                }));

            return newState;
        case CARD_DELETE_SUCCESS:

            // Update payload with deleted data connection
            detailUpdatedPayload = fromJS(action.payload);

            newState = state
                .mergeIn([detail], Map({
                    payload: detailUpdatedPayload,
                    isFetching: action.isFetching,
                    issue: action.issue,
                    deleted: action.deleted
                }));

            if (state.getIn([list, 'payload'])) {

                // Find the user index to be updated inside the List
                listUpdatedPayload = state.getIn([list, 'payload']);
                index = listUpdatedPayload.findIndex(item => item.get('_id') === action.payload._id);

                if (index >= 0) {
                    listUpdatedPayload = listUpdatedPayload.removeIn([index]);

                    newState = newState
                        .mergeIn([list], {
                            payload: listUpdatedPayload,
                            isFetching: action.isFetching,
                            issue: action.issue,
                            deleted: action.deleted
                        });
                }
            }

            return newState;
        case CARD_DELETE_ERROR:
            newState = state
                .mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }))
                .mergeIn([list], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }));

            return newState;
        case UPDATE_PROFILE_SUCCESS:
        case CHANGE_OWNER_SUCCESS:

            newState = state;

            // Check objectType before to check if proceed to update reducer data
            if (action.objectType === 'card' && action.payload && action.payload.length > 0) {
                action.payload.forEach(object => {

                    if (String(state.getIn([detail, 'payload', '_id'])) === String(object._id)) {

                        // If payload is an Immutable Map and has the same _id that the action given object info
                        // Check permissions (platform and object profile_level) before update
                        if (object.readOneAuthorization || (!!object.profile_level && object.profile_level <= 4)) {

                            //Enough permissions to read, object updated
                            newState = newState.setIn([detail, 'payload', 'profiles'], fromJS(object.profiles));
                            newState = newState.setIn([detail, 'payload', 'profile_level'], object.profile_level);
                            newState = newState.setIn([detail, 'payload', 'owner'], fromJS(object.owner));
                        } else {

                            // Not enough permissions, delete payload from reducer
                            newState = state.setIn([detail, 'payload'], null);
                        }
                    }

                    if (state.getIn([list, 'payload'])) {
                        let index = state.getIn([list, 'payload']).findIndex(connection => String(connection.get('_id')) === String(object._id));
                        if (object.changed && index !== -1) {

                            // Check permissions of the object profiles updated and platform permissions
                            if (object.readAuthorization || (!!object.profile_level && object.profile_level <= 4)) {

                                // Enough permissions to read, profiles and profile_level is updated
                                newState = newState.setIn([list, 'payload', index, 'profiles'], fromJS(object.profiles));
                                newState = newState.setIn([list, 'payload', index, 'profile_level'], object.profile_level);
                                newState = newState.setIn([list, 'payload', index, 'owner'], fromJS(object.owner));
                            } else {

                                // Not enough permissions after profiles update, exclude object from List
                                newState = state.setIn([list, 'payload'], newState.getIn([list, 'payload']).filter(connection => String(connection.get('_id')) !== String(object._id)));
                            }
                        }
                    }
                });
            }

            return newState;
        case CARD_FILTERS_UPSERT_REQUEST:
        case CARD_FILTERS_CLEAN_REQUEST:

            newState = state;

            // // Recover page from reducer
            // if (List.isList(state.get('payload'))) {
            //     card = state.get('payload').find(card => card.get('_id') === action.cardId);
            // } else if (Map.isMap(state.get('payload'))) {
            //     card = state.getIn(['payload', '_id']) === action.cardId ? state.get('payload') : null
            // }
            //
            // if (!!card) {
            //     card = card.merge(fromJS({ isFetchingCardFilters: action.isFetching, issue: action.issue }));
            //
            //     // Set the newState with updated card info
            //     if (List.isList(state.get('payload'))) {
            //         index = state.get('payload').findIndex(card => card.get('_id') === action.cardId);
            //         if (!!index) {
            //             newState = state.set('payload', state.get('payload').set(index, card));
            //         } else {
            //             newState = state;
            //         }
            //     } else if (Map.isMap(state.get('payload')) && state.getIn(['payload', '_id']) === action.cardId) {
            //         newState = state.set('payload', card);
            //     } else {
            //         newState = state;
            //     }
            //
            // } else {
            //     newState = state
            //         .remove('isFetchingCardFilters')
            //         .remove('issueCardFilters')
            //         .remove('issuePayloadCardFilters')
            //         .remove('isUpdated')
            //         .merge({
            //             isFetchingCardFilters: action.isFetching,
            //             issueCardFilters: action.issue
            //         });
            // }

            return newState;
        case CARD_FILTERS_UPSERT_SUCCESS:
        case CARD_FILTERS_CLEAN_SUCCESS:

            newState = state;

            if (!!state.getIn([detail, 'payload'])) {
                card = state.getIn([detail, 'payload']);

                if (!!card) {

                    if (card.getIn(['filters'])) {
                        if (Array.isArray(action.payload)) {
                            action.payload.forEach(actionPayload => {
                                filterIndex = card.getIn(['filters']).findIndex(item => item.get('_id') === actionPayload.filter_id);

                                // Update filter in card filters array
                                if (filterIndex !== -1) {
                                    card = card.setIn(['filters'], card.getIn(['filters']).update(filterIndex, item => item.set('expression', fromJS(actionPayload.expression))));
                                }
                            });
                        } else {
                            filterIndex = card.getIn(['filters']).findIndex(item => item.get('_id') === action.payload.filter_id);

                            // Update the filter in page filters array
                            if (filterIndex !== -1) {
                                card = card.setIn(['filters'], card.getIn(['filters']).update(filterIndex, item => item.set('expression', fromJS(action.payload.expression))));
                            }
                        }
                    }

                    card = card.merge(fromJS({
                        isFetchingPageFilters: action.isFetching,
                        issuePageFilters: action.issue,
                        isUpdated: true
                    }));

                    newState = newState.setIn([detail, 'payload'], card);
                }
            }

            if (!!state.getIn([list, 'payload'])) {
                index = state.getIn([list, 'payload']).findIndex(card => card.get('_id') === action.cardId);
                if (index >= 0) {
                    card = state.getIn([list, 'payload', index]);
                }

                if (!!card) {
                    if (card.getIn(['filters'])) {
                        if (Array.isArray(action.payload)) {
                            action.payload.forEach(actionPayload => {
                                filterIndex = card.getIn(['filters']).findIndex(item => item.get('_id') === actionPayload.filter_id);

                                // Update the filter in card filters array
                                if (filterIndex !== -1) {
                                    card = card.setIn(['filters'], card.getIn(['filters']).update(filterIndex, item => item.set('expression', fromJS(actionPayload.expression))));
                                }
                            });
                        }
                        else {
                            filterIndex = card.getIn(['filters']).findIndex(item => item.get('_id') === action.payload.filter_id);

                            // Update the filter in card filters array
                            if (filterIndex !== -1) {
                                card = card.setIn(['filters'], card.getIn(['filters']).update(filterIndex, item => item.set('expression', fromJS(action.payload.expression))));
                            }
                        }
                    }
                    card = card.merge(fromJS({
                        isFetchingPageFilters: action.isFetching,
                        issuePageFilters: action.issue,
                        isUpdated: true
                    }));

                    newState = newState.setIn([list, 'payload', index], card);
                }
            }

            newState = newState
                .removeIn([list, 'isFetchingPageFilters'])
                .removeIn([list, 'issuePageFilters'])
                .removeIn([list, 'issuePayloadPageFilters'])
                .mergeIn([list], {
                    isFetchingPageFilters: action.isFetching,
                    issuePageFilters: action.issue,
                    isFetching: action.isFetching,
                    issue: action.issue,
                    updated: action.updated
                })
                .removeIn([detail, 'isFetchingPageFilters'])
                .removeIn([detail, 'issuePageFilters'])
                .removeIn([detail, 'issuePayloadPageFilters'])
                .mergeIn([detail], {
                    isFetchingPageFilters: action.isFetching,
                    issuePageFilters: action.issue,
                    isFetching: action.isFetching,
                    issue: action.issue,
                    updated: action.updated
                });

            return newState;
        case CARD_FILTERS_UPSERT_ERROR:
        case CARD_FILTERS_CLEAN_ERROR:

            // // Recover page from reducer
            // if (List.isList(state.get('payload'))) {
            //     card = state.get('payload').find(card => card.get('_id') === action.cardId);
            // } else if (Map.isMap(state.get('payload'))) {
            //     card = state.getIn(['payload', '_id']) === action.cardId ? state.get('payload') : null
            // }
            //
            // if (!!card) {
            //     card = card.merge(fromJS({
            //         isFetchingCardFilters: action.isFetching,
            //         issueCardFilters: action.issue,
            //         issuePayloadCardFilters: action.issuePayload
            //     }));
            //
            //     // Set the newState with updated card info
            //     if (List.isList(state.get('payload'))) {
            //         index = state.get('payload').findIndex(card => card.get('_id') === action.cardId);
            //         if (!!index) {
            //             newState = state.set('payload', state.get('payload').set(index, card));
            //         } else {
            //             newState = state;
            //         }
            //     } else if (Map.isMap(state.get('payload')) && state.getIn(['payload', '_id']) === action.cardId) {
            //         newState = state.set('payload', card);
            //     } else {
            //         newState = state;
            //     }
            // } else {
            //     newState = state
            //         .remove('isFetchingCardFilters')
            //         .remove('issueCardFilters')
            //         .remove('issuePayloadCardFilters')
            //         .merge({
            //             isFetchingCardFilters: action.isFetching,
            //             issueCardFilters: action.issue,
            //             issuePayloadCardFilters: action.issuePayload
            //         });
            // }

            newState = state;
            return newState;
        case RESOURCE_TOKEN_CREATE_SUCCESS:
            newState = state;
            if (action.resourceType === 'card') {
                detailUpdatedPayload = state.getIn([detail, 'payload']);
                if (detailUpdatedPayload && detailUpdatedPayload.get('_id') === action.resourceId && action.created) {
                    detailUpdatedPayload = detailUpdatedPayload.setIn(['resource_token'], action.payload._id);
                    newState = newState.setIn([detail, 'payload'], detailUpdatedPayload);
                }

                listUpdatedPayload = state.getIn([list, 'payload']);
                if (listUpdatedPayload && listUpdatedPayload.size > 0) {
                    index = listUpdatedPayload.findIndex(page => page.get('_id') === action.resourceId);
                    if (action.created && index !== -1) {
                        listUpdatedPayload = listUpdatedPayload.setIn([index, 'resource_token'], action.payload._id);
                        newState = newState.setIn([list, 'payload'], listUpdatedPayload);
                    }
                }
            }
            return newState;
        case RESOURCE_TOKEN_DELETE_SUCCESS:
            newState = state;
            if (action.resourceType === 'card') {
                detailUpdatedPayload = state.getIn([detail, 'payload']);
                if (detailUpdatedPayload && detailUpdatedPayload.get('_id') === action.resourceId && action.deleted) {
                    detailUpdatedPayload = detailUpdatedPayload.setIn(['resource_token'], null);
                    newState = newState.setIn([detail, 'payload'], detailUpdatedPayload);
                }

                listUpdatedPayload = state.getIn([list, 'payload']);
                if (listUpdatedPayload && listUpdatedPayload.size > 0) {
                    index = listUpdatedPayload.findIndex(page => page.get('_id') === action.resourceId);
                    if (action.deleted && index !== -1) {
                        listUpdatedPayload = listUpdatedPayload.setIn([index, 'resource_token'], null);
                        newState = newState.setIn([list, 'payload'], listUpdatedPayload);
                    }
                }
            }
            return newState;
        case DATAFILTERS_UPSERT_REQUEST:
            if (action.cardId == null) return state
            newState = state;

            cardId = action.cardId
            isCurrentCard = newState.getIn([detail, "payload", "_id"]) === cardId
            if (isCurrentCard) {
                // Check if "delete" key exists
                if (newState.getIn([detail, 'delete'])) newState = newState.removeIn([detail, 'delete']);

                // Check if "updated" key exists
                if (newState.getIn([detail, 'updated'])) newState = newState.removeIn([detail, 'updated']);

                newState = newState
                    .mergeIn([detail], Map({
                        isFetching: action.isFetching,
                        issue: action.issue
                    }));
            }
            return newState
        case DATAFILTERS_UPSERT_SUCCESS:
            if (action.cardId == null) return state
            newState = state;

            cardId = action.cardId
            isCurrentCard = newState.getIn([detail, "payload", "_id"]) === cardId
            if (isCurrentCard) {
                detailUpdatedPayload = newState.getIn([detail, "payload"]).toJS();
                (action.payload ?? []).forEach((updatedDataFilter) => {
                    const filterId = updatedDataFilter.filter_id
                    const filterIndexInDetailPayload = (detailUpdatedPayload.filters ?? []).findIndex(filter => String(filter._id) === String(filterId))

                    if (filterIndexInDetailPayload >= 0) {
                        detailUpdatedPayload.filters[filterIndexInDetailPayload].expression = updatedDataFilter.expression
                    }
                })

                newState = newState
                    .mergeIn([detail], Map({
                        isFetching: action.isFetching,
                        issue: action.issue,
                        updated: action.updated,
                        payload: fromJS(detailUpdatedPayload)
                    }))
            }
            return newState
        case DATAFILTERS_UPSERT_ERROR:
            if (action.cardId == null) return state
            newState = state;

            cardId = action.cardId
            isCurrentCard = newState.getIn([detail, "payload", "_id"]) === cardId
            if (isCurrentCard) {
                newState = newState.mergeIn([detail], Map({
                    isFetching: action.isFetching,
                    issue: action.issue,
                    issuePayload: fromJS(action.issuePayload)
                }))
            }
            return newState
        default:
            return state;
    }
}